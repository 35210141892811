import AddItemDialog, {
  AddItemType,
} from "@/components/organism/AddItemDialog";
import { ReactComponent as AddSvg } from "@/src/assets/icons/icon-add-black.svg";
import AddBlueSvg from "@/src/assets/icons/icon-add-blue.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import InfoGray6Svg from "@/src/assets/icons/icon-info-gray6.svg";
import InfoSvg from "@/src/assets/icons/icon-info.svg";
import NoDataBagSvg from "@/src/assets/icons/icon-no-data-bag.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-black.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import SelectOptionButton from "@/src/components/atom/Button/SelectOptionButton";
import Checkbox from "@/src/components/atom/Checkbox";
import DatePicker from "@/src/components/atom/DatePicker";
import FileComponent from "@/src/components/atom/File";
import Icon from "@/src/components/atom/Icon";
import Input from "@/src/components/atom/Input";
import Label from "@/src/components/atom/Label";
import Loader from "@/src/components/atom/Loader";
import RadioGroup from "@/src/components/atom/RadioGroup";
import Select from "@/src/components/atom/Select";
import Typo from "@/src/components/atom/Typo";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import Flex from "@/src/components/molecule/Flex";
import FormItem, { InputError } from "@/src/components/molecule/FormItem";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import NoRegisteredSignatureAlertDialog from "@/src/components/organism/NoRegisteredSignatureAlertDialog";
import ReferenceImageDialog from "@/src/components/organism/ReferenceImageDialog";
import RegisterCompanySignDialog from "@/src/components/organism/RegisterCompanySignDialog";
import SignatureRequestAlertDialog from "@/src/components/organism/SignatureRequestAlertDialog";
import TermsAddDialog from "@/src/components/organism/TermsAddDialog";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import { DIRECT_INPUT_LABEL, DIRECT_INPUT_VALUE } from "@/src/constant";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useAppSelector } from "@/src/store";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useCreateBuyerEmployeesMutation,
  useGetBuyersQuery,
  useLazyGetBuyerEmployeesQuery,
} from "@/src/store/apis/client/buyer";
import { GenerateBuyerEmployeeDto } from "@/src/store/apis/client/buyer/interface";
import { useGetCommonCodeListQuery } from "@/src/store/apis/common";
import {
  useCreateContractV2Mutation,
  useLazyCheckScNoQuery,
} from "@/src/store/apis/contracts";
import { ContractDetailViewDto } from "@/src/store/apis/contracts/contractDetail/interface";
import { useGetExportersQuery } from "@/src/store/apis/corporate/company";
import {
  useCreateSingleItemCodeMutation,
  useGetNonFilteredItemCodesQuery,
} from "@/src/store/apis/corporate/companyItemManagement";
import { ExporterItemCodeListViewV2Dto } from "@/src/store/apis/corporate/companyItemManagement/interface";
import {
  useLazyGetTemporaryDataQuery,
  useSaveTemporaryDataMutation,
} from "@/src/store/apis/tempSave";
import colorSet from "@/src/styles/color";
import { mediaQuery } from "@/src/styles/mediaQuery";
import typo from "@/src/styles/typography";
import { commonCodeMapper } from "@/src/utils/commonCodeHelper";
import { isNull, isUndefined } from "@/src/utils/is";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { GroupType, OptionType } from "types";
import BuyerEmployeeAddDialog from "../../../ClientManagement/dialog/BuyerEmployeeAddDialog";
import { ContractAddFormType } from "../../types/contract.type";
import { contractFormDefaultValue } from "../../utils/defaultValue";
import {
  getIntegerWithDecimal,
  handleIntegerWithDecimalsChange,
} from "../../utils/getIntegerWithDecimal";
import LoadContractDialog from "../dialog/LoadContractDialog";
import SignatureSelectDialog from "@/src/components/organism/SignatureSelectDialog";
import { ScData } from "@/src/components/template/pdfs/v2/Sc/types";
import { scPreview } from "@/src/components/template/pdfs/v2/Sc/ScContent";
import DatePickerNoticeTypo from "@/src/components/molecule/DatePickerNoticeTypo";

enum DialogState {
  NULL,
  IMAGE,
  LOAD_CONTRACT,
  ADD_ITEM,
  ADD_CONTACT_PERSON,
  CREATE_TERMS,
  REGISTER_SIGN,
}

enum AlertDialogState {
  NULL,
  SAVED_DATA,
  BACK_TO_PREVIOUS,
  SEND_TO_BUYER,
  REGISTER_SIGN,
  SIGNATURE_REQUEST,
  SIGNATURE_SELECT_SC,
}

const ContractAddPage = () => {
  const { t } = useTranslation("contract");
  const navigate = useNavigate();
  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const alert = useAlert();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  const exportersData = useGetExportersQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const isCompanyTerms =
    !!exportersData.currentData?.termsOfSalesContract
      .salesContractTermsWithLatestHistory;

  const isTwoSign =
    exportersData.currentData?.signatureManagement
      ?.simpleExporterSignatureMedias?.length === 2;

  const defaultSignatureMediaId =
    exportersData?.currentData?.signatureManagement?.simpleExporterSignatureMedias?.find(
      (item) => item.isDefault
    )?.signatureSimpleMedia.id as number;

  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    getValues,
    clearErrors,
    reset,
    setFocus,
    unregister,
    setError,
  } = useForm<ContractAddFormType>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      ...contractFormDefaultValue,
      isAddContractTerms: isCompanyTerms ? ["true"] : [],
    },
  });

  const subscription = useAppSelector((state) => state.subscription);

  const [selectImage, setSelectImage] = useState("");
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [previousItemCodeId, setPreviousItemCodeId] = useState<number>();
  const [previousBuyerId, setPreviousBuyerId] = useState<number>();
  const [previousBuyerEmployeeId, setPreviousBuyerEmployeeId] =
    useState<number>();
  const [savedData, setSavedData] = useState<any>();
  const [isSendScToBuyer, setIsSendScToBuyer] = useState(true);

  // SC PDF
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const previewButtonRef = useRef<HTMLButtonElement>(null);
  const previewScPdfResourceRef = useRef<ContractAddFormType | null>(null);

  const { mainCategoryList, exporterUserType, exporterUserMainFieldType } =
    useGetSessionQuery(undefined, {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isErrorAndUndefined =
          isError || isFetching || currentData === undefined;

        const isManager =
          currentData?.row.exporterUserType === "MANAGER" ||
          currentData?.row.exporterUserType === "MIDDLE_MANAGER";

        return {
          mainCategoryList: !isErrorAndUndefined
            ? isManager
              ? currentData.row.mainCategoryCodeItemNames
              : currentData.row.exporter.mainCategoryCodeItemNames
            : [],
          exporterUserType: currentData?.row.exporterUserType,
          exporterUserMainFieldType: currentData?.row.exporterUserMainFieldType,
        };
      },
    });
  const [getSavedData] = useLazyGetTemporaryDataQuery();
  const [createContract] = useCreateContractV2Mutation();
  const [createEmployee] = useCreateBuyerEmployeesMutation();
  const [checkScNo] = useLazyCheckScNoQuery();

  const { buyerList, isBuyersFetching } = useGetBuyersQuery(
    { isActivated: true },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;

        return {
          buyerList: !isUnstable ? currentData.data : [],
          isBuyersFetching: isFetching,
        };
      },
    }
  );
  const [tempSave] = useSaveTemporaryDataMutation();
  const [createSingleItemCode] = useCreateSingleItemCodeMutation();
  const [getBuyerEmployees, buyerEmployeesResult] =
    useLazyGetBuyerEmployeesQuery();
  const {
    itemsList,
    isItemsFetching,
    refetch: itemListRefetch,
  } = useGetNonFilteredItemCodesQuery(
    {},
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isFetching || isError;
        const isStable = !isUnstable;

        return {
          itemsList: isStable ? currentData.rows : [],
          isItemsFetching: isFetching,
        };
      },
    }
  );

  const { isCommonCodeFetching, commonCodeCurrentData } =
    useGetCommonCodeListQuery(
      {
        codeNameList: [
          { codeName: "CURRENCY" },
          { codeName: "FREETIME" },
          { codeName: "MEASUREMENT" },
          { codeName: "PAYMENT_TERMS" },
          { codeName: "PLACE_OF_DELIVERY" },
          { codeName: "SHIPPING_TERMS", sort: "codeItemNameEn", order: "ASC" },
          { codeName: "COUNTRY" },
        ],
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ isFetching, currentData }) => {
          return {
            isCommonCodeFetching: isFetching,
            commonCodeCurrentData: currentData ?? [],
          };
        },
      }
    );

  const [
    currencyList = [],
    freeTimeList = [],
    measurementList = [],
    paymentTermsList = [],
    placeOfDeliveryList = [],
    shippingTermsList = [],
    countryList = [],
  ] = commonCodeCurrentData;

  const buyerToOptionList = buyerList
    .filter(
      ({ isActivated, buyerListQueryResultDto, mainCategoryCodeItemNames }) =>
        (isActivated &&
          mainCategoryCodeItemNames.some((mainCategory) =>
            mainCategoryList.includes(mainCategory)
          )) ||
        buyerListQueryResultDto.id === previousBuyerId
    )
    .reduce<OptionType[]>((acc, val) => {
      const resource = {
        label: val.buyerName,
        value: val.buyerListQueryResultDto.id.toString(),
      };
      return [...acc, resource];
    }, []);

  const buyerListToOptionList = buyerEmployeesResult.isError
    ? []
    : buyerEmployeesResult.currentData?.rows
        .filter(
          ({ isActivated, id }) => isActivated || id === previousBuyerEmployeeId
        )
        .reduce<OptionType[]>((acc, val) => {
          const resource = {
            label: val.name,
            value: val.id.toString(),
          };
          return [...acc, resource];
        }, []);

  const buyerMainCategory =
    buyerList.find(({ id }) => watch("buyerId") === id.toString())
      ?.mainCategoryCodeItemNames ?? [];

  const itemListToGroupedOptionList = itemsList
    .filter(({ isActivated, id, mainCategoryCodeItemName }) => {
      return (
        (isActivated &&
          mainCategoryList.includes(mainCategoryCodeItemName) &&
          buyerMainCategory.includes(mainCategoryCodeItemName)) ||
        (id === previousItemCodeId &&
          String(previousBuyerId || 0) === watch("buyerId"))
      );
    })
    .reduce((acc: GroupType[], item: ExporterItemCodeListViewV2Dto) => {
      const groupLabel = `${item.mainCategory} > ${item.subCategory}`;

      // 그룹이 이미 존재하는지 확인
      let group = acc.find((g) => g.label === groupLabel);
      if (!group) {
        group = { label: groupLabel, options: [] };
        acc.push(group);
      }

      // 옵션 레이블 생성
      const isItemCodeSameAsItem = item.item === item.itemCode;
      const optionLabel = isItemCodeSameAsItem
        ? `${item.item}`
        : `${item.item}(${item.itemCode})`;

      // value 필드 수정
      const optionValue = `${item.id}_${item.exporterItemId}`;

      // 옵션 추가
      group.options.push({
        label: optionLabel,
        value: optionValue,
      });

      return acc;
    }, []);

  const measurementOptions = measurementList.reduce<OptionType[]>(
    commonCodeMapper,
    []
  );
  const currencyOptions = currencyList.reduce<OptionType[]>(
    commonCodeMapper,
    []
  );
  const freetimeOptions = freeTimeList.reduce<OptionType[]>(
    commonCodeMapper,
    []
  );
  const placeOfDeliveryOptions = placeOfDeliveryList.reduce<OptionType[]>(
    commonCodeMapper,
    []
  );
  const paymentTermsOptions = useMemo(
    () => paymentTermsList.reduce<OptionType[]>(commonCodeMapper, []),
    [paymentTermsList]
  );
  const shippingTermsOptions = shippingTermsList.reduce<OptionType[]>(
    commonCodeMapper,
    []
  );
  const countryListOptions = countryList.reduce<OptionType[]>((acc, val) => {
    const resource = {
      label: `${val.codeItemName}) ${val.codeItemNameEn}`,
      value: val.codeItemNameKo,
    };
    return [...acc, { ...resource }];
  }, []);

  const selectBuyer = buyerEmployeesResult
    ? buyerEmployeesResult?.currentData?.rows.filter(
        (item) => item.id === Number(watch("buyerEmployeeId"))
      )[0]?.buyer
    : null;

  const referenceImageList =
    getValues("referenceMediaIdList")?.map((item) => item.mediaUrl) || [];

  const numberOfUsers =
    (subscription.subscriptionCredit?.numberOfMember as number) -
      (subscription.subscriptionCredit?.memberNumberCredit as number) || 0;

  const isCreateDisabled =
    exporterUserMainFieldType === "BUYER" ||
    !subscription.isCompanySubscription ||
    (subscription?.subscriptionCredit?.numberOfMember || 0) < numberOfUsers;

  const isBuyerLinkedStatus =
    buyerList.find(
      ({ buyerListQueryResultDto }) =>
        buyerListQueryResultDto.id.toString() === watch().buyerId
    )?.buyerListQueryResultDto.linkedStatus === "COMPLETED";

  const handlePreview = () => {
    const scData: ScData = {
      scNumber: previewScPdfResourceRef.current?.scNo || "",
      componyLogoUrl:
        exportersData.currentData?.basicInformation?.businessLogoSimpleMedia
          ?.mediaUrl || "",
      sellerCorporation:
        exportersData.currentData?.basicInformation?.companyName || "",
      sellerContact:
        exportersData.currentData?.exporterDetailQueryResultDto?.tel || "",
      sellerContactPrefix:
        exportersData.currentData?.exporterDetailQueryResultDto?.telPrefix ||
        "",
      sellerFax:
        exportersData?.currentData?.exporterDetailQueryResultDto?.fax || "",
      sellerFaxPrefix:
        exportersData?.currentData?.exporterDetailQueryResultDto?.faxPrefix ||
        "",
      sellerCountryName:
        exportersData?.currentData?.exporterDetailQueryResultDto?.countryName ||
        "",
      sellerRegion:
        exportersData?.currentData?.exporterDetailQueryResultDto?.region || "",
      sellerPostalCode:
        exportersData?.currentData?.exporterDetailQueryResultDto?.postalCode ||
        "",
      sellerLocality:
        exportersData?.currentData?.exporterDetailQueryResultDto?.locality ||
        "",
      sellerStreetAddress:
        exportersData?.currentData?.exporterDetailQueryResultDto
          ?.streetAddress || "",

      buyerCorporation: selectBuyer ? selectBuyer?.buyerName : "",
      buyerContactPrefix: selectBuyer ? selectBuyer?.telPrefix : "",
      buyerContact: selectBuyer ? selectBuyer?.tel : "",
      buyerFaxPrefix: selectBuyer ? selectBuyer?.faxPrefix : "",
      buyerFax: selectBuyer ? selectBuyer?.fax : "",
      buyerCountryName: selectBuyer ? selectBuyer?.buyerCountryName : "",
      buyerRegion: selectBuyer ? selectBuyer?.buyerRegion : "",
      buyerPostalCode: selectBuyer ? selectBuyer?.buyerPostalCode : "",
      buyerLocality: selectBuyer ? selectBuyer?.buyerLocality : "",
      buyerStreetAddress: selectBuyer
        ? selectBuyer?.buyerStreetAddress || ""
        : "",

      orderDate: previewScPdfResourceRef.current?.orderDateAt
        ? dayjs(previewScPdfResourceRef.current?.orderDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD
          )
        : "",
      paymentTerm: previewScPdfResourceRef.current?.paymentTerms || "",
      paymentTermsRemark:
        previewScPdfResourceRef.current?.paymentTermsRemark || "",
      originLocation: previewScPdfResourceRef.current?.origin || "",
      shippingTerm: previewScPdfResourceRef.current?.shippingTerms || "",
      shippingTermRemark:
        previewScPdfResourceRef.current?.shippingTermsRemark || "",
      lastShipmentDate: previewScPdfResourceRef.current?.lastShipmentDateAt
        ? dayjs(previewScPdfResourceRef.current?.lastShipmentDateAt).format(
            DATE_FORMAT_STRINGS.YYYY_MM_DD
          )
        : "",
      quantityPrefix: previewScPdfResourceRef.current?.quantityUnit || "",
      quantity: previewScPdfResourceRef.current?.quantity
        ? Number(
            previewScPdfResourceRef.current?.quantity.replace(/[,]/g, "")
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "",
      description: previewScPdfResourceRef.current?.item || "",
      hsCode: previewScPdfResourceRef.current?.hsCode || "",
      unitPricePrefix: previewScPdfResourceRef.current?.unitPriceUnit || "",
      unitPrice: previewScPdfResourceRef.current?.unitPrice
        ? Number(
            previewScPdfResourceRef.current?.unitPrice.replace(/[,]/g, "")
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "",
      amount:
        (previewScPdfResourceRef.current?.quantity &&
          previewScPdfResourceRef.current?.unitPrice &&
          Number(
            (
              (parseFloat(
                previewScPdfResourceRef.current?.quantity.replace(/,/g, "")
              ) || 0) *
              (parseFloat(
                previewScPdfResourceRef.current?.unitPrice.replace(/,/g, "")
              ) || 0)
            ).toFixed(2)
          ).toLocaleString("ko-KR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })) ||
        "",
      remark: previewScPdfResourceRef.current?.contractRemark || "",

      isAddBankDetail:
        !!previewScPdfResourceRef.current?.isAddBankDetail.length,
      isAddContractTerms:
        !!previewScPdfResourceRef.current?.isAddContractTerms.length,
      contractTermsTitle:
        exportersData.currentData?.termsOfSalesContract
          ?.salesContractTermsWithLatestHistory?.title || "",
      contractTerms:
        exportersData.currentData?.termsOfSalesContract
          .salesContractTermsWithLatestHistory?.latestSalesContractTermsHistory
          .body,
      bankName:
        exportersData?.currentData?.exporterDetailQueryResultDto?.bankName ||
        "",
      bankTelPrefix:
        exportersData?.currentData?.exporterDetailQueryResultDto
          ?.bankTelPrefix || "",
      bankTel:
        exportersData?.currentData?.exporterDetailQueryResultDto?.bankTel || "",
      bankFaxPrefix:
        exportersData?.currentData?.exporterDetailQueryResultDto?.faxPrefix ||
        "",
      bankFax:
        exportersData?.currentData?.exporterDetailQueryResultDto?.bankFax || "",
      bankStreetAddress:
        exportersData?.currentData?.exporterDetailQueryResultDto
          ?.bankStreetAddress || "",
      bankLocality:
        exportersData?.currentData?.exporterDetailQueryResultDto
          ?.bankLocality || "",
      bankRegion:
        exportersData?.currentData?.exporterDetailQueryResultDto?.bankRegion ||
        "",
      bankPostalCode:
        exportersData?.currentData?.exporterDetailQueryResultDto
          ?.bankPostalCode || "",
      bankCountryName:
        exportersData?.currentData?.exporterDetailQueryResultDto
          ?.bankCountryName || "",
      swiftCode:
        exportersData?.currentData?.exporterDetailQueryResultDto?.swiftCode ||
        "",
      accountNumber:
        exportersData?.currentData?.exporterDetailQueryResultDto
          ?.accountNumber || "",
      accountName:
        exportersData?.currentData?.exporterDetailQueryResultDto?.accountName ||
        "",
      sellerSignatureUrl:
        exportersData?.currentData?.signatureManagement?.simpleExporterSignatureMedias?.find(
          (item) => item.isDefault
        )?.signatureSimpleMedia.mediaUrl || "",
      buyerSignatureUrl: "",
    };

    scPreview(scData);
  };

  const handleImageClick = (image: string) => {
    setDialogState(DialogState.IMAGE);
    setSelectImage(image);
  };

  const createScContract = async (signatureMediaId: number) => {
    try {
      handleContentLoadingOn();

      const { row } = await createContract({
        /**
         * Basic Information
         */
        scNo: getValues("scNo"),
        poNo: getValues("poNo") || undefined,
        buyerId: Number(getValues("buyerId")),
        buyerEmployeeId: Number(getValues("buyerEmployeeId")),
        allocation: getValues("allocation") || undefined,
        orderDateAt: dayjs(getValues("orderDateAt"))
          .startOf("day")
          .toISOString(),
        isAddContractTerms: !!getValues("isAddContractTerms").length,
        hsCode: getValues("hsCode") || undefined,
        exporterItemId: Number(getValues("exporterItemId").split("_")[1]),
        exporterItemCodeId: Number(getValues("exporterItemCodeId")),
        /**
         * Transaction Information
         */
        quantity: Number(getValues("quantity").replace(/,/g, "")),
        quantityUnit: getValues("quantityUnit"),
        quantityUnitCodeItemName: getValues("quantityUnitCodeItemName"),
        unitPrice: Number(getValues("unitPrice").replace(/,/g, "")),
        unitPriceUnit: getValues("unitPriceUnit"),
        unitPriceUnitCodeItemName: getValues("unitPriceUnit"),
        shippingTerms: getValues("shippingTerms"),
        shippingTermsCodeItemName: getValues("shippingTermsCodeItemName"),
        shippingTermsRemark: getValues("shippingTermsRemark") || undefined,
        freetime:
          getValues("freetimeCodeItemName") === DIRECT_INPUT_VALUE
            ? getValues("freetimeDirect") || undefined
            : getValues("freetime") || undefined,
        freetimeCodeItemName:
          getValues("freetimeCodeItemName") === DIRECT_INPUT_VALUE
            ? undefined
            : getValues("freetimeCodeItemName") || undefined,
        placeOfDelivery: getValues("placeOfDelivery") || undefined,
        portOfDischarge: getValues("portOfDischarge") || undefined,
        portOfDischargeCodeItemName:
          getValues("portOfDischargeCodeItemName") || undefined,
        placeOfDeliveryCodeItemName:
          getValues("placeOfDeliveryCodeItemName") || undefined,
        anyPortIn: getValues("notYetSpecified")
          ? getValues("anyPortIn")
          : undefined,
        anyPortInCodeItemName: getValues("notYetSpecified")
          ? getValues("anyPortInCodeItemName")
          : undefined,
        paymentTerms: getValues("paymentTerms"),
        paymentTermsCodeItemName: getValues("paymentTermsCodeItemName"),
        paymentTermsRemark: getValues("paymentTermsRemark") || undefined,
        paymentTermsMediaId:
          getValues("paymentTerms") === "L/C"
            ? getValues("paymentTermsMediaId")?.id
            : undefined,
        isAddBankDetail: !!getValues("isAddBankDetail").length,
        lastShipmentDateAt: getValues("lastShipmentDateAt")
          ? dayjs(getValues("lastShipmentDateAt")).startOf("day").toISOString()
          : undefined,
        origin: getValues("origin") || undefined,
        expireDateAt: getValues("expireDateAt")
          ? dayjs(getValues("expireDateAt")).startOf("day").toISOString()
          : undefined,
        lcNo: getValues("lcNo") || undefined,
        /**
         * Attachment file
         */
        referenceMediaIdList: getValues("referenceMediaIdList").length
          ? getValues("referenceMediaIdList").map(({ id }) => id)
          : undefined,
        poMediaId: getValues("poMediaId")?.[0]?.id,
        scMediaId: getValues("scMediaId")?.[0]?.id,
        contractRemark: getValues("contractRemark") || undefined,
        memo: getValues("memo") || undefined,
        isSendToBuyer: isBuyerLinkedStatus ? isSendScToBuyer : false,
        signatureMediaId,
      }).unwrap();

      alert.showAlert({
        type: "success",
        message: t("contract:exporter.add.alert.saveSuccess"),
      });
      if (row.id) {
        navigate(`${EXPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/${row.id}`);
      }
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      handleContentLoadingOff();
    }
  };

  const handleCreateContract = async () => {
    /**
     * - 기업관리자, 중간관리자 서명등록
     * - 일반 매니저 기업관리자에게 서명요청
     */
    if (
      isNull(
        exportersData.currentData?.signatureManagement
          ?.simpleExporterSignatureMedias
      )
    ) {
      if (exporterUserType !== "MANAGER") {
        return setAlertDialogState(AlertDialogState.REGISTER_SIGN);
      } else {
        return setAlertDialogState(AlertDialogState.SIGNATURE_REQUEST);
      }
    }
    // 계약 생성 모달 (거래처 연결되어있는 경우)
    if (isBuyerLinkedStatus) {
      return setAlertDialogState(AlertDialogState.SEND_TO_BUYER);
    } else {
      // 거래처 연결되어있는 경우와 서명이 2개 일 경우
      if (isTwoSign) {
        return setAlertDialogState(AlertDialogState.SIGNATURE_SELECT_SC);
      }
      // 계약 생성 API 요청 (연결되어있지 않은 경우)
      await createScContract(defaultSignatureMediaId);
    }
  };

  const handleTempSave = async () => {
    try {
      await tempSave({
        storageTarget: "CONTRACT",
        jsonData: { ...getValues() },
      }).unwrap();
      alert.showAlert({
        type: "success",
        message: t("contract:exporter.add.alert.temporarySaveSuccess"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleOverrideData = () => {
    let expireDateAt = undefined;
    let lastShipmentDateAt = undefined;
    let orderDateAt = undefined;

    if (savedData?.expireDateAt) expireDateAt = dayjs(savedData?.expireDateAt);
    if (savedData?.lastShipmentDateAt)
      lastShipmentDateAt = dayjs(savedData?.lastShipmentDateAt);
    if (savedData?.orderDateAt) orderDateAt = dayjs(savedData?.orderDateAt);

    setPreviousBuyerId(savedData?.buyerId);
    setPreviousBuyerEmployeeId(savedData?.buyerEmployeeId);
    setPreviousItemCodeId(
      savedData.exporterItemCodeId
        ? Number(savedData.exporterItemCodeId)
        : undefined
    );

    setFocus("scNo");

    reset({
      ...savedData,
      freetime: savedData.freetime,
      freetimeCodeItemName: savedData.freetimeCodeItemName,
      freetimeDirect: savedData.freetimeDirect,
      expireDateAt,
      lastShipmentDateAt,
      orderDateAt,
    });
  };

  const handleLoadDataOverride = async (detail: ContractDetailViewDto) => {
    try {
      if (detail.buyerId) {
        await getBuyerEmployees({
          id: detail.buyerId,
        });
      }
      setPreviousItemCodeId(detail.exporterItemCodeId);
      setPreviousBuyerId(detail.buyerId);
      setPreviousBuyerEmployeeId(detail.buyerEmployeeId);
    } catch (e) {
    } finally {
      const {
        buyerId,
        buyerEmployeeId,
        allocation = undefined,
        hsCode = undefined,
        exporterItemId,
        exporterItemCodeId = undefined,
        exporterItemCode,
        quantityUnitCodeItemName,
        quantityUnit,
        quantity,
        unitPriceUnit,
        unitPriceUnitCodeItemName,
        unitPrice,
        origin = undefined,
        freetime = undefined,
        freetimeCodeItemName,
        placeOfDelivery,
        placeOfDeliveryCodeItemName,
        anyPortIn,
        anyPortInCodeItemName,
        paymentTerms,
        paymentTermsCodeItemName,
        paymentTermsRemark,
        isAddBankDetail,
        shippingTerms,
        shippingTermsCodeItemName,
        shippingTermsRemark,
        contractRemark,
      } = detail;
      reset({
        poNo: "",
        scNo: "",
        buyerId: String(buyerId),
        buyerEmployeeId: String(buyerEmployeeId),
        allocation,
        orderDateAt: dayjs(),
        hsCode,
        exporterItemId: `${exporterItemCodeId}_${exporterItemId}`,
        exporterItemCodeId: exporterItemCodeId
          ? String(exporterItemCodeId)
          : undefined,
        exporterItemCode: exporterItemCode || "",
        quantityUnitCodeItemName,
        quantityUnit,
        quantity: getIntegerWithDecimal(String(quantity)),
        unitPriceUnit,
        unitPriceUnitCodeItemName,
        unitPrice: getIntegerWithDecimal(String(unitPrice)),
        origin,
        freetime,
        freetimeCodeItemName: freetimeCodeItemName || DIRECT_INPUT_VALUE,
        freetimeDirect: !freetimeCodeItemName ? freetime : undefined,
        placeOfDelivery,
        placeOfDeliveryCodeItemName,
        anyPortIn,
        anyPortInCodeItemName,
        paymentTerms,
        paymentTermsCodeItemName,
        paymentTermsRemark,
        lcNo: "",
        isAddBankDetail: isAddBankDetail ? ["true"] : [],
        expireDateAt: undefined,
        lastShipmentDateAt: undefined,
        shippingTerms,
        shippingTermsCodeItemName,
        shippingTermsRemark,
        contractRemark,
        isAddContractTerms: detail.isAddContractTerms ? ["true"] : [],
        notYetSpecified: !!anyPortInCodeItemName,
        paymentTermsMediaId: undefined,
        referenceMediaIdList: [],
        poMediaId: undefined,
        scMediaId: undefined,
        memo: undefined,
        isThirdPartyTrade: allocation ? ["true"] : [],
        mainCategory: itemsList.find(
          (itemInfo) => itemInfo.exporterItemId === exporterItemId
        )?.mainCategory,
        subCategory: itemsList.find(
          (itemInfo) => itemInfo.exporterItemId === exporterItemId
        )?.subCategory,
        item: itemsList.find(
          (itemInfo) => itemInfo.exporterItemId === exporterItemId
        )?.item,
      });
    }
  };

  const handleAddItems = async ({
    item,
    exporterItemCodeList,
    subCategoryCodeItemName,
    mainCategoryCodeItemName,
  }: AddItemType) => {
    try {
      const { row } = await createSingleItemCode({
        item: item,
        itemCode: exporterItemCodeList[0].itemCode,
        subCategoryCodeItemName: subCategoryCodeItemName,
        mainCategoryCodeItemName: mainCategoryCodeItemName,
      }).unwrap();

      itemListRefetch();

      const categoryList =
        buyerList.find(({ id }) => watch("buyerId") === id.toString())
          ?.mainCategoryCodeItemNames ?? [];

      if (categoryList.includes(mainCategoryCodeItemName)) {
        setValue("exporterItemId", `${row.id}_${row.exporterItemId}`);
        setValue("mainCategory", row.mainCategory ?? "");
        setValue("subCategory", row.subCategory ?? "");
        setValue("item", row.item ?? "");
        setValue("exporterItemCodeId", String(row.id));
        setValue("exporterItemCode", row.itemCode);
        clearErrors("exporterItemId");
      }

      alert.showAlert({
        type: "success",
        message: t("alert:saveSuccess"),
      });

      setDialogState(DialogState.NULL);
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleAddBuyerEmployee = async (
    values: Omit<GenerateBuyerEmployeeDto, "isActivated"> & {
      isActivated: string;
    }
  ) => {
    const buyerId = getValues("buyerId");

    try {
      setDialogState(DialogState.NULL);
      const { row: createdEmployee } = await createEmployee({
        id: Number(buyerId),
        name: values.name,
        email: values.email,
        isActivated: values.isActivated === "Y",
        personalContactPrefix: values.personalContactPrefix || undefined,
        personalContact: values.personalContact || undefined,
        officeContactPrefix: values.officeContactPrefix || undefined,
        officeContact: values.officeContact || undefined,
        buyerEmployeeRemark: values.buyerEmployeeRemark || undefined,
      }).unwrap();

      await getBuyerEmployees({
        id: Number(buyerId),
      });

      setValue("buyerEmployeeId", createdEmployee.id.toString());
      clearErrors("buyerEmployeeId");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
    }
  };

  // 기본 정보
  const renderBasicInformation = () => {
    // 선택사항
    const optionalItemValue = () => {
      if (exportersData.isFetching) {
        return <Loader />;
      }

      // 약관이 있는경우에만 체크박스
      if (isCompanyTerms) {
        return (
          <StyledFormItem
            type="checkbox"
            control={control as any}
            name="isAddContractTerms"
            options={[
              {
                label: t("contract:exporter.add.common.addContractTerm"),
                value: "true",
              },
            ]}
          />
        );
      }

      return (
        <Flex gap={16} alignItems="center" flexWrap="wrap">
          <Typo typoType="b7r" color="gray7">
            {t("common:noRegisteredContractTerms")}
          </Typo>
          <Button
            buttonGrade="tertiary"
            buttonColor="black"
            buttonSize={32}
            onClick={() => setDialogState(DialogState.CREATE_TERMS)}
          >
            <Flex alignItems="center" gap={4}>
              <AddIcon />
              {t("common:registerContractTerms")}
            </Flex>
          </Button>
        </Flex>
      );
    };

    return (
      <SectionCard
        cardTitle={t("contract:exporter.add.common.basicInformation")}
      >
        <Flex flexDirection="column" gap={24}>
          <StyledFormItem
            label={t("contract:exporter.add.common.scNo")}
            type={"text"}
            name={"scNo"}
            control={control as any}
            inputProps={{
              placeholder: t("contract:exporter.add.placeholder.scNo"),
            }}
            rules={{
              maxLength: 80,
              required: true,
              validate: async (scNo) => {
                if (scNo.length > 80) return true;
                const checkResult = await checkScNo({ scNo });
                return !checkResult.isError;
              },
            }}
            errorsMessage={{
              required: t("error:required"),
              validate: t("error:alreadyExisted"),
              maxLength: t("error:maxLength80"),
            }}
          />
          <StyledFormItem
            label={t("contract:exporter.add.common.buyer")}
            rules={{
              required: true,
            }}
            type="singleSelect"
            control={control as any}
            name="buyerId"
            inputContainerClassName="selector-size-m"
            inputProps={{
              suffixIcon: isBuyersFetching ? <Loader /> : undefined,
              disabled: isBuyersFetching,
              placeholder: t("contract:exporter.add.placeholder.buyer"),
              filterOption: (input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase()),
              onChange: async (a: any) => {
                try {
                  const res = await getBuyerEmployees({
                    id: Number(a),
                  }).unwrap();

                  const buyerCountryCodeItemName =
                    buyerList.find(
                      ({ buyerListQueryResultDto }) =>
                        buyerListQueryResultDto.id.toString() === a
                    )?.buyerListQueryResultDto.countryCodeItemName ?? undefined;
                  const buyerCountryInfo = countryList?.find(
                    ({ codeItemName }) =>
                      buyerCountryCodeItemName === codeItemName
                  );
                  const countryLabel = buyerCountryInfo
                    ? `${buyerCountryInfo.codeItemName}) ${buyerCountryInfo.codeItemNameEn}`
                    : undefined;

                  setValue("anyPortIn", countryLabel);
                  setValue("anyPortInCodeItemName", buyerCountryCodeItemName);

                  const candidate = contractFormDefaultValue;

                  setValue("exporterItemId", candidate["exporterItemId"]);
                  setValue("mainCategory", candidate["mainCategory"]);
                  setValue("subCategory", candidate["subCategory"]);
                  setValue("item", candidate["item"]);
                  setValue(
                    "exporterItemCodeId",
                    candidate["exporterItemCodeId"]
                  );
                  setValue("exporterItemCode", candidate["exporterItemCode"]);

                  const validBuyerList = res.rows.filter(
                    ({ isActivated, id }) =>
                      isActivated || previousBuyerEmployeeId === id
                  );

                  if (validBuyerList.length === 1) {
                    setValue(
                      "buyerEmployeeId",
                      validBuyerList[0].id.toString()
                    );
                    clearErrors("buyerEmployeeId");
                  } else {
                    setValue("buyerEmployeeId", candidate["buyerEmployeeId"]);
                  }
                } catch (e: any) {
                  const message = Array.isArray(e.data.message)
                    ? e.data.message[0]
                    : e.data.message;
                  alert.showAlert({ type: "error", message });
                }
              },
            }}
            options={buyerToOptionList}
            bottomAccessory={
              <>
                <SubFormRow>
                  <SubFormLabel>
                    {t("contract:exporter.add.common.buyerContact")}
                    <strong>*</strong>
                  </SubFormLabel>
                  <Controller
                    name="buyerEmployeeId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <FlexDiv className="selector-size-s">
                        <StyledSelect
                          {...field}
                          disabled={
                            !watch("buyerId") || buyerEmployeesResult.isFetching
                          }
                          value={field.value || undefined}
                          data-invalid={!!errors.buyerEmployeeId}
                          showSearch
                          filterOption={(input, option) =>
                            ((option?.label as string) ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={buyerListToOptionList}
                          placeholder={t(
                            "contract:exporter.add.placeholder.buyerContact"
                          )}
                          suffixIcon={
                            buyerEmployeesResult.isFetching ? (
                              <Loader />
                            ) : undefined
                          }
                          dropdownRender={(menu) => {
                            return (
                              <>
                                <DirectInputSelectOptionButton
                                  onClick={() => {
                                    setValue("buyerEmployeeId", "");
                                    setDialogState(
                                      DialogState.ADD_CONTACT_PERSON
                                    );
                                  }}
                                >
                                  {t(DIRECT_INPUT_LABEL)}
                                </DirectInputSelectOptionButton>
                                {(buyerListToOptionList?.length ?? 0) > 0 && (
                                  <div>{menu}</div>
                                )}
                              </>
                            );
                          }}
                        />
                        {errors.buyerEmployeeId && (
                          <StyledInputError message={t("error:required")} />
                        )}
                      </FlexDiv>
                    )}
                  />
                </SubFormRow>
              </>
            }
            errorsMessage={{
              required: t("error:required"),
              validate: t("error:alreadyExisted"),
            }}
          />

          <StyledFormItem
            label={t("common:orderDate")}
            type="date"
            control={control as any}
            name="orderDateAt"
            rules={{ required: true }}
            errorsMessage={{
              required: t("error:required"),
            }}
            inputProps={{
              showToday: false,
              renderExtraFooter: () => (
                <DatePickerNoticeTypo
                  text={t("common:datePickerFooterOrderDate")}
                />
              ),
            }}
          />
          <StyledFormItem
            label={t("contract:exporter.add.common.poNo")}
            type={"text"}
            name={"poNo"}
            control={control as any}
            inputProps={{
              placeholder: t("contract:exporter.add.placeholder.poNo"),
            }}
          />
          <StyledFormItem
            label={t("contract:exporter.add.common.thirdPartyTrade")}
            type="checkbox"
            control={control as any}
            name="isThirdPartyTrade"
            inputProps={{
              onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                if (!e.target.checked) {
                  unregister("allocation");
                }
              },
            }}
            options={[
              {
                label: t("contract:exporter.add.common.thirdPartyTradeLabel"),
                value: "true",
              },
            ]}
            bottomAccessory={
              <>
                {!!watch("isThirdPartyTrade").length && (
                  <SubFormRow>
                    <SubFormLabel>
                      {t("common:allocation")}
                      <strong>*</strong>
                    </SubFormLabel>
                    <FlexDiv>
                      <StyledInput
                        {...register("allocation", {
                          required: true,
                        })}
                        placeholder={t("placeholder:enterAllocation")}
                        data-invalid={!!errors.allocation}
                        onClear={() => setValue("allocation", "")}
                      />
                      {!!errors.allocation && (
                        <StyledInputError message={t("error:required")} />
                      )}
                    </FlexDiv>
                  </SubFormRow>
                )}
              </>
            }
          />
          <SectionCardRow
            label={t("contract:exporter.add.common.optional")}
            value={optionalItemValue()}
          />
        </Flex>
      </SectionCard>
    );
  };

  // 아이템 코드
  const renderItemSubInputs = () => {
    return (
      <>
        <SubFormRow>
          <SubFormLabel>
            {t("contract:exporter.add.common.mainCategory")}
          </SubFormLabel>
          <Controller
            name="mainCategory"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                style={{ width: "100%" }}
                disabled
                placeholder={t("common:mainCategory")}
                value={
                  getValues("mainCategory")
                    ? `${field.value} > ${watch("subCategory")}`
                    : "-"
                }
              />
            )}
          />
        </SubFormRow>
        <SubFormRow>
          <SubFormLabel>
            {t("contract:exporter.add.common.itemCode")}
          </SubFormLabel>
          <Controller
            name="exporterItemCode"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                style={{ width: "100%" }}
                disabled
                placeholder={
                  getValues("mainCategory") ? "-" : t("common:itemCode")
                }
              />
            )}
          />
        </SubFormRow>
      </>
    );
  };

  // Item / Payment Terms
  const renderItem = () => {
    return (
      <SectionCard
        cardTitle={t("contract:exporter.add.common.itemPaymentTerms")}
      >
        <Flex flexDirection="column" gap={24}>
          <CustomFormItemRow>
            <StyledCustomLabel>
              {t("contract:exporter.add.common.item")}
            </StyledCustomLabel>
            <DoubleInputContainerWithError style={{ flex: 1 }}>
              <DoubleInputContainer style={{ width: "100%" }}>
                <Controller
                  name="exporterItemId"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => {
                    const isItemFieldDisabled =
                      isItemsFetching || !watch("buyerId");
                    return (
                      <>
                        <StyledSelect
                          {...field}
                          value={field.value || undefined}
                          style={{ flex: 1, width: 1 }}
                          placeholder={t(
                            "contract:exporter.add.placeholder.item"
                          )}
                          filterOption={(input, option) =>
                            ((option?.label as string) ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={itemListToGroupedOptionList}
                          data-invalid={!!errors.exporterItemId}
                          showSearch
                          suffixIcon={isItemsFetching ? <Loader /> : undefined}
                          disabled={isItemFieldDisabled}
                          onChange={(value) => {
                            const splitedValue = (value as string).split("_");
                            const selectItem = itemsList.find(
                              (itemInfo) =>
                                itemInfo.id === Number(splitedValue[0])
                            );

                            if (selectItem) {
                              setValue("exporterItemId", value);
                              setValue("mainCategory", selectItem.mainCategory);
                              setValue("subCategory", selectItem.subCategory);
                              setValue("item", selectItem.item);
                              setValue("exporterItemCodeId", splitedValue[0]);
                              setValue("exporterItemCode", selectItem.itemCode);
                            }
                          }}
                        />
                        <StyledButton
                          buttonColor="black"
                          buttonGrade="tertiary"
                          buttonSize={40}
                          onClick={() => setDialogState(DialogState.ADD_ITEM)}
                          disabled={isItemFieldDisabled}
                        >
                          <AddIcon disabled={isItemFieldDisabled} />{" "}
                          {t("contract:exporter.add.common.addItem")}
                        </StyledButton>
                      </>
                    );
                  }}
                />
              </DoubleInputContainer>
              {errors.exporterItemId && (
                <InputError message={t("error:required")} />
              )}
              {watch("mainCategory") && renderItemSubInputs()}
            </DoubleInputContainerWithError>
          </CustomFormItemRow>

          <StyledFormItem
            label={t("contract:exporter.add.common.hsCode")}
            control={control as any}
            name="hsCode"
            type="text"
            inputProps={{
              placeholder: t("contract:exporter.add.placeholder.hsCode"),
            }}
          />

          <CustomFormItemRow>
            <Label required>{t("contract:exporter.add.common.qty")}</Label>
            <DoubleInputContainer className="fixed-width-container">
              <Controller
                name="quantityUnit"
                control={control}
                render={({ field }) => (
                  <FlexDiv>
                    <DoubleInputContainer>
                      <StyledSelect
                        {...field}
                        data-invalid={!!errors.quantity}
                        style={{ width: "108px", flexShrink: "0" }}
                        value={watch("quantityUnitCodeItemName")}
                        options={measurementOptions}
                        onChange={(_, option: any) => {
                          setValue("quantityUnit", option.label);
                          setValue("quantityUnitCodeItemName", option.value);
                        }}
                        suffixIcon={
                          isCommonCodeFetching ? <Loader /> : undefined
                        }
                      />
                      <Input
                        {...register("quantity", { required: true })}
                        onChange={(e) => {
                          const quantityWithDecimals =
                            handleIntegerWithDecimalsChange(e.target);

                          setValue(
                            "quantity",
                            String(quantityWithDecimals ?? "")
                          );
                        }}
                        data-invalid={!!errors.quantity}
                        onBlur={(e) => {
                          const quantityWithDecimals = getIntegerWithDecimal(
                            e.target.value
                          );

                          if (
                            quantityWithDecimals === "0.00" ||
                            quantityWithDecimals === ""
                          ) {
                            return setError("quantity", { type: "required" });
                          }

                          setValue(
                            "quantity",
                            String(quantityWithDecimals ?? "")
                          );
                          clearErrors("quantity");
                        }}
                        placeholder={t("contract:exporter.add.placeholder.qty")}
                        onClear={() => setValue("quantity", "")}
                        autoComplete="off"
                      />
                    </DoubleInputContainer>
                    {!!errors.quantity && (
                      <StyledInputError message={t("error:required")} />
                    )}
                  </FlexDiv>
                )}
              />
            </DoubleInputContainer>
          </CustomFormItemRow>

          <CustomFormItemRow>
            <Label required>
              {t("contract:exporter.add.common.unitPrice")}
            </Label>
            <DoubleInputContainer>
              <Controller
                name="unitPriceUnit"
                control={control}
                render={({ field }) => (
                  <FlexDiv>
                    <DoubleInputContainer>
                      <StyledSelect
                        {...field}
                        style={{ width: "108px", flexShrink: "0" }}
                        options={currencyOptions}
                        data-invalid={!!errors.unitPrice}
                        suffixIcon={
                          isCommonCodeFetching ? <Loader /> : undefined
                        }
                      />
                      <StyledInput
                        {...register("unitPrice", { required: true })}
                        style={{ width: "100%" }}
                        data-invalid={!!errors.unitPrice}
                        placeholder={t(
                          "contract:exporter.add.placeholder.unitPrice"
                        )}
                        onChange={(e) => {
                          const quantityWithDecimals =
                            handleIntegerWithDecimalsChange(e.target);

                          setValue(
                            "unitPrice",
                            String(quantityWithDecimals ?? "")
                          );
                        }}
                        onBlur={(e) => {
                          const quantityWithDecimals = getIntegerWithDecimal(
                            e.target.value
                          );

                          if (
                            quantityWithDecimals === "0.00" ||
                            quantityWithDecimals === ""
                          ) {
                            return setError("unitPrice", {
                              type: "required",
                            });
                          }

                          setValue(
                            "unitPrice",
                            String(quantityWithDecimals ?? "")
                          );
                          clearErrors("unitPrice");
                        }}
                        onClear={() => setValue("unitPrice", "")}
                        autoComplete="off"
                      />
                    </DoubleInputContainer>
                    {!!errors.unitPrice && (
                      <StyledInputError message={t("error:required")} />
                    )}
                  </FlexDiv>
                )}
              />
            </DoubleInputContainer>
          </CustomFormItemRow>

          <StyledSectionCardRow
            className="flex-column"
            label={<Label>{t("contract:exporter.add.common.amount")}</Label>}
            value={
              <AmountContent>
                <Typo typoType="b7r" color="blue4">
                  {parseFloat(
                    (
                      (parseFloat(watch("quantity").replace(/,/g, "")) || 0) *
                      (parseFloat(watch("unitPrice").replace(/,/g, "")) || 0)
                    ).toFixed(2)
                  ).toLocaleString("ko-KR", {
                    minimumFractionDigits:
                      watch("quantity") || watch("unitPrice") ? 2 : undefined,
                    maximumFractionDigits:
                      watch("quantity") || watch("unitPrice") ? 2 : undefined,
                  })}
                </Typo>{" "}
                <Typo typoType="b7r">{watch("unitPriceUnit")}</Typo> (
                <Typo typoType="b7r" color="blue4">
                  {Number(
                    parseFloat(watch("quantity").replace(/,/g, "")) || 0
                  ).toLocaleString("ko-KR")}{" "}
                </Typo>
                <Typo typoType="b7r">{watch("quantityUnit")}</Typo> X{" "}
                <Typo typoType="b7r" color="blue4">
                  {(
                    parseFloat(watch("unitPrice").replace(/,/g, "")) || 0
                  ).toLocaleString("ko-KR")}
                </Typo>{" "}
                <Typo typoType="b7r">{watch("unitPriceUnit")}</Typo>)
              </AmountContent>
            }
          />

          <Divider />

          <CustomFormItemRow>
            <StyledCustomLabel>
              {t("contract:exporter.add.common.paymentTerms")}
            </StyledCustomLabel>
            <FlexDiv style={{ gap: "8px" }}>
              <DoubleInputContainer>
                <Controller
                  name="paymentTermsCodeItemName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <StyledSelect
                        {...field}
                        style={{ width: "140px", flexShrink: "0" }}
                        data-invalid={!!errors.paymentTermsCodeItemName}
                        placeholder={t("contract:exporter.add.common.select")}
                        options={paymentTermsOptions}
                        showSearch
                        suffixIcon={
                          isCommonCodeFetching ? <Loader /> : undefined
                        }
                        disabled={isCommonCodeFetching}
                        onChange={(label, option: any) => {
                          field.onChange(label);
                          setValue("paymentTerms", option.label);

                          if (label !== "L/C") {
                            unregister("lcNo");
                            unregister("expireDateAt");
                          }
                        }}
                      />
                      <Input
                        {...register("paymentTermsRemark")}
                        style={{ width: "100%" }}
                        placeholder={t(
                          "contract:exporter.add.placeholder.paymentTermsRemark"
                        )}
                        onClear={() => setValue("paymentTermsRemark", "")}
                      />
                    </>
                  )}
                />
              </DoubleInputContainer>

              {errors.paymentTermsCodeItemName && (
                <InputError message={t("error:required")} />
              )}

              {watch("paymentTermsCodeItemName") === "L_C" && (
                <Controller
                  control={control}
                  name="lcNo"
                  render={({ field }) => {
                    return (
                      <SubFormRow>
                        <SubFormLabel>
                          {t("contract:exporter.add.common.lcNoComma")}
                        </SubFormLabel>
                        <FlexDiv>
                          <Input
                            {...field}
                            placeholder={t("placeholder:lcNo")}
                            onClear={() => setValue("lcNo", "")}
                          />
                        </FlexDiv>
                      </SubFormRow>
                    );
                  }}
                />
              )}
              {watch("paymentTermsCodeItemName") === "L_C" && (
                <>
                  <Controller
                    control={control}
                    name="paymentTermsMediaId"
                    render={() => {
                      return (
                        <SubFormRow>
                          <SubFormLabel>
                            {t("contract:exporter.add.common.lcFile")}
                          </SubFormLabel>
                          <FlexDiv>
                            <FileComponent
                              defaultFileList={
                                watch("paymentTermsMediaId")
                                  ? [
                                      new File(
                                        [""],
                                        watch("paymentTermsMediaId")
                                          ?.originalFileName ?? ""
                                      ),
                                    ]
                                  : undefined
                              }
                              onChange={(a) =>
                                setValue("paymentTermsMediaId", a[0])
                              }
                            />
                          </FlexDiv>
                        </SubFormRow>
                      );
                    }}
                  />
                </>
              )}
              {watch("paymentTermsCodeItemName") === "L_C" && (
                <Controller
                  control={control}
                  name="expireDateAt"
                  rules={{
                    required: watch("paymentTermsCodeItemName") === "L_C",
                  }}
                  render={({ field }) => {
                    return (
                      <SubFormRow>
                        <SubFormLabel>
                          {t("common:expireDate")}
                          <strong>*</strong>
                        </SubFormLabel>
                        <FlexDiv>
                          <DatePickerContainer
                            data-invalid={!!errors.expireDateAt}
                          >
                            <ConfigProvider
                              theme={{
                                components: {
                                  DatePicker: {
                                    fontSize: 16,
                                  },
                                },
                              }}
                            >
                              <DatePicker
                                {...field}
                                style={{ width: "100%" }}
                                onChange={(day) => {
                                  setValue(
                                    "expireDateAt",
                                    dayjs(
                                      dayjs(day).format("YYYY-MM-DD"),
                                      "YYYY-MM-DD"
                                    )
                                  );
                                }}
                              />
                            </ConfigProvider>
                          </DatePickerContainer>
                          {!!errors.expireDateAt && (
                            <StyledInputError message={t("error:required")} />
                          )}
                        </FlexDiv>
                      </SubFormRow>
                    );
                  }}
                />
              )}
            </FlexDiv>
          </CustomFormItemRow>
          <StyledFormItem
            label={t("contract:exporter.add.common.optional")}
            type="checkbox"
            control={control as any}
            name="isAddBankDetail"
            options={[
              {
                label: t("contract:exporter.add.common.addBankDetail"),
                value: "true",
              },
            ]}
          />
        </Flex>
      </SectionCard>
    );
  };

  // Shipping Terms
  const renderShippingTermsInformation = () => {
    return (
      <SectionCard
        cardTitle={t("contract:exporter.add.common.shippingTerms")}
        cardContentContainerStyle={{ display: "flex", gap: "24px" }}
      >
        <HalfFormItemContainer>
          <CustomFormItemRow>
            <StyledCustomLabel>
              {t("contract:exporter.add.common.shippingTerms")}
            </StyledCustomLabel>
            <DoubleInputContainerWithError style={{ flex: 1 }}>
              <DoubleInputContainer>
                <Controller
                  name="shippingTermsCodeItemName"
                  rules={{ required: true }}
                  control={control}
                  render={({ field }) => (
                    <>
                      <StyledSelect
                        {...field}
                        style={{ width: "140px", flexShrink: "0" }}
                        placeholder={t("contract:exporter.add.common.select")}
                        options={shippingTermsOptions}
                        data-invalid={!!errors.shippingTermsCodeItemName}
                        showSearch
                        suffixIcon={
                          isCommonCodeFetching ? <Loader /> : undefined
                        }
                        disabled={isCommonCodeFetching}
                        onChange={(value, option: any) => {
                          field.onChange(value);
                          setValue("shippingTerms", option.value);
                        }}
                      />
                      <Input
                        {...register("shippingTermsRemark")}
                        style={{ width: "100%" }}
                        placeholder={t(
                          "contract:exporter.add.placeholder.shippingTermsRemark"
                        )}
                        onClear={() => setValue("shippingTermsRemark", "")}
                      />
                    </>
                  )}
                />
              </DoubleInputContainer>
              {errors.shippingTermsCodeItemName && (
                <InputError message={t("error:required")} />
              )}
            </DoubleInputContainerWithError>
          </CustomFormItemRow>

          <StyledFormItem
            label={t("contract:exporter.add.common.lastShipmentDate")}
            type="date"
            control={control as any}
            name="lastShipmentDateAt"
            inputProps={{
              allowClear: true,
              showToday: false,
              renderExtraFooter: () => (
                <DatePickerNoticeTypo
                  text={t("common:datePickerFooterLastShipmentDate")}
                />
              ),
            }}
          />

          <StyledFormItem
            label={t("contract:exporter.add.common.freetime")}
            type="singleSelect"
            name={"freetimeCodeItemName"}
            control={control as any}
            options={[
              {
                label: t(DIRECT_INPUT_LABEL),
                value: DIRECT_INPUT_VALUE,
              },
              ...freetimeOptions,
            ]}
            inputContainerClassName="selector-size-m"
            inputProps={{
              placeholder: t("contract:exporter.add.placeholder.freetime"),
              virtual: false,
              allowClear: true,
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              filterOption: (input, option) =>
                ((option?.label as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase()) ||
                ((option?.value as string) ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase()) ||
                option?.value === DIRECT_INPUT_VALUE,
              dropdownRender: (menu) => {
                return <CustomDropdownWrapper>{menu}</CustomDropdownWrapper>;
              },
              onChange: (label: any, option: any) => {
                if (option) {
                  setValue("freetime", option.label);
                }
              },
              onClear: () => {
                setValue("freetime", undefined);
              },
            }}
            bottomAccessory={
              watch("freetimeCodeItemName") === DIRECT_INPUT_VALUE ? (
                <Input
                  {...register("freetimeDirect")}
                  placeholder={t("placeholder:enterFreetime")}
                  onClear={() => setValue("freetimeDirect", "")}
                />
              ) : null
            }
          />
        </HalfFormItemContainer>
        <HalfFormItemContainer>
          <StyledFormItem
            label={t("contract:exporter.add.common.origin")}
            type={"text"}
            name={"origin"}
            control={control as any}
            inputProps={{
              placeholder: t("contract:exporter.add.placeholder.origin"),
            }}
          />
          <StyledFormItem
            label={t("contract:exporter.add.common.placeOfDelivery")}
            type="singleSelect"
            name={"placeOfDeliveryCodeItemName"}
            control={control as any}
            options={placeOfDeliveryOptions}
            inputContainerClassName="selector-size-m"
            inputProps={{
              placeholder: t(
                "contract:exporter.add.placeholder.placeOfDelivery"
              ),
              allowClear: true,
              disabled: watch("notYetSpecified") || isCommonCodeFetching,
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              onChange: (label: any, option: any) => {
                if (option) {
                  setValue("placeOfDelivery", option.label);
                }
              },
              onClear: () => {
                setValue("placeOfDelivery", undefined);
                setValue("placeOfDeliveryCodeItemName", undefined);
              },
            }}
            bottomAccessory={
              <>
                <CheckboxLabel>
                  <Checkbox
                    checked={watch("notYetSpecified")}
                    onChange={(e) => {
                      setValue("notYetSpecified", e.target.checked);
                    }}
                  />
                  <Typo typoType="b7r">
                    {t("contract:exporter.add.common.notYetSpecified")}
                  </Typo>
                </CheckboxLabel>
                {watch("notYetSpecified") && (
                  <SubFormRow>
                    <SubFormLabel>
                      {t("contract:exporter.add.common.anyPortIn")}
                      <strong>*</strong>
                    </SubFormLabel>
                    <FlexDiv
                      style={{ gap: "8px", width: "calc(100% - 108px)" }}
                    >
                      <Controller
                        name="anyPortIn"
                        control={control}
                        rules={{ required: watch("notYetSpecified") }}
                        render={({ field }) => (
                          <StyledSelect
                            {...field}
                            value={
                              countryList?.find(
                                ({ codeItemName }) =>
                                  codeItemName ===
                                  watch("anyPortInCodeItemName")
                              )?.codeItemNameKo
                            }
                            data-invalid={!!errors.anyPortIn}
                            onChange={(value, option: any) => {
                              field.onChange(option.label);
                              setValue(
                                "anyPortInCodeItemName",
                                (option.label as string).split(")")[0]
                              );
                            }}
                            disabled={isCommonCodeFetching}
                            showSearch
                            filterOption={(input, option) =>
                              ((option?.label as string) ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase()) ||
                              ((option?.value as string) ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            options={countryListOptions}
                            placeholder={t(
                              "contract:exporter.add.placeholder.anyPortIn"
                            )}
                            suffixIcon={
                              isCommonCodeFetching ? <Loader /> : undefined
                            }
                          />
                        )}
                      />

                      {errors.anyPortIn && (
                        <InputError message={t("error:required")} />
                      )}
                    </FlexDiv>
                  </SubFormRow>
                )}
              </>
            }
          />
          <StyledFormItem
            label={t("contract:exporter.add.common.portOfDischarge")}
            type="singleSelect"
            name={"portOfDischargeCodeItemName"}
            control={control as any}
            options={placeOfDeliveryOptions}
            inputContainerClassName="selector-size-m"
            inputProps={{
              placeholder: t(
                "contract:exporter.add.placeholder.placeOfDelivery"
              ),
              allowClear: true,
              disabled: isCommonCodeFetching,
              suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
              onChange: (label: any, option: any) => {
                if (option) {
                  setValue("portOfDischarge", option.label);
                }
              },
              onClear: () => {
                setValue("portOfDischarge", undefined);
                setValue("portOfDischargeCodeItemName", undefined);
              },
            }}
          />
        </HalfFormItemContainer>
      </SectionCard>
    );
  };

  // 첨부 파일
  const renderAttachmentFile = () => {
    return (
      <StyledSectionCard
        cardTitle={t("contract:exporter.add.common.attachmentFile")}
      >
        <FlexGapDiv>
          <StyledFormItem
            label={t("contract:exporter.add.common.poFile")}
            type="file"
            name="poMediaId"
            control={control as any}
            inputProps={{
              defaultFileList: watch("poMediaId")
                ? [
                    new File(
                      [""],
                      watch("poMediaId")?.[0]?.originalFileName ?? ""
                    ),
                  ]
                : undefined,
              onRemove: () => setValue("poMediaId", undefined),
            }}
          />
          <StyledFormItem
            label={t("contract:exporter.add.common.scFile")}
            type="file"
            name="scMediaId"
            control={control as any}
            bottomAccessory={
              <WarningDescription>
                <StyledIcon iconSrc={InfoSvg} iconSize={16} />
                <Typo typoType="b9r" color="gray6">
                  {t("contract:exporter.add.placeholder.scFile")}
                </Typo>
              </WarningDescription>
            }
            inputProps={{
              defaultFileList: watch("scMediaId")
                ? [
                    new File(
                      [""],
                      watch("scMediaId")?.[0]?.originalFileName ?? ""
                    ),
                  ]
                : undefined,
              onRemove: () => setValue("scMediaId", undefined),
            }}
          />
          <StyledFormItem
            label={t("contract:exporter.add.common.referenceImage")}
            type="imageUpload"
            name="referenceMediaIdList"
            control={control as any}
            errorsMessage={{
              required: t("error:required"),
            }}
            bottomAccessory={
              <WarningDescription>
                <StyledIcon iconSrc={InfoSvg} iconSize={16} />
                <Typo typoType="b9r" color="gray6">
                  {t("contract:exporter.add.placeholder.referenceImage")}
                </Typo>
              </WarningDescription>
            }
            inputProps={{
              imageSrcList: watch("referenceMediaIdList")?.map(
                ({ mediaUrl }) => {
                  return {
                    imageSrc: mediaUrl,
                    onClick: () => handleImageClick(mediaUrl),
                  };
                }
              ),
              onRemove: (removeItemUrl) => {
                setValue(
                  "referenceMediaIdList",
                  watch("referenceMediaIdList").filter(
                    ({ mediaUrl }) => mediaUrl !== removeItemUrl
                  )
                );
              },
            }}
          />
        </FlexGapDiv>
      </StyledSectionCard>
    );
  };

  // Remark
  const renderContractRemark = () => {
    return (
      <StyledSectionCard
        cardTitle={t("contract:exporter.add.common.contractRemark")}
      >
        <FormItem
          type="textarea"
          name="contractRemark"
          control={control}
          inputProps={{
            style: { width: "100%" },
            placeholder: t("contract:exporter.add.placeholder.contractRemark"),
            maxLength: 1000,
          }}
          noLabel
        />
      </StyledSectionCard>
    );
  };

  // Memo
  const renderMemo = () => {
    return (
      <StyledSectionCard
        cardTitle={t("contract:exporter.add.common.memo")}
        cardContentContainerStyle={{ display: "flex", gap: "24px" }}
      >
        <MemoHalfContainer>
          <MemoPlaceholder>
            <Icon iconSrc={NoDataBagSvg} iconSize={56} />
            <Typo as="p" typoType="b7m" color="gray8">
              {t("contract:exporter.add.common.noData")}
            </Typo>
          </MemoPlaceholder>
        </MemoHalfContainer>
        <MemoHalfContainer>
          <MemoInputContainer>
            <WarningDescription style={{ color: colorSet.gray6 }}>
              <StyledIcon iconSrc={InfoGray6Svg} iconSize={16} />
              {t("contract:exporter.add.memoInfo")}
            </WarningDescription>
            <FormItem
              type="textarea"
              name="memo"
              control={control}
              inputProps={{
                style: { width: "100%" },
                placeholder: t("contract:exporter.add.placeholder.memo"),
                maxLength: 500,
              }}
              noLabel
            />
          </MemoInputContainer>
        </MemoHalfContainer>
      </StyledSectionCard>
    );
  };

  const renderDialog = () => {
    if (dialogState === DialogState.IMAGE && selectImage) {
      return (
        <ReferenceImageDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          imageList={referenceImageList ?? [""]}
          selectImage={selectImage}
          onChangeSelect={setSelectImage}
        />
      );
    }
    // 계약 불러오기
    if (dialogState === DialogState.LOAD_CONTRACT) {
      return (
        <LoadContractDialog
          open
          onClose={() => setDialogState(DialogState.NULL)}
          onFetchContractViaId={handleLoadDataOverride}
        />
      );
    }
    // 아이템 추가
    if (dialogState === DialogState.ADD_ITEM) {
      return (
        <AddItemDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          isSingleItemCode
          onSave={handleAddItems}
          allowedMainCategories={buyerMainCategory}
        />
      );
    }
    // 거래처 추가
    if (dialogState === DialogState.ADD_CONTACT_PERSON) {
      const selectedBuyer = buyerList.find(
        ({ id }) => watch("buyerId") === id.toString()
      );

      return (
        <BuyerEmployeeAddDialog
          onSave={handleAddBuyerEmployee}
          onClose={() => setDialogState(DialogState.NULL)}
          defaultCountryCode={selectedBuyer?.telPrefix}
        />
      );
    }
    // 약관추가
    if (dialogState === DialogState.CREATE_TERMS) {
      return (
        <TermsAddDialog
          title={t("common:registerContractTerms")}
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          onSave={async () => await exportersData.refetch()}
        />
      );
    }
    // 서명추가
    if (dialogState === DialogState.REGISTER_SIGN) {
      return (
        <RegisterCompanySignDialog
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }
  };

  const renderAlertDialog = () => {
    const closeAlertDialog = () => setAlertDialogState(AlertDialogState.NULL);
    // 임시저장
    if (alertDialogState === AlertDialogState.SAVED_DATA) {
      return (
        <AlertDialog
          open
          title={t("contract:exporter.add.alertDialog.loadTemporarySave.title")}
          onOpenChange={() => {
            closeAlertDialog();
            setFocus("scNo");
          }}
          onOk={() => {
            handleOverrideData();
            closeAlertDialog();
          }}
          okText={t("contract:exporter.add.common.ok")}
          cancelText={t("contract:exporter.add.common.exit")}
        >
          {t("contract:exporter.add.alertDialog.loadTemporarySave.description")}
        </AlertDialog>
      );
    }
    // 뒤로가기
    if (alertDialogState === AlertDialogState.BACK_TO_PREVIOUS) {
      return (
        <AlertDialog
          open
          title={t("contract:exporter.add.alertDialog.backToPrevious.title")}
          onOpenChange={closeAlertDialog}
          onOk={() => navigate(-1)}
          okText={t("contract:exporter.add.common.ok")}
          cancelText={t("contract:exporter.add.common.exit")}
        >
          {t("contract:exporter.add.alertDialog.backToPrevious.description")}
        </AlertDialog>
      );
    }
    // 계약셍성 (거래처 연결되어있는 경우는 바로 보낼지 Y/N 노출)
    if (alertDialogState === AlertDialogState.SEND_TO_BUYER) {
      return (
        <AlertDialog
          open
          title={t("contract:exporter.add.alertDialog.createContract.title")}
          onOpenChange={closeAlertDialog}
          okText={t("contract:exporter.add.common.ok")}
          cancelText={t("contract:exporter.add.common.exit")}
          onOk={() => {
            const defaultCreate = () => {
              createScContract(defaultSignatureMediaId);
              closeAlertDialog();
            };
            /**
             * - 서명이 2개일 경우 서명선택모달
             * - 서명이 1개일 경우 default
             */
            isTwoSign
              ? setAlertDialogState(AlertDialogState.SIGNATURE_SELECT_SC)
              : defaultCreate();
          }}
        >
          <FlexGap flexGap={16}>
            {t("contract:exporter.add.alertDialog.createContract.description")}
            {isBuyerLinkedStatus ? (
              <FlexGap flexGap={8}>
                <Typo typoType="b9r" color="gray6">
                  {t("contract:exporter.add.common.sendScToBuyer")}
                </Typo>

                <RadioGroup
                  value={isSendScToBuyer}
                  onChange={() => {
                    setIsSendScToBuyer(!isSendScToBuyer);
                  }}
                  options={[
                    {
                      value: true,
                      label: "Y",
                    },
                    {
                      value: false,
                      label: "N",
                    },
                  ]}
                  name="sendSc"
                />
              </FlexGap>
            ) : null}
          </FlexGap>
        </AlertDialog>
      );
    }
    // 서명등록
    if (alertDialogState === AlertDialogState.REGISTER_SIGN) {
      return (
        <NoRegisteredSignatureAlertDialog
          open
          onOpenChange={closeAlertDialog}
          onOk={() => {
            setDialogState(DialogState.REGISTER_SIGN);
            closeAlertDialog();
          }}
          onCancel={closeAlertDialog}
        />
      );
    }
    // 서명등록 요청
    if (alertDialogState === AlertDialogState.SIGNATURE_REQUEST) {
      return (
        <SignatureRequestAlertDialog
          open
          onOpenChange={closeAlertDialog}
          onCancel={closeAlertDialog}
        />
      );
    }
    // SC서명선택 (서명 2개일 경우)
    if (alertDialogState === AlertDialogState.SIGNATURE_SELECT_SC) {
      return (
        <SignatureSelectDialog
          open
          onSave={(selectSignatureMediaId) => {
            createScContract(selectSignatureMediaId);
            closeAlertDialog();
          }}
          onOpenChange={closeAlertDialog}
        />
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await getSavedData({ storageTarget: "CONTRACT" }).unwrap();

        if (data?.row.jsonData?.buyerId) {
          await getBuyerEmployees({
            id: data.row.jsonData.buyerId,
          });
        }

        if (data) {
          setAlertDialogState(AlertDialogState.SAVED_DATA);
          setSavedData(data.row.jsonData);
        }
      } catch (e) {
        setFocus("scNo");
      }
    })();
  }, [getBuyerEmployees, getSavedData, reset, setFocus]);

  return (
    <ExporterMainLayout
      breadcrumb={[t("sideNav:contract"), t("sideNav:exportContractCreate")]}
      customPageTitle={
        <HeaderInnerDiv>
          <h1>{t("sideNav:exportContractCreate")}</h1>
        </HeaderInnerDiv>
      }
      pageTitle={t("sideNav:exportContractCreate")}
    >
      <ContentSection>
        <StyledForm onSubmit={handleSubmit(handleCreateContract)}>
          <Flex justifyContent="space-between">
            <WarningDescription>
              <Icon iconSrc={InfoSvg} iconSize={16} />
              {t("contract:exporter.add.contractAddInfo")}
            </WarningDescription>

            <StyleButton
              buttonGrade="secondary"
              buttonSize={32}
              onClick={() => setDialogState(DialogState.LOAD_CONTRACT)}
            >
              {t("contract:exporter.add.buttonLabel.loadContract")}
            </StyleButton>
          </Flex>

          <Grid>
            {renderBasicInformation()}
            {renderItem()}
          </Grid>
          {renderShippingTermsInformation()}
          <Grid>
            {renderAttachmentFile()}
            {renderContractRemark()}
          </Grid>
          {renderMemo()}

          <BottomFixedContainer>
            <FooterButtonSection>
              <Button
                buttonGrade="tertiary"
                buttonColor="black"
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
                onClick={() =>
                  setAlertDialogState(AlertDialogState.BACK_TO_PREVIOUS)
                }
              >
                <Icon iconSrc={ChevronLeftSvg} iconSize={16} />{" "}
                {t("contract:exporter.add.buttonLabel.backToPrevious")}
              </Button>

              <SaveButtonContainer>
                {(!watch("scMediaId") || !watch("scMediaId")?.length) && (
                  <Button
                    ref={previewButtonRef}
                    onClick={() => {
                      previewScPdfResourceRef.current = getValues();
                      setIsPreviewLoading(true);

                      setTimeout(() => {
                        if (previewButtonRef.current) {
                          previewButtonRef.current.click();
                          setIsPreviewLoading(false);
                        }
                      }, 1500);
                      handlePreview();
                    }}
                    buttonGrade="tertiary"
                    buttonColor="black"
                    isLoading={exportersData.isFetching || isPreviewLoading}
                    disabled={exportersData.isFetching || isPreviewLoading}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <Icon iconSrc={PreviewSvg} iconSize={16} />
                    {t("contract:exporter.add.buttonLabel.preview")}
                  </Button>
                )}

                <Button
                  buttonGrade="secondary"
                  buttonColor="blue"
                  onClick={handleTempSave}
                >
                  {t("contract:exporter.add.buttonLabel.temporarySave")}
                </Button>
                <Button
                  buttonGrade="primary"
                  type="button"
                  style={{ width: 158 }}
                  onClick={() => {
                    submitButtonRef.current?.click();
                  }}
                  disabled={isCreateDisabled}
                >
                  {t("contract:exporter.add.buttonLabel.save")}
                </Button>

                <HiddenButton type="submit" ref={submitButtonRef} />
              </SaveButtonContainer>
            </FooterButtonSection>
          </BottomFixedContainer>
        </StyledForm>
      </ContentSection>

      {renderDialog()}
      {renderAlertDialog()}
    </ExporterMainLayout>
  );
};

export default ContractAddPage;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SaveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
`;

const StyleButton = styled(Button)`
  ${typo.btn3m}
`;

const HeaderInnerDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;

  h1 {
    ${typo.h1}
  }

  ${StyleButton} {
    flex-shrink: 0;
    margin-left: auto;
  }
`;

const WarningDescription = styled.p`
  ${typo.b9r};
  color: ${colorSet.gray4};
  display: flex;
  align-items: center;
  gap: 6px;
`;

const HalfFormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 12px);
  gap: 24px;
`;

const SubFormLabel = styled.p`
  color: ${colorSet.gray6};
  ${typo.b9r};
  strong {
    color: ${colorSet.red2};
    font-size: 12px;
    padding-left: 2px;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  &.selector-size-s {
    width: calc(100% - 164px - 16px - 100px);
  }
`;

const SubFormRow = styled.div<{ isRequired?: boolean }>`
  display: flex;
  width: 100%;
  gap: 8px;

  ${FlexDiv} {
    flex: 1;
  }
  ${SubFormLabel} {
    width: 100px;
    flex-shrink: 0;
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
  &[data-invalid="true"] {
    .ant-select-selector {
      border: 1px solid ${colorSet.red2} !important;
    }
  }
  .ant-select-selection-placeholder {
    width: 1px;
  }
`;

const StyledCustomLabel = styled.p`
  width: 164px;
  color: ${colorSet.gray1};
  flex-shrink: 0;
  ${typo.b7r};

  &::after {
    content: " *";
    color: ${colorSet.red2};
  }
`;

const CustomFormItemRow = styled.div`
  display: flex;
  gap: 8px;

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
  }
`;

const DoubleInputContainer = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;

  &.fixed-width-container {
    width: calc(100% - 172px);

    ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
      width: 100%;
    }
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledSectionCard = styled(SectionCard)`
  flex: 1;
`;

const MemoHalfContainer = styled.div`
  flex: 1;
`;

const MemoPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
`;

const MemoInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FlexGapDiv = styled(FlexDiv)`
  gap: 24px;
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 72px;
`;

const StyledInputError = styled(InputError)`
  padding-top: 8px;
`;

const DoubleInputContainerWithError = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const FooterButtonSection = styled.section`
  display: flex;
  justify-content: space-between;
`;

const StyledFormItem = styled(FormItem)`
  .ant-select-selection-placeholder {
    width: 1px;
  }
  .selector-size-m {
    width: calc(100% - 164px - 8px);
  }

  .ant-picker .ant-picker-clear {
    width: 18px;
    height: 18px;
    outline: 1px solid white;
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;

    .selector-size-m {
      width: 100%;
    }
  }
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  &.flex-column {
    ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
      flex-direction: column !important;
      gap: 8px;
    }
  }
`;

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

const DatePickerContainer = styled.div`
  &[data-invalid="true"] {
    .ant-picker {
      border: 1px solid ${colorSet.red2};
    }
  }
`;

const AddIcon = styled(AddSvg)<{ disabled?: boolean }>`
  width: 16px;
  height: 16px;

  path {
    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `};
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-shrink: 0;
`;

const CustomDropdownWrapper = styled.div`
  div.ant-select-item {
    &:first-of-type {
      color: ${colorSet.blue4};

      &::before {
        content: url(${AddBlueSvg});
        width: 18px;
        height: 18px;
        position: relative;
        margin-right: 8px;
        top: 4px;
      }
    }
  }
`;

const HiddenButton = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const FlexGap = styled(FlexDiv)<{ flexGap?: number }>`
  ${({ flexGap }) =>
    flexGap &&
    css`
      gap: ${flexGap}px;
    `};
`;

const DirectInputSelectOptionButton = styled(SelectOptionButton)`
  color: ${colorSet.blue4};

  &:hover {
    &&& {
      color: ${colorSet.blue4};
    }
  }

  &::before {
    content: url(${AddBlueSvg});
    width: 18px;
    height: 18px;
    position: relative;
    margin-right: 8px;
    top: 4px;
  }
`;

const Grid = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
`;

const AmountContent = styled.div`
  color: ${colorSet.gray2};
`;
