import AlertDialog from "@/src/components/atom/AlertDialog";
import SignUpLayout from "@/src/components/template/Layout/SignUpLayout";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import PUBLIC_PATH from "@/src/routes/public/path";
import { useAppDispatch, useAppSelector } from "@/src/store";
import { setRedirectUrl } from "@/src/store/slice/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import EnterAccountCode from "./components/steps/EnterAccountCode";
import LinkSuccess from "./components/steps/LinkSuccess";

enum AlertDialogState {
  NULL,
  DUPLICATE,
}

const LinkToAccountPage = () => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [params] = useSearchParams();
  const linkCode = params.get("code");

  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [step, setStep] = useState<1 | 2>(1);

  if (!user) {
    dispatch(setRedirectUrl(`${location.pathname}${location.search}`));
    return <Navigate to={PUBLIC_PATH.LOGIN} replace />;
  }

  if (
    user &&
    (user.exporterUserType === "MIDDLE_MANAGER" ||
      user.exporterUserType === "MANAGER")
  ) {
    const type = user.exporterUserMainFieldType;
    if (type === "BOTH" || type === "SELLER") {
      return <Navigate to={EXPORTER_PRIVATE_PATH.HOME} replace />;
    }
    return <Navigate to={IMPORTER_PRIVATE_PATH.HOME} replace />;
  }

  const renderContentByStep = () => {
    switch (step) {
      case 1:
        return (
          <EnterAccountCode onStepChange={setStep} linkCode={linkCode ?? ""} />
        );
      case 2:
        return <LinkSuccess />;
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.DUPLICATE) {
      return (
        <AlertDialog
          open
          title={t(
            "linkToAccount:integrated.integrationCompletionCodeGuidance"
          )}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          cancelText={""}
          okText={t("linkToAccount:integrated.ok")}
        >
          {t(
            "linkToAccount:integrated.integrationCompletionCodeGuidanceDescription"
          )}
        </AlertDialog>
      );
    }
  };

  return (
    <SignUpLayout>
      {renderContentByStep()}
      {renderAlertDialog()}
    </SignUpLayout>
  );
};

export default LinkToAccountPage;
