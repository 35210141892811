import ArrowDownSvg from "@/src/assets/icons/icon-chevron-down.svg";
import { ReactComponent as DownloadSvg } from "@/src/assets/icons/icon-download-black.svg";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import SectionCard from "@/src/components/molecule/SectionCard";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import TableBlueText from "@/src/components/molecule/TableBlueText";
import ViewPhotoCollectionDialog, {
  ContainerMediaData,
  ViewPhotoCollectionDialogProps,
} from "@/src/components/organism/ViewPhotoCollectionDialog";
import useAlert from "@/src/hooks/useAlert";
import TaskThumbnail from "@/src/pages/exporter/Task/components/detail/TaskThumbnail";
import {
  renderContainerIcon,
  renderContainerText,
} from "@/src/pages/exporter/Task/components/detail/utils/renderContainerType";
import { renderLoadingContainerStatusBadge } from "@/src/pages/exporter/Task/utils/renderTaskStatusBadge";
import { useContainerIdToSeaVantageUrlMutation } from "@/src/store/apis/seavantage";
import {
  taskDetailApi,
  useTaskDetailPrefetch,
} from "@/src/store/apis/tasks/taskDetail";
import {
  ContainerMediaType,
  ContainerStatusType,
  SimpleContainerMediaDto,
  TaskDetailViewDto,
} from "@/src/store/apis/tasks/taskDetail/interface";
import {
  taskShareApi,
  useTaskSharedPrefetch,
} from "@/src/store/apis/tasks/taskShare";
import {
  ContainerShareInfoDto,
  ContainerShareInfoListDto,
  TaskBookingContractShareInfoV2Dto,
} from "@/src/store/apis/tasks/taskShare/interface";
import { slideDown, slideUp } from "@/src/styles/animation";
import colorSet, { ColorType } from "@/src/styles/color";
import { aesDecrypt } from "@/src/utils/aesDecrypt";
import { downloadFile } from "@/src/utils/downloadFile";
import { transformURLSearchParamsToObject } from "@/src/utils/transform";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@radix-ui/react-accordion";
import { useId, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import styled, { css } from "styled-components";
import { TaskDecrypt } from "../SharedTask";
import Flex from "@/src/components/molecule/Flex";
import { formatSingleContainerMedia } from "@/src/utils/format";

type TaskContainerDialogItemType =
  | "CONTAINER_TASK"
  | "VIEW_PHOTO"
  | "ADDITIONAL_INFO"
  | null;

interface ContainerAccordionItemProps {
  containerId: number;
  onCheckChange?: (id: number, checked: boolean) => void;
  containerNo?: string;
  sealNo?: string;
  containerStatus: ContainerStatusType;
  index: number;
  extraRemark?: string;
  taskData?:
    | TaskBookingContractShareInfoV2Dto["taskDetailShareInfo"]
    | TaskDetailViewDto;
}

const containerCountMediaMap = {
  containerSampleBaleMediasCount: "sampleBaleSimpleContainerMedias",
  containerEmptyMediasCount: "emptySimpleContainerMedias",
  containerQuarterLoadedMediasCount: "quarterLoadedSimpleContainerMedias",
  containerHalfLoadedMediasCount: "halfLoadedSimpleContainerMedias",
  containerFullLoadedMediasCount: "fullLoadedSimpleContainerMedias",
  containerOneDoorClosedMediasCount: "oneDoorClosedSimpleContainerMedias",
  containerBothDoorsClosedMediasCount: "bothDoorsClosedSimpleContainerMedias",
  containerCloseUpSealMediasCount: "closeUpSealSimpleContainerMedias",
  containerExtraMediasCount: "extraSimpleContainerMedias",
};

function SharedTaskAccordionItem({
  containerId,
  containerNo,
  sealNo,
  containerStatus,
  index,
  extraRemark,
  taskData,
}: ContainerAccordionItemProps) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const alert = useAlert();
  const isFetched = useRef(false);
  const id = useId();
  const loadingImageRef = useRef<HTMLDivElement>(null);
  const loadingWeightRef = useRef<HTMLDivElement>(null);
  const isPreview = searchParams.get("preview") === "true";

  const location = useLocation();
  const sharedTaskEncryptKey = location.search.slice(1);
  const taskDecryptToObject = transformURLSearchParamsToObject(
    aesDecrypt(sharedTaskEncryptKey)
  ) as TaskDecrypt;

  const [selectDialog, setSelectDialog] =
    useState<TaskContainerDialogItemType>(null);
  const [startIndex, setStartIndex] = useState<number>();
  const [selectLoading, setSelectLoading] = useState<
    ContainerShareInfoListDto | ContainerShareInfoDto | null
  >(null);
  const [isPreviewDownloadLoading, setIsPreviewDownloadLoading] =
    useState(false);
  const [selectImageUrl, setSelectImage] = useState<ContainerMediaData | null>(
    null
  );

  const {
    currentData: sharedTypeContainerData,
    isFetching: isSharedTypeContainerFetching,
  } = taskShareApi.endpoints.getTaskContainerShared.useQueryState({
    id: containerId,
    taskId: Number(taskDecryptToObject.taskId),
    taskShareKey: taskDecryptToObject.taskShareKey,
  });
  const {
    currentData: previewTypeContainerData,
    isFetching: isPreviewTypeFetching,
  } = taskDetailApi.endpoints.getTaskDetailContainer.useQueryState({
    id: containerId,
  });
  const [containerIdToSeaVantageUrl] = useContainerIdToSeaVantageUrlMutation();
  const prefetch = useTaskSharedPrefetch("getTaskContainerShared");
  const previewTypePrefetch = useTaskDetailPrefetch("getTaskDetailContainer");

  const containerData = {
    currentData: isPreview ? previewTypeContainerData : sharedTypeContainerData,
    isFetching: isPreview
      ? isPreviewTypeFetching
      : isSharedTypeContainerFetching,
  };

  const handlePrefetch = async () => {
    try {
      if (isPreview) {
        await previewTypePrefetch(
          { id: containerId },
          { force: !isFetched.current }
        );
        isFetched.current = true;

        return;
      }

      await prefetch(
        {
          id: containerId,
          taskId: Number(taskDecryptToObject.taskId),
          taskShareKey: taskDecryptToObject.taskShareKey,
        },
        { force: !isFetched.current }
      );
      isFetched.current = true;
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleContainerNoClick = async () => {
    try {
      const { cargoTrackUrl } = await containerIdToSeaVantageUrl({
        containerId,
      }).unwrap();

      window.open(cargoTrackUrl, "_blank");
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const countKeyConvertToContainerType = (
    countKey: keyof typeof containerCountMediaMap
  ): ContainerMediaType | undefined => {
    if (countKey === "containerSampleBaleMediasCount") {
      return "SAMPLE_BALE";
    }
    if (countKey === "containerEmptyMediasCount") {
      return "EMPTY";
    }
    if (countKey === "containerQuarterLoadedMediasCount") {
      return "QUARTER_LOADED";
    }
    if (countKey === "containerHalfLoadedMediasCount") {
      return "HALF_LOADED";
    }
    if (countKey === "containerFullLoadedMediasCount") {
      return "FULL_LOADED";
    }
    if (countKey === "containerOneDoorClosedMediasCount") {
      return "ONE_DOOR_CLOSED";
    }
    if (countKey === "containerBothDoorsClosedMediasCount") {
      return "BOTH_DOORS_CLOSED";
    }
    if (countKey === "containerCloseUpSealMediasCount") {
      return "CLOSE_UP_SEAL";
    }
    if (countKey === "containerExtraMediasCount") {
      return "EXTRA";
    }
  };

  const containerImageListMaping = Object.entries(
    containerCountMediaMap
  ).reduce<{
    imageResource: {
      isUsed: boolean;
      imageList: (string | undefined)[];
      icon: any;
      title: any;
      mediaDataList: ContainerMediaData[];
      description?: string;
    }[];
  }>(
    (acc, [countKey, mediaKey]) => {
      const count =
        containerData.currentData?.[countKey as keyof ContainerShareInfoDto] ??
        0;

      const mediaDataList = count
        ? ((
            containerData.currentData?.[
              mediaKey as keyof ContainerShareInfoDto
            ] as SimpleContainerMediaDto[]
          )?.map(
            ({
              coordinate,
              containerPhotoSimpleMedia,
              containerMediaCreatedAt,
            }) => {
              return {
                createAt: containerMediaCreatedAt,
                latitude: coordinate?.latitude ?? undefined,
                longitude: coordinate?.longitude ?? undefined,
                zoomLevel: coordinate?.zoomLevel
                  ? Number(coordinate?.zoomLevel)
                  : undefined,
                mediaCoordinateRange:
                  coordinate?.mediaCoordinateRange ?? undefined,
                originalFileName:
                  containerPhotoSimpleMedia.originalFileName ?? undefined,
                mediaUrl: containerPhotoSimpleMedia
                  ? containerPhotoSimpleMedia?.mediaUrl
                  : undefined,
              };
            }
          ) ?? [])
        : [];

      const imageList = count
        ? ((
            containerData.currentData?.[
              mediaKey as keyof ContainerShareInfoDto
            ] as SimpleContainerMediaDto[]
          )?.map(({ containerPhotoSimpleMedia }) => {
            return containerPhotoSimpleMedia.mediaUrl;
          }) ?? [])
        : [];

      const containerType = countKeyConvertToContainerType(
        countKey as keyof typeof containerCountMediaMap
      );

      const resource = {
        isUsed: !!count,
        imageList: [
          ...(imageList as unknown as string[]),
          ...Array.from({ length: count as number }, () => undefined),
        ].slice(0, count as number),
        icon: renderContainerIcon(containerType),
        title: renderContainerText(t, containerType, "b7m"),
        description:
          containerType === "EXTRA" ? (extraRemark ?? undefined) : undefined,
        mediaDataList,
      };

      return {
        imageResource: [...acc.imageResource, resource].filter(
          (item) => item.isUsed
        ),
      };
    },
    {
      imageResource: [],
    }
  );

  const photoTypeList = containerData.currentData
    ? formatSingleContainerMedia(containerData.currentData)
    : { folderName: "", images: [] };

  const allImageList = containerImageListMaping.imageResource.reduce<string[]>(
    (acc, val) => {
      const validImage = val.imageList.filter((image) => image) as string[];

      return [...acc, ...validImage];
    },
    []
  );

  const handlePreviewDownloadClick = async () => {
    setIsPreviewDownloadLoading(true);
    try {
      const selectUrl = selectImageUrl;
      if (!selectUrl?.mediaUrl) {
        return;
      }

      await downloadFile(
        selectUrl?.mediaUrl ?? "",
        selectUrl?.originalFileName ?? ""
      );
    } finally {
      setIsPreviewDownloadLoading(false);
    }
  };

  const renderDialogs = () => {
    // 이메일 공유된 로딩 상세 > 컨테이너 > 컨테이너 사진 슬라이드
    if (selectDialog === "VIEW_PHOTO") {
      return (
        <ViewPhotoCollectionDialog
          items={allImageList.map((item) => {
            return { original: item, thumbnail: item };
          })}
          open={selectDialog === "VIEW_PHOTO"}
          onClose={() => {
            setSelectDialog(null);
            setSelectLoading(null);
          }}
          selectLoading={
            selectLoading as ViewPhotoCollectionDialogProps["selectLoading"]
          }
          startIndex={startIndex}
          mediaDataList={photoTypeList.images}
          onSelectImageChange={(media) => setSelectImage(media)}
          photoDetailRightAccessory={
            <Button
              buttonGrade="tertiary"
              buttonColor="black"
              buttonSize={32}
              onClick={handlePreviewDownloadClick}
              isLoading={isPreviewDownloadLoading}
            >
              <Flex alignItems="center" gap={2}>
                <DownloadIcon />
                {t("common:download")}
              </Flex>
            </Button>
          }
          isLocationInfo={false}
        />
      );
    }
  };

  return (
    <AccordionItem
      value={id}
      onMouseEnter={() => {
        handlePrefetch();
      }}
      onKeyDown={() => {
        handlePrefetch();
      }}
    >
      <StyledAccordionTrigger>
        <AccordionTriggerTextContainer onClick={(e) => e.stopPropagation()}>
          <TextContainer>
            <Typo typoType="b7m">
              {t("common:dynamicContainerNo", { index: index + 1 })}
            </Typo>
            {containerNo ? (
              <TableBlueText
                typoType="b7m"
                color="systemBlue2"
                onClick={() => handleContainerNoClick()}
              >
                {containerNo}
              </TableBlueText>
            ) : (
              <Typo typoType="b7m" color="blue2">
                {containerNo ?? "-"}
              </Typo>
            )}
          </TextContainer>

          <TextContainer>
            <Typo typoType="b7m">{t("common:sealNo")}</Typo>
            <Typo typoType="b7m" color="blue2">
              {sealNo ?? "-"}
            </Typo>
          </TextContainer>

          <TextContainer>
            {renderLoadingContainerStatusBadge({
              type: containerStatus,
              size: "S",
              t,
            })}
          </TextContainer>
        </AccordionTriggerTextContainer>

        <ArrowIcon iconSrc={ArrowDownSvg} iconSize={24} />
      </StyledAccordionTrigger>
      <StyledAccordionContent>
        <AccordionSectionCardContent>
          {/* Container Task Information */}
          <StyledSectionCard
            cardTitle={
              <SectionCardTitle>
                <Typo typoType="h6">
                  {t("task:detail.cards.containerLoadingInformation")}
                </Typo>
                {renderLoadingContainerStatusBadge({
                  type: containerStatus,
                  size: "S",
                  t,
                })}
              </SectionCardTitle>
            }
          >
            <TaskInformationContainer ref={loadingImageRef}>
              {containerData.isFetching ? (
                <LoadingContainer
                  height={
                    loadingImageRef?.current?.getBoundingClientRect().height
                  }
                >
                  <PulseLoader color={colorSet.blue4} />
                </LoadingContainer>
              ) : (
                containerImageListMaping.imageResource.map((item, idx) => {
                  return (
                    <TaskThumbnail
                      title={item.title}
                      description={item.description}
                      icon={item.icon}
                      imageUrlList={item.imageList}
                      onClickImage={(imageUrl) => {
                        if (!containerData.currentData) {
                          return;
                        }

                        setSelectLoading(containerData.currentData);
                        setSelectDialog("VIEW_PHOTO");
                        setStartIndex(
                          allImageList.findIndex((item) => item === imageUrl)
                        );
                      }}
                      index={idx}
                      key={idx}
                    />
                  );
                })
              )}
            </TaskInformationContainer>
          </StyledSectionCard>

          {/* Weight Information */}
          <StyledSectionCard
            cardTitle={
              <SectionCardTitle>
                <Typo typoType="h6">
                  {t("task:detail.cards.weightInformation")}
                </Typo>
              </SectionCardTitle>
            }
          >
            {containerData.isFetching ? (
              <LoadingContainer
                height={
                  loadingWeightRef?.current?.getBoundingClientRect().height
                }
              >
                <PulseLoader color={colorSet.blue4} />
              </LoadingContainer>
            ) : (
              <WeightInformationContainer ref={loadingWeightRef}>
                <WeightInformationContent>
                  <Typo typoType="b9m" color="gray7">
                    {t("common:weightInformation")}
                  </Typo>

                  <WeightInformationRows>
                    <SectionCardRow
                      label={t("common:unitQuantity")}
                      value={
                        !containerData.currentData?.unitQuantity &&
                        !containerData.currentData?.unitQuantityUnit ? (
                          "-"
                        ) : (
                          <>
                            {containerData.currentData?.unitQuantity
                              ? containerData.currentData.unitQuantity.toLocaleString(
                                  "ko-KR"
                                )
                              : ""}
                            {containerData.currentData?.unitQuantityUnit ?? ""}
                          </>
                        )
                      }
                    />
                    <SectionCardRow
                      label={t("common:grossWeight")}
                      value={
                        containerData.currentData?.grossWeight &&
                        containerData.currentData?.weightUnit
                          ? `${Number(
                              containerData.currentData?.grossWeight
                            ).toLocaleString("ko-KR")} ${
                              containerData.currentData?.weightUnit
                            }`
                          : "-"
                      }
                    />
                    <SectionCardRow
                      label={t("common:netWeight")}
                      value={
                        containerData.currentData?.netWeight &&
                        containerData.currentData?.weightUnit
                          ? `${Number(
                              containerData.currentData?.netWeight
                            ).toLocaleString("ko-KR")} ${
                              containerData.currentData?.weightUnit
                            }`
                          : "-"
                      }
                    />
                  </WeightInformationRows>
                </WeightInformationContent>
              </WeightInformationContainer>
            )}
          </StyledSectionCard>
        </AccordionSectionCardContent>
      </StyledAccordionContent>

      {renderDialogs()}
    </AccordionItem>
  );
}

export default SharedTaskAccordionItem;

const ArrowIcon = styled(Icon)`
  transition: 0.3s;
`;

const StyledAccordionTrigger = styled(AccordionTrigger)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid ${colorSet.gray10};
  padding: 16px;
  margin: 0;
  cursor: pointer;

  &:hover {
    background: ${colorSet.blue10};
  }

  &[data-state="open"] {
    ${ArrowIcon} {
      transform: rotateZ(-180deg);
    }
  }

  &[data-state="closed"] {
    ${ArrowIcon} {
      transform: rotateZ(0deg);
    }
  }
`;

const AccordionTriggerTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AccordionSectionCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background: ${colorSet.gray11};
`;

const StyledSectionCard = styled(SectionCard)`
  background: ${colorSet.white};
`;

const SectionCardTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const WeightInformationContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 24px;
`;

const WeightInformationContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const WeightInformationRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TaskInformationContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(367px, 469px));
  justify-content: center;
  gap: 16px;
`;

const LoadingContainer = styled.div<{ height?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height}px;
`;

const DownloadIcon = styled(DownloadSvg)<{
  color?: ColorType;
  disabled?: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const StyledAccordionContent = styled(AccordionContent)`
  overflow: hidden;
  transform-origin: top;

  &[data-state="open"] {
    animation: ${slideDown} 0.6s ease-in-out;
  }

  &[data-state="closed"] {
    animation: ${slideUp} 0.6s ease-in-out;
  }
`;
