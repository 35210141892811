import { apiSlice } from "../baseQuery";
import { Row } from "../type";
import {
  CargoTrackUrlDto,
  InquiryCargoTrackUrlByBlNoDto,
  InquiryCargoTrackUrlByContainerIdAndShipmentShareKeyDto,
  InquiryCargoTrackUrlByContainerIdDto,
  InquiryCargoTrackUrlByShipmentIdDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [""],
});

const seavantageApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // containerId로 화물 추적 화면 연동을 위한 SeaVantage URL 반환 API
    containerIdToSeaVantageUrl: build.mutation<
      CargoTrackUrlDto,
      InquiryCargoTrackUrlByContainerIdDto
    >({
      query: (body) => ({
        method: "POST",
        url: `/exporters/this/sea-vantage/inquiry-cargo-track-url-by-container-id`,
        body,
      }),
      transformResponse: (res: Row<CargoTrackUrlDto>) => res.row,
    }),

    // shipmentId로 화물 추적 화면 연동을 위한 SeaVantage URL 반환 API
    shipmentIdToSeaVantageUrl: build.mutation<
      CargoTrackUrlDto,
      InquiryCargoTrackUrlByShipmentIdDto
    >({
      query: (body) => ({
        method: "POST",
        url: `/exporters/this/sea-vantage/inquiry-cargo-track-url-by-shipment-id`,
        body,
      }),
      transformResponse: (res: Row<CargoTrackUrlDto>) => res.row,
    }),

    // blNo로 화물 추적 화면 연동을 위한 SeaVantage URL 반환 API
    blnoToSeaVantageUrl: build.mutation<
      CargoTrackUrlDto,
      InquiryCargoTrackUrlByBlNoDto
    >({
      query: (body) => ({
        method: "POST",
        url: `/exporters/this/sea-vantage/inquiry-cargo-track-url-by-bl-no`,
        body,
      }),
      transformResponse: (res: Row<CargoTrackUrlDto>) => res.row,
    }),

    // 이메일로 전송받은 shipmentShareKey, shipmentId와 containerId로 화물 추적 화면 연동을 위한 SeaVantage URL 반환 API
    sharedShipmentKeyWithcontainerIdToSeaVantageUrl: build.mutation<
      CargoTrackUrlDto,
      InquiryCargoTrackUrlByContainerIdAndShipmentShareKeyDto
    >({
      query: (body) => ({
        method: "POST",
        url: `/sea-vantage/inquiry-cargo-track-url-by-container-id-and-shipment-share-key`,
        body,
      }),
      transformResponse: (res: Row<CargoTrackUrlDto>) => res.row,
    }),
    getSeavantageAuth: build.query<
      Row<{
        id: number;
        createdAt: string;
        updatedAt: string;
        seaVantageAuthToken: string;
      }>,
      void
    >({
      query: () => ({
        url: "/sea-vantage-auth-tokens/this",
        headers: {
          "x-access-token": `${process.env.REACT_APP_SEA_VANTAGE_ACCESS_TOKEN}`,
          "x-secret": `${process.env.REACT_APP_SEA_VANTAGE_SECRET}`,
        },
      }),
    }),
  }),
});

export const {
  useContainerIdToSeaVantageUrlMutation,
  useShipmentIdToSeaVantageUrlMutation,
  useBlnoToSeaVantageUrlMutation,
  useSharedShipmentKeyWithcontainerIdToSeaVantageUrlMutation,
  useGetSeavantageAuthQuery,
} = seavantageApi;
export { seavantageApi };
