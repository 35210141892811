import AddSvg from "@/src/assets/icons/icon-add-black.svg";
import ChevronLeftSvg from "@/src/assets/icons/icon-chevron-left-black.svg";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-info-gray6.svg";
import PreviewSvg from "@/src/assets/icons/icon-preview-black.svg";
import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import Icon from "@/src/components/atom/Icon";
import Loader from "@/src/components/atom/Loader";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import AddressFormItem from "@/src/components/molecule/AddressFormItem";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import ContactFormItem from "@/src/components/molecule/ContactFormItem";
import FormItem, { FormItemProps } from "@/src/components/molecule/FormItem";
import FormInputPropsWithInputType from "@/src/components/molecule/FormItem/type";
import SectionCard from "@/src/components/molecule/SectionCard";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useGetSessionQuery } from "@/src/store/apis/auth";
import {
  useCreateBuyerEmployeesMutation,
  useDeleteBuyerEmployeeMutation,
  useEditBuyerEmployeesMutation,
  useEditBuyerMutation,
  useGetBuyerQuery,
  useLazyGetBuyerEmployeesQuery,
} from "@/src/store/apis/client/buyer";
import { GenerateBuyerEmployeeDto } from "@/src/store/apis/client/buyer/interface";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import colorSet, { ColorType } from "@/src/styles/color";
import { mediaQuery } from "@/src/styles/mediaQuery";
import typo from "@/src/styles/typography";
import { isNull, isUndefined } from "@/src/utils/is";
import { editAndDeleteAction } from "@/src/utils/row-data-util";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { editBuyerContactPersonListColumn } from "./columns/column";
import BuyerEmployeeAddDialog from "./dialog/BuyerEmployeeAddDialog";
import BuyerEmployeeEditDialog from "./dialog/BuyerEmployeeEditDialog";

type BasicInformationType = {
  infoCountry: string;
  buyerName: string;
  buyerNameCode: string;
  businessNumber: string;
  businessRegistration: any;
  mainCategoryCodeItemNames: string[];
  tradedItems: any;
  use: string;
  apartment: string;
  city: string;
  state: string;
  postal: string;
  country: string;
  contactPrefix: string;
  contact: string;
  faxPrefix: string;
  fax: string;
  clientType: "BUYER" | "SELLER" | "BOTH";
};

enum DialogState {
  NULL,
  ADD,
  EDIT,
}

enum AlertDialogState {
  NULL,
  DELETE_EMPLOYEE,
}

const emptyArray: any[] = [];

const ExporterClientManagementEditPage = () => {
  const { t } = useTranslation();
  const pageParams = useParams<{ id: string }>();
  const alert = useAlert();
  const navigate = useNavigate();
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  const editButtonRef = useRef<HTMLButtonElement | null>(null);
  const agGridRef = useRef<AgGridReact>(null);
  const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });
  const [isReady, setIsReady] = useState<boolean>(false);
  const [columnDefs] = useState<ColDef[]>(editBuyerContactPersonListColumn);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [deleteWatingId, setDeleteWatingId] = useState<number>();
  const [editingBuyerId, setEditingBuyerId] = useState<null | number>(null);

  // API
  const { exporterUserType, userMainCategory } = useGetSessionQuery(undefined, {
    refetchOnMountOrArgChange: true,
    selectFromResult: ({ currentData, isError, isFetching }) => {
      const isUnstable = isError || isFetching || isUndefined(currentData);
      const isStable = !isUnstable;

      return {
        userMainCategory: isStable
          ? currentData.row.mainCategoryCodeItemNames
          : [],
        exporterUserType: currentData?.row.exporterUserType,
      };
    },
  });
  const {
    isBuyerFetching,
    buyerName,
    buyerNameCode,
    countryName,
    companyType,
    businessNumber,
    businessRegistration,
    mainCategoryCodeItemNames,
    use,
    apartment,
    city,
    state,
    postal,
    country,
    contactPrefix,
    contact,
    faxPrefix,
    fax,
    isLinkedWithCompany,
  } = useGetBuyerQuery(
    {
      id: Number(pageParams.id),
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isUndefined(currentData) || isError;
        return {
          isBuyerFetching: isFetching,
          buyerName: !isUnstable ? currentData.buyerName : "",
          buyerNameCode: !isUnstable ? currentData.buyerNameCode : "",
          countryName: !isUnstable
            ? currentData.buyerQueryResultDto.country.codeItemNameKo
            : "",
          businessNumber:
            !isUnstable && !isNull(currentData.businessNumber)
              ? currentData.businessNumber
              : "",
          businessRegistration:
            !isUnstable && !isNull(currentData.businessRegistrationSimpleMedia)
              ? currentData.businessRegistrationSimpleMedia
              : null,
          use: !isUnstable ? currentData.isActivated : false,
          apartment:
            !isUnstable &&
            !isNull(currentData.buyerQueryResultDto.buyerStreetAddress)
              ? currentData.buyerQueryResultDto.buyerStreetAddress
              : "",
          city:
            !isUnstable &&
            !isNull(currentData.buyerQueryResultDto.buyerLocality)
              ? currentData.buyerQueryResultDto.buyerLocality
              : "",
          state:
            !isUnstable && !isNull(currentData.buyerQueryResultDto.buyerRegion)
              ? currentData.buyerQueryResultDto.buyerRegion
              : "",
          postal:
            !isUnstable &&
            !isNull(currentData.buyerQueryResultDto.buyerPostalCode)
              ? currentData.buyerQueryResultDto.buyerPostalCode
              : "",
          country: !isUnstable
            ? currentData.buyerQueryResultDto.buyerCountryName
            : "",
          companyType: !isUnstable ? currentData.companyType : undefined,
          contactPrefix: !isUnstable
            ? currentData.buyerQueryResultDto.telPrefix
            : "",
          contact: !isUnstable ? currentData.buyerQueryResultDto.tel : "",
          faxPrefix: !isUnstable
            ? currentData.buyerQueryResultDto.faxPrefix
            : "",
          fax: !isUnstable ? currentData.buyerQueryResultDto.fax : "",
          mainCategoryCodeItemNames: !isUnstable
            ? currentData.mainCategoryCodeItemNames
            : emptyArray,
          isLinkedWithCompany: !isUnstable
            ? currentData.buyerQueryResultDto.linkedStatus === "COMPLETED"
            : false,
        };
      },
    }
  );
  const [getEmployees, { isEmployeeFetching, employeeCount, employeeList }] =
    useLazyGetBuyerEmployeesQuery({
      selectFromResult: ({ isFetching, isError, currentData }) => {
        const isUnstable = isUndefined(currentData) || isError;
        return {
          isEmployeeFetching: isFetching,
          employeeList: !isUnstable ? currentData.rows : [],
          employeeCount: !isUnstable ? currentData.count : 0,
        };
      },
    });

  const { isCommonCodeFetching, countryList } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "COUNTRY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ isFetching, isError, currentData }) => {
          const isUnstable = isError || isFetching || isUndefined(currentData);
          return {
            isCommonCodeFetching: isFetching,
            countryList: isUnstable ? [] : currentData,
          };
        },
      }
    );

  const { isMainCategoryFetching, mainCategory } =
    useGetCommonCodeViaCodeNameQuery(
      {
        codeName: "MAIN_CATEGORY",
      },
      {
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isErrorAndUndefined = isError || currentData === undefined;

          return {
            isMainCategoryFetching: isFetching,
            mainCategory: !isErrorAndUndefined ? (currentData ?? []) : [],
          };
        },
      }
    );

  const [createEmployee] = useCreateBuyerEmployeesMutation();
  const [editEmployee] = useEditBuyerEmployeesMutation();
  const [editBuyer] = useEditBuyerMutation();
  const [deleteEmployee] = useDeleteBuyerEmployeeMutation();

  const {
    control,
    register,
    watch,
    setValue,
    handleSubmit: handleBasicInfoSubmit,
    getValues: getBasicInformationValues,
    setFocus,
  } = useForm<BasicInformationType>({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      buyerName: buyerName,
      buyerNameCode: buyerNameCode,
      infoCountry: countryName,
      businessNumber: businessNumber,
      mainCategoryCodeItemNames: mainCategoryCodeItemNames,
      businessRegistration: businessRegistration ? [businessRegistration] : [],
      tradedItems: "",
      use: use ? "Y" : "N",
      apartment: apartment,
      city: city,
      state: state,
      postal: postal,
      country: country,
      contactPrefix: contactPrefix,
      contact: contact,
      faxPrefix: faxPrefix,
      fax: fax,
      clientType: companyType,
    },
  });

  useAgGridHeaderRefresh({
    gridRef: agGridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "clientManagement:exporter.common.name",
      },
      {
        columnKey: "email",
        langKey: "clientManagement:exporter.common.email",
      },
      {
        columnKey: "personalContact",
        langKey: "clientManagement:exporter.common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "clientManagement:exporter.common.officeContact",
      },
      {
        columnKey: "buyerEmployeeRemark",
        langKey: "clientManagement:exporter.common.remark",
      },
      {
        columnKey: "isActivated",
        langKey: "clientManagement:exporter.common.use",
      },
      {
        columnKey: "edit",
        langKey: "clientManagement:exporter.common.edit",
      },
    ],
  });

  const fetchEmployees = async ({
    page,
    pageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    try {
      await getEmployees({
        id: Number(pageParams.id),
        page,
        pageSize,
      }).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const countryCodeList = countryList.reduce<
    { label: string; value: string }[]
  >((acc, val) => {
    return [
      ...acc,
      {
        label: `${val.codeItemName}_${val.codeItemNameEn}`,
        value: val.codeItemNameKo,
      },
    ];
  }, []);

  const mainCategoryOptionList = useMemo(
    () =>
      mainCategory.map((item) => {
        return {
          label: item.codeItemNameEn,
          value: item.codeItemName,
        };
      }),
    [mainCategory]
  );

  const mainCategoryStringList = useMemo(
    () => mainCategory.map((item) => item.codeItemName),
    [mainCategory]
  );

  const basicInformationFormItemList: (FormItemProps<
    BasicInformationType,
    keyof BasicInformationType
  > &
    FormInputPropsWithInputType & { hide?: boolean })[] = [
    {
      label: t("clientManagement:exporter.common.clientName"),
      type: "text",
      name: "buyerName",
      control: control,
      rules: { required: true, maxLength: 80 },
      disabled: isLinkedWithCompany,
      errorsMessage: {
        required: t("error:required"),
        maxLength: t("error:maxLength80"),
      },
      inputProps: {
        placeholder: t("clientManagement:exporter.placeholder.client"),
      },
    },
    {
      label: t("clientManagement:exporter.common.clientCode"),
      type: "text",
      name: "buyerNameCode",
      control: control,
      rules: { required: true, maxLength: 80 },
      errorsMessage: {
        required: t("error:required"),
        maxLength: t("error:maxLength80"),
      },
      inputProps: {
        placeholder: t("clientManagement:exporter.placeholder.clientCode"),
      },
    },
    {
      label: t("signupCorp:content.country"),
      type: "singleSelect",
      name: "infoCountry",
      control: control,
      options: countryCodeList,
      rules: { required: true },
      errorsMessage: {
        required: t("error:required"),
      },
      inputProps: {
        suffixIcon: isCommonCodeFetching ? <Loader /> : undefined,
        disabled: isCommonCodeFetching || isLinkedWithCompany,
        placeholder: t("clientManagement:exporter.placeholder.country"),
        onChange: (value) => {
          const prefix = countryList.find(
            ({ codeItemNameKo }) => codeItemNameKo === value
          )?.value1;
          setValue("contactPrefix", prefix || "");
          setValue("faxPrefix", prefix || "");
        },
      },
    },
    {
      label: t("clientManagement:exporter.buyerDetail.categoryInCharge"),
      type: "multipleSelect",
      name: "mainCategoryCodeItemNames",
      rules: { required: true },
      errorsMessage: {
        required: t("error:required"),
      },
      control: control,
      options: mainCategoryOptionList,
      inputProps: {
        disableValues:
          // 일반매니저, 중간관리자가 아닌 경우 필터링 없이 그대로 유지
          exporterUserType === "MANAGER" ||
          exporterUserType === "MIDDLE_MANAGER"
            ? [
                ...mainCategoryStringList.filter(
                  (category) => !userMainCategory.includes(category)
                ),
                ...mainCategoryCodeItemNames,
              ]
            : mainCategoryCodeItemNames,
        placeholder: t(
          "clientManagement:exporter.placeholder.categoryInCharge"
        ),
        suffixIcon: isMainCategoryFetching ? <Loader /> : undefined,
        disabled: isMainCategoryFetching || isLinkedWithCompany,
        onRemoveItem: (item) => {
          setValue(
            "mainCategoryCodeItemNames",
            getBasicInformationValues("mainCategoryCodeItemNames").filter(
              (mainCategoryItem) => mainCategoryItem !== item
            )
          );
        },
      },
    },
    {
      label: t("clientManagement:exporter.common.businessNumber"),
      type: "text",
      name: "businessNumber",
      control: control,
      disabled: isLinkedWithCompany,
      inputProps: {
        placeholder: t("clientManagement:exporter.placeholder.businessNumber"),
      },
    },
    {
      label: t("clientManagement:exporter.common.businessRegistration"),
      type: "file",
      name: "businessRegistration",
      control: control,
      hide: isLinkedWithCompany,
      inputProps: {
        disabled: isLinkedWithCompany,
        defaultFileList: watch("businessRegistration").length
          ? [
              new File(
                [""],
                watch("businessRegistration")?.[0]?.originalFileName ?? ""
              ),
            ]
          : undefined,
        onRemove: () => setValue("businessRegistration", []),
      },
    },
  ];

  const handleSubmit = async (
    values: Omit<GenerateBuyerEmployeeDto, "isActivated"> & {
      isActivated: string;
    }
  ) => {
    try {
      setDialogState(DialogState.NULL);
      await createEmployee({
        id: Number(pageParams.id),
        name: values.name,
        email: values.email,
        isActivated: values.isActivated === "Y",
        personalContactPrefix: values.personalContactPrefix || undefined,
        personalContact: values.personalContact || undefined,
        officeContactPrefix: values.officeContactPrefix || undefined,
        officeContact: values.officeContact || undefined,
        buyerEmployeeRemark: values.buyerEmployeeRemark || undefined,
      }).unwrap();
      await fetchEmployees({
        page: pagination.page,
        pageSize: pagination.pageSize,
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
    }
  };

  const handelEditContactPerson = async (values: GenerateBuyerEmployeeDto) => {
    try {
      await editEmployee({
        name: values.name,
        email: values.email,
        id: Number(editingBuyerId),
        isActivated: values.isActivated,
        personalContact: values.personalContact || null,
        personalContactPrefix: values.personalContactPrefix || null,
        officeContact: values.officeContact || null,
        officeContactPrefix: values.officeContactPrefix || null,
        buyerEmployeeRemark: values.buyerEmployeeRemark || null,
      }).unwrap();

      await fetchEmployees({
        page: pagination.page,
        pageSize: pagination.pageSize,
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    } finally {
      setDialogState(DialogState.NULL);
    }
  };

  const handleDeleteEmployee = ({ data }: ICellRendererParams) => {
    setAlertDialogState(AlertDialogState.DELETE_EMPLOYEE);
    setDeleteWatingId(data.id);
  };

  const handleEditBuyer = async () => {
    try {
      const {
        infoCountry,
        buyerName,
        buyerNameCode,
        businessRegistration,
        use,
        apartment,
        city,
        state,
        postal,
        country,
        contactPrefix,
        contact,
        faxPrefix,
        fax,
        businessNumber,
        mainCategoryCodeItemNames: editedMainCategoryCodeItemNames,
      } = getBasicInformationValues();

      const validBodyData = Object.entries({
        telPrefix: contactPrefix || undefined,
        faxPrefix: faxPrefix || undefined,
        tel: contact || undefined,
        fax: fax || undefined,
      })
        .filter(([_key, value]) => value !== undefined) // undefined인 값 필터링
        .reduce<any>((acc, [key, value]) => {
          acc[key] = value; // 유효한 값만 추가
          return acc;
        }, {});

      await editBuyer({
        id: Number(pageParams.id) ?? "",
        buyerName,
        buyerNameCode: buyerNameCode || null,
        companyType: "BOTH",
        countryCodeItemName:
          countryList.find(
            ({ codeItemNameKo }) => infoCountry === codeItemNameKo
          )?.codeItemName ?? "",
        buyerCountryName: country,
        buyerPostalCode: postal || null,
        buyerRegion: state || null,
        buyerLocality: city || null,
        buyerStreetAddress: apartment || null,
        isActivated: use === "Y",
        businessNumber: businessNumber || null,
        businessRegistrationMediaId: businessRegistration[0]?.id ?? null,
        mainCategoryCodeItemNames: editedMainCategoryCodeItemNames,
        ...validBodyData,
      }).unwrap();

      navigate(
        `${EXPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_DETAIL}/${pageParams.id}`
      );
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleDetailClick = ({ data }: ICellRendererParams) => {
    setEditingBuyerId(data.id);
    setDialogState(DialogState.EDIT);
  };

  useEffect(() => {
    fetchEmployees({ page: 1, pageSize: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFocus("buyerName");
  }, [setFocus]);

  useEffect(() => {
    if (isBuyerFetching) {
      handleContentLoadingOn();
    } else {
      handleContentLoadingOff();
    }
  }, [handleContentLoadingOff, handleContentLoadingOn, isBuyerFetching]);

  useEffect(() => {
    if (isEmployeeFetching && agGridRef.current) {
      agGridRef.current.api.showLoadingOverlay();
    }
  }, [isEmployeeFetching]);

  const renderDialog = () => {
    if (dialogState === DialogState.NULL) return null;
    const closeDialog = () => setDialogState(DialogState.NULL);

    if (dialogState === DialogState.ADD) {
      return (
        <BuyerEmployeeAddDialog
          defaultCountryCode={
            countryList.find(
              ({ codeItemNameKo }) => codeItemNameKo === watch("infoCountry")
            )?.value1
          }
          onSave={handleSubmit}
          onClose={closeDialog}
        />
      );
    }
    if (dialogState === DialogState.EDIT && editingBuyerId) {
      const editing = employeeList.find(({ id }) => id === editingBuyerId);

      if (!editing) return;

      return (
        <BuyerEmployeeEditDialog
          name={editing.name}
          email={editing.email}
          isActivated={editing.isActivated ? "Y" : "N"}
          personalContact={editing.personalContact}
          officeContact={editing.officeContact}
          personalContactPrefix={editing.personalContactPrefix}
          officeContactPrefix={editing.officeContactPrefix}
          buyerEmployeeRemark={editing.buyerEmployeeRemark}
          onSave={handelEditContactPerson}
          onClose={closeDialog}
        />
      );
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.NULL) {
      return null;
    }
    if (alertDialogState === AlertDialogState.DELETE_EMPLOYEE) {
      return (
        <AlertDialog
          title={t(
            "clientManagement:exporter.buyerEdit.alertDialog.deleteContactPerson.title"
          )}
          open
          okText={t("common:ok")}
          cancelText={t("common:cancel")}
          onOpenChange={() => setAlertDialogState(AlertDialogState.NULL)}
          onCancel={() => setAlertDialogState(AlertDialogState.NULL)}
          onOk={async () => {
            try {
              if (employeeCount === 1) {
                setAlertDialogState(AlertDialogState.NULL);
                // eslint-disable-next-line no-throw-literal
                throw {
                  data: {
                    message: t(
                      "clientManagement:exporter.buyerListAddPage.alertDialog.registerOneContactPerson.description"
                    ),
                  },
                };
              }
              if (deleteWatingId) {
                setAlertDialogState(AlertDialogState.NULL);
                await deleteEmployee({ id: deleteWatingId }).unwrap();
              }
            } catch (e: any) {
              const message = Array.isArray(e.data.message)
                ? e.data.message[0]
                : e.data.message;
              alert.showAlert({ message, type: "error" });
            } finally {
              setDeleteWatingId(undefined);
            }
          }}
        >
          {t(
            "clientManagement:exporter.buyerEdit.alertDialog.deleteContactPerson.description"
          )}
        </AlertDialog>
      );
    }
  };

  return (
    <ExporterMainLayout
      breadcrumb={[
        t("sideNav:clientManagement"),
        t("sideNav:clientManagementEdit"),
      ]}
      pageTitle={buyerName ?? t("sideNav:clientManagementEdit")}
    >
      <SectionContainer>
        <SectionCard
          cardTitle={t("clientManagement:exporter.common.basicInformation")}
        >
          <CallOut>
            <InfoIcon color="gray6" />
            <Typo typoType="b9r" color="gray6">
              {t("clientManagement:exporter.common.callout")}
            </Typo>
          </CallOut>
          <StyledForm onSubmit={handleBasicInfoSubmit(handleEditBuyer)}>
            <HalfDiv>
              {basicInformationFormItemList.map(
                ({
                  label,
                  type,
                  name,
                  control,
                  options,
                  rules,
                  inputProps,
                  disabled,
                  errorsMessage,
                  hide,
                }) => {
                  if (hide) return null;
                  return (
                    <StyledFormItem
                      label={label}
                      type={type as any}
                      name={name as keyof BasicInformationType}
                      control={control as any}
                      options={options ?? []}
                      inputProps={inputProps as any}
                      rules={rules}
                      disabled={disabled}
                      errorsMessage={errorsMessage}
                    />
                  );
                }
              )}

              {isLinkedWithCompany &&
                watch("businessRegistration").length === 1 && (
                  <Flex>
                    <p className="label">
                      {t(
                        "clientManagement:exporter.common.businessRegistration"
                      )}
                    </p>
                    <p>
                      <RowValueContainer>
                        <Typo typoType="b7r" color="gray5">
                          {watch("businessRegistration")[0].originalFileName}{" "}
                        </Typo>
                        <StyledIconButton
                          buttonGrade="secondary"
                          buttonSize={24}
                          buttonColor="blue"
                          onClick={() => {
                            if (watch("businessRegistration")) {
                              window.open(
                                watch("businessRegistration")[0].mediaUrl
                              );
                            }
                          }}
                        >
                          <Icon iconSrc={PreviewSvg} iconSize={16} />
                        </StyledIconButton>
                      </RowValueContainer>
                    </p>
                  </Flex>
                )}
            </HalfDiv>
            <HalfDiv>
              <StyledAddressFormItem
                label={t("signupCorp:content.address")}
                register={register as any}
                isRequired
                setValue={setValue as any}
                disabled={isLinkedWithCompany}
              />
              <StyledContactFormItem
                label={t("signupCorp:content.contact")}
                prefixName="contactPrefix"
                register={register as any}
                setValue={setValue as any}
                restContactName="contact"
                prefixValue={watch("contactPrefix") || undefined}
                isRequired={false}
                disabled={isLinkedWithCompany}
              />
              <StyledContactFormItem
                label={t("clientManagement:exporter.common.fax")}
                prefixName="faxPrefix"
                register={register as any}
                setValue={setValue as any}
                restContactName="fax"
                prefixValue={watch("faxPrefix") || undefined}
                isRequired={false}
                disabled={isLinkedWithCompany}
              />
              <FormItem
                type="radioGroup"
                label={t("clientManagement:exporter.common.use")}
                control={control}
                name="use"
                rules={{ required: true }}
                options={[
                  { label: "Y", value: "Y" },
                  { label: "N", value: "N" },
                ]}
                errorsMessage={{
                  required: t("error:required"),
                  pattern: t("error:login.emailTypeMissMatching"),
                }}
              />
            </HalfDiv>
            <Hidden ref={editButtonRef} />
          </StyledForm>
        </SectionCard>
        <SectionCard
          cardTitle={t("clientManagement:exporter.common.contactPersonList")}
          rightAccessory={
            <StyledButton
              buttonGrade="tertiary"
              buttonSize={32}
              buttonColor="black"
              onClick={() => setDialogState(DialogState.ADD)}
            >
              <Icon iconSrc={AddSvg} iconSize={16} />
              <Typo typoType="btn3m" color="gray2">
                {t(
                  "clientManagement:exporter.buyerDetail.buttons.addContactPerson"
                )}
              </Typo>
            </StyledButton>
          }
        >
          <Table
            ref={agGridRef}
            rowData={employeeList}
            columnDefs={columnDefs}
            components={{
              ...editAndDeleteAction({
                onEdit: handleDetailClick,
                onDelete: handleDeleteEmployee,
              }),
            }}
            isPaginationDataMaping
            totalPage={employeeCount}
            page={pagination.page}
            pageSize={pagination.pageSize}
            handlePaginationClick={(page, pageSize) => {
              fetchEmployees({ page, pageSize });
              setPagination({ page, pageSize });
            }}
            onGridReady={() => setIsReady(true)}
          />
        </SectionCard>
        <BottomFixedContainer>
          <FooterButtonContainer>
            <FooterButton
              buttonColor="black"
              buttonGrade="tertiary"
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
              onClick={() => navigate(-1)}
            >
              <Icon iconSrc={ChevronLeftSvg} iconSize={16} />{" "}
              {t("clientManagement:exporter.common.backToPrevious")}
            </FooterButton>
            <FooterButton onClick={() => editButtonRef.current?.click()}>
              {t("clientManagement:exporter.common.save")}
            </FooterButton>
          </FooterButtonContainer>
        </BottomFixedContainer>
      </SectionContainer>

      {renderDialog()}
      {renderAlertDialog()}
    </ExporterMainLayout>
  );
};

export default ExporterClientManagementEditPage;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 72px;
`;

const StyledForm = styled.form`
  display: flex;
  gap: 24px;
`;

const HalfDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledFormItem = styled(FormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledAddressFormItem = styled(AddressFormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
    > div {
      width: 100% !important;
    }
  }
`;

const StyledContactFormItem = styled(ContactFormItem)`
  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    gap: 8px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Hidden = styled.button`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const FooterButton = styled(Button)`
  min-width: 158px;
  text-align: center;
  ${typo.btn3m}
`;

const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CallOut = styled.div`
  background: ${colorSet.gray11};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 24px;
`;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;

  .label {
    width: 164px;
    flex-shrink: 0;
  }

  .value {
    flex: 1;
  }

  ${mediaQuery.FORM_INPUT_TO_FULL_INPUT} {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }
`;

const RowValueContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledIconButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 4px;
`;
