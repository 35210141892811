import { apiSlice } from "../../baseQuery";
import { IsBookmarkedInfoDto } from "../../contracts/contractDetail/interface";
import { Row, Rows } from "../../type";
import {
  ExporterUserShipmentBookmarkInfoDto,
  ExternalDocumentType,
  OutContainerShipmentChangeHistoryListByShipmentIdDto,
  SendShipmentInfoEmailV2Dto,
  ShipmentBookmarkListViewDto,
  ShipmentContainerListForReselectionViewDto,
  ShipmentContainerMediaByPhotoTypeDto,
  ShipmentContainerMediaContainerDetailDto,
  ShipmentContainerMediaContainerListDto,
  ShipmentDetailContainerListViewDto,
  ShipmentDetailContainersIntegrationInfo,
  ShipmentDetailContractBookingListViewDto,
  ShipmentDetailDocumentAndMemoViewDto,
  ShipmentDetailTaskListViewDto,
  ShipmentDetailViewDto,
} from "./interface";

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [
    "detail",
    "bookmark",
    "document",
    "container",
    "importer-document",
  ],
});

const shipmentDetailApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    // 선적 단일 항목 조회
    getShipmentDetail: build.query<ShipmentDetailViewDto, { id: number }>({
      query: ({ id }) => ({
        url: `/v2/exporters/this/shipments/${id}`,
      }),
      transformResponse: (res: Row<ShipmentDetailViewDto>) => res.row,

      providesTags: ["detail"],
    }),

    // 선적 수출입웹 유저의 단일 선적에 대한 북마크 정보를 조회하는 API 입니다.
    getShipmentDetailBookmark: build.query<
      ExporterUserShipmentBookmarkInfoDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporter-users/this/shipments/${id}/bookmark-info`,
      }),
      transformResponse: (res: Row<ExporterUserShipmentBookmarkInfoDto>) =>
        res.row,
      providesTags: ["bookmark"],
    }),

    // 선적 단일 삭제 API
    deleteShipmentDetail: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        method: "DELETE",
        url: `/exporters/this/shipments/${id}`,
      }),
    }),

    // 선적을 북마크한 수출입웹 유저 목록 조회 API
    getShipmentDetailBookmarkUser: build.query<
      Rows<ShipmentBookmarkListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/shipments/${id}/shipment-bookmarks`,
      }),
      providesTags: ["bookmark"],
    }),

    // 수출입웹 유저의 단일 선적에 대한 북마크 정보를 관장하는 API 입니다.
    updateShipmentDetailBookmark: build.mutation<
      Rows<ExporterUserShipmentBookmarkInfoDto>,
      IsBookmarkedInfoDto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        method: "POST",
        url: `/exporter-users/this/shipments/${id}/bookmark-info`,
        body: body,
      }),
      invalidatesTags: ["bookmark"],
    }),

    // 선적에 귀속된 계약/부킹 목록 조회 API
    getShipmentDetailContractBooking: build.query<
      Rows<ShipmentDetailContractBookingListViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/shipments/${id}/contract-booking-infos`,
      }),
    }),

    // 선적에 귀속된 작업 목록 조회 API
    getShipmentDetailTasks: build.query<
      Rows<ShipmentDetailTaskListViewDto>,
      { id: number; page?: number; pageSize?: number }
    >({
      query: ({ id, page, pageSize }) => ({
        url: `/exporters/this/shipments/${id}/tasks`,
        params: { page, pageSize },
      }),
    }),

    // 선적에 귀속된 컨테이너 목록 조회 API
    getShipmentDetailContainers: build.query<
      Rows<ShipmentDetailContainerListViewDto>,
      { id: number; page?: number; pageSize?: number }
    >({
      query: ({ id, page, pageSize }) => ({
        url: `/exporters/this/shipments/${id}/containers`,
        params: { page, pageSize },
      }),
      providesTags: ["container"],
    }),

    // 선적에 귀속된 컨테이너 목록의 통합 정보 조회 API
    getShipmentDetailContainersIntegration: build.query<
      ShipmentDetailContainersIntegrationInfo,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/shipments/${id}/containers-integration-info`,
      }),
      transformResponse: (res: Row<ShipmentDetailContainersIntegrationInfo>) =>
        res.row,
      providesTags: ["container"],
    }),

    // 부킹 Combine/Split으로 인해 해당 선적에서 OUT된 컨테이너 목록 조회 API
    getCombinedAndSplitHistoryOfContainers: build.query<
      Rows<OutContainerShipmentChangeHistoryListByShipmentIdDto>,
      { shipmentId: number }
    >({
      query: ({ shipmentId }) => ({
        url: `/exporters/this/container-shipment-change-history/out-history-list-inquiry-by-shipment-id`,
        params: {
          shipmentId,
        },
      }),
      providesTags: ["container"],
    }),

    // 선적에 귀속된 Document & Memo 정보를 조회하는 API 입니다.
    getShipmentDetailDocumentAndMemo: build.query<
      Row<ShipmentDetailDocumentAndMemoViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/shipments/${id}/document-and-memo-info`,
      }),
      providesTags: ["document"],
    }),

    // 선적에 귀속된 Internal Document를 생성하는 API 입니다. 해당 요청은 선적을 작성한 유저, corporate manager, middle manager만 가능합니다.
    createInternalDocument: build.mutation<
      void,
      { id: number; mediaId: number }
    >({
      query: ({ id, mediaId }) => ({
        url: `/exporters/this/shipments/${id}/shipment-internal-document-medias`,
        method: "POST",
        body: {
          mediaId,
        },
      }),
      invalidatesTags: ["document"],
    }),

    // 선적에 귀속된 External Document를 생성하는 API 입니다. 해당 요청은 선적을 작성한 유저, corporate manager, middle manager만 가능합니다.
    createExternalDocument: build.mutation<
      void,
      { id: number; mediaIds: number[]; type?: ExternalDocumentType }
    >({
      query: ({ id, mediaIds, type }) => ({
        url: `/exporters/this/shipments/${id}/shipment-external-document-medias`,
        method: "POST",
        body: {
          mediaIds,
          type,
        },
      }),
      invalidatesTags: ["document"],
    }),

    // 선적에 귀속된 Internal Document를 삭제하는 API 입니다. 해당 요청은 선적을 작성한 유저, corporate manager, middle manager만 가능합니다.
    deleteShipmentInternalDocument: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/shipment-internal-document-medias/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["document"],
    }),

    // 선적에 귀속된 Internal Document를 삭제하는 API 입니다. 해당 요청은 선적을 작성한 유저, corporate manager, middle manager만 가능합니다.
    deleteShipmentExternalDocument: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/exporters/this/shipment-external-document-medias/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["document"],
    }),

    //선적 메모를 생성하는 API 입니다. 선적 메모의 생성은 선적을 작성한 유저, corporate manager, middle manager만 가능합니다.
    createShipmentMemo: build.mutation<void, { id: number; memo: string }>({
      query: ({ id, memo }) => ({
        url: `exporters/this/shipments/${id}/memos`,
        method: "POST",
        body: { memo },
      }),
      invalidatesTags: ["document"],
    }),

    // 선적 B/L을 등록하는 API 입니다. 해당 요청은 선적을 작성한 유저, corporate manager, middle manager만 가능합니다.
    createBl: build.mutation<
      void,
      {
        id: number;
        blStatus?: "BL_DRAFT" | "BL_FINAL";
        blNo?: string | null;
        shippingLineUrl?: string | null;
        blMediaId?: number | null;
        blMedias?: {
          documentType: "BL_DRAFT" | "BL_FINAL";
          mediaId: number;
        }[];
      }
    >({
      query: ({
        id,
        blStatus,
        blNo,
        shippingLineUrl,
        blMediaId,
        blMedias,
      }) => ({
        url: `/exporters/this/shipments/${id}/bl-registration`,
        method: "POST",
        body: {
          blStatus,
          blNo,
          shippingLineUrl,
          blMediaId,
          blMedias,
        },
      }),
      invalidatesTags: ["document", "detail"],
    }),

    // 선적 도메인의 컨테이너 이미지와 관련하여, 컨테이너 목록을 조회하는 API 입니다.
    getShipmentContainersList: build.query<
      ShipmentContainerMediaContainerListDto[],
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/shipment-container-media/shipments/${id}/containers`,
      }),
      transformResponse: (res: Rows<ShipmentContainerMediaContainerListDto>) =>
        res.rows,
    }),

    // 선적 도메인의 컨테이너 이미지와 관련하여, 컨테이너를 단일 조회하는 API 입니다.
    getSingleShipmentContainerMedia: build.query<
      ShipmentContainerMediaContainerDetailDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/shipment-container-media/containers/${id}`,
      }),
      transformResponse: (res: Row<ShipmentContainerMediaContainerDetailDto>) =>
        res.row,
    }),

    // 선적 도메인의 컨테이너 이미지와 관련하여, Photo Type 별 컨테이너 이미지를 조회하는 API 입니다.
    getShipmentContainerMediaByPhotoType: build.query<
      ShipmentContainerMediaByPhotoTypeDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/shipment-container-media-by-photo-type/shipments/${id}`,
      }),
      transformResponse: (res: Row<ShipmentContainerMediaByPhotoTypeDto>) =>
        res.row,
    }),

    // 선적 도메인의 컨테이너 이미지와 관련하여, 작업 ID(PK) 목록으로 컨테이너 목록을 조회하는 API 입니다. 이미 선적과 연결된 컨테이너의 정보는 반환하지 않으며, pagination을 제공하지 않습니다.
    getShipmentContainersListViaTaskId: build.mutation<
      ShipmentContainerMediaContainerListDto[],
      { taskIdList: number[] }
    >({
      query: ({ taskIdList }) => ({
        url: `/exporters/this/shipment-container-media/container-list-inquiry`,
        method: "POST",
        body: { taskIdList },
      }),
      transformResponse: (res: Rows<ShipmentContainerMediaContainerListDto>) =>
        res.rows,
    }),

    // 선적 도메인의 컨테이너 이미지와 관련하여, 작업 ID(PK) 목록으로 Photo Type 별 컨테이너 이미지를 조회하는 API 입니다. 이미 선적과 연결된 컨테이너의 정보는 반환하지 않습니다.
    getShipmentContainerMediaByPhotoTypeViaTaskId: build.mutation<
      ShipmentContainerMediaByPhotoTypeDto,
      { taskIdList: number[] }
    >({
      query: ({ taskIdList }) => ({
        url: `/exporters/this/shipment-container-media-by-photo-type-inquiry`,
        method: "POST",
        body: { taskIdList },
      }),
      transformResponse: (res: Row<ShipmentContainerMediaByPhotoTypeDto>) =>
        res.row,
    }),

    // 선적에 귀속된 컨테이너 목록 수정 시 컨테이너 목록을 조회하는 API 입니다.
    getShipmentContainerListForReselection: build.query<
      Rows<ShipmentContainerListForReselectionViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/exporters/this/shipments/${id}/container-list-inquiry-for-reselection`,
      }),
      providesTags: ["container"],
    }),

    editShipmentContainer: build.mutation<
      void,
      { id: number; containerIdList: number[] }
    >({
      query: ({ id, containerIdList }) => ({
        url: `/exporters/this/shipments/${id}`,
        method: "PUT",
        body: {
          containerIdList,
        },
      }),
      invalidatesTags: ["container"],
    }),

    // (v2)선적 정보 이메일 발송 API
    shipmentDetailSendEmail: build.mutation<
      void,
      SendShipmentInfoEmailV2Dto & { id: number }
    >({
      query: ({ id, ...body }) => ({
        url: `/v2/exporters/this/shipments/${id}/send-shipment-info-email`,
        method: "POST",
        body,
      }),
    }),

    getImporterShipmentDetail: build.query<
      ShipmentDetailViewDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/shipments/${id}`,
      }),
      transformResponse: (res: Row<ShipmentDetailViewDto>) => res.row,
    }),

    getImporterShipmentDetailContainers: build.query<
      Rows<ShipmentDetailContainerListViewDto>,
      { id: number; page?: number; pageSize?: number }
    >({
      query: ({ id, page, pageSize }) => ({
        url: `/importers/this/shipments/${id}/containers`,
        params: { page, pageSize },
      }),
    }),

    // 선적에 귀속된 컨테이너 목록의 통합 정보 조회 API
    getImporterShipmentDetailContainersIntegration: build.query<
      ShipmentDetailContainersIntegrationInfo,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/shipments/${id}/containers-integration-info`,
      }),
      transformResponse: (res: Row<ShipmentDetailContainersIntegrationInfo>) =>
        res.row,
    }),

    getImporterShipmentContainersList: build.query<
      ShipmentContainerMediaContainerListDto[],
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/shipment-container-media/shipments/${id}/containers`,
      }),
      transformResponse: (res: Rows<ShipmentContainerMediaContainerListDto>) =>
        res.rows,
    }),

    getImporterSingleShipmentContainerMedia: build.query<
      ShipmentContainerMediaContainerDetailDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/shipment-container-media/containers/${id}`,
      }),
      transformResponse: (res: Row<ShipmentContainerMediaContainerDetailDto>) =>
        res.row,
    }),

    getImporterShipmentContainerMediaByPhotoType: build.query<
      ShipmentContainerMediaByPhotoTypeDto,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/shipment-container-media-by-photo-type/shipments/${id}`,
      }),
      transformResponse: (res: Row<ShipmentContainerMediaByPhotoTypeDto>) =>
        res.row,
    }),
    // 선적에 귀속된 Document & Memo 정보를 조회하는 API 입니다.
    getImporterShipmentDetailDocumentAndMemo: build.query<
      Row<ShipmentDetailDocumentAndMemoViewDto>,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/shipments/${id}/document-and-memo-info`,
      }),
      providesTags: ["importer-document"],
    }),
    createImportInternalDocument: build.mutation<
      void,
      { id: number; mediaId: number }
    >({
      invalidatesTags: ["importer-document"],
      query: ({ id, mediaId }) => ({
        url: `/importers/this/shipments/${id}/shipment-internal-document-medias`,
        method: "POST",
        body: {
          mediaId,
        },
      }),
    }),
    deleteImporterShipmentInternalDocument: build.mutation<
      void,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/importers/this/shipment-internal-document-medias/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["importer-document"],
    }),
  }),
});

export const {
  useGetShipmentDetailQuery,
  useLazyGetShipmentDetailQuery,
  useDeleteShipmentDetailMutation,
  useDeleteShipmentInternalDocumentMutation,
  useDeleteShipmentExternalDocumentMutation,
  useCreateExternalDocumentMutation,
  useCreateInternalDocumentMutation,
  useCreateShipmentMemoMutation,
  useCreateBlMutation,
  useEditShipmentContainerMutation,
  useShipmentDetailSendEmailMutation,

  // Bookmark
  useGetShipmentDetailBookmarkQuery,
  useGetShipmentDetailBookmarkUserQuery,
  useUpdateShipmentDetailBookmarkMutation,

  // Tab
  useGetShipmentDetailContractBookingQuery,
  useLazyGetShipmentDetailContractBookingQuery,
  useLazyGetShipmentDetailTasksQuery,
  useLazyGetShipmentDetailContainersQuery,
  useLazyGetShipmentDetailDocumentAndMemoQuery,

  // Container
  useLazyGetShipmentContainersListQuery,
  useLazyGetSingleShipmentContainerMediaQuery,
  useLazyGetShipmentContainerMediaByPhotoTypeQuery,
  useGetShipmentContainersListViaTaskIdMutation,
  useGetShipmentContainerMediaByPhotoTypeViaTaskIdMutation,
  useLazyGetShipmentContainerListForReselectionQuery,
  useGetShipmentDetailContainersIntegrationQuery,
  useLazyGetShipmentDetailContainersIntegrationQuery,
  useLazyGetCombinedAndSplitHistoryOfContainersQuery,

  // Buyer
  useGetImporterShipmentDetailQuery,
  useLazyGetImporterShipmentDetailContainersQuery,
  useGetImporterShipmentDetailContainersIntegrationQuery,
  useLazyGetImporterShipmentContainersListQuery,
  useLazyGetImporterSingleShipmentContainerMediaQuery,
  useLazyGetImporterShipmentContainerMediaByPhotoTypeQuery,
  useGetImporterShipmentDetailDocumentAndMemoQuery,
  useCreateImportInternalDocumentMutation,
  useDeleteImporterShipmentInternalDocumentMutation,
} = shipmentDetailApi;
export { shipmentDetailApi };
