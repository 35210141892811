import Dialog from "@/components/atom/Dialog";
import Icon from "@/components/atom/Icon";
import Table from "@/components/atom/Table";
import Typo from "@/components/atom/Typo";
import CallOut from "@/components/molecule/CallOut";
import Flex from "@/components/molecule/Flex";
import InfoSvg from "@/src/assets/icons/icon-info-gray6.svg";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import {
  useGetSharingContractViaBookingQuery,
  useGetSharingContractViaShipmentQuery,
  useGetSharingContractViaTaskQuery,
} from "@/src/store/apis/sharing";
import { isUndefined } from "@/src/utils/is";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../atom/Button";
import DialogFooterContainer from "../../atom/Dialog/DialogFooterContainer";
import { columnShareableTable } from "./constant/column";

const EMPTY_ARRAY: [] = [];

interface ShareableContractDialogProps {
  onClose: () => void;
  onShare: () => void;
  type: "booking" | "task" | "shipment";
  id: number;
  isConfirmLoading?: boolean;
}

const ShareableContractDialog = ({
  onClose,
  onShare,
  type,
  id,
  isConfirmLoading,
}: ShareableContractDialogProps) => {
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);

  const [isReady, setIsReady] = useState<boolean>(false);
  const [colDefs] = useState(columnShareableTable);
  const { bookingList, isBookingFetching } =
    useGetSharingContractViaBookingQuery(
      {
        bookingId: id,
      },
      {
        skip: type !== "booking",
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            bookingList: isStable ? currentData.rows : EMPTY_ARRAY,
            isBookingFetching: isFetching,
          };
        },
      }
    );
  const { loadingList, isLoadingFetching } = useGetSharingContractViaTaskQuery(
    {
      taskId: id,
    },
    {
      skip: type !== "task",
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isFetching, isError }) => {
        const isUnstable = isError || isUndefined(currentData);
        const isStable = !isUnstable;
        return {
          loadingList: isStable ? currentData.rows : EMPTY_ARRAY,
          isLoadingFetching: isFetching,
        };
      },
    }
  );
  const { shipmentList, isShipmentFetching } =
    useGetSharingContractViaShipmentQuery(
      {
        shipmentId: id,
      },
      {
        skip: type !== "shipment",
        refetchOnMountOrArgChange: true,
        selectFromResult: ({ currentData, isFetching, isError }) => {
          const isUnstable = isError || isUndefined(currentData);
          const isStable = !isUnstable;
          return {
            shipmentList: isStable ? currentData.rows : EMPTY_ARRAY,
            isShipmentFetching: isFetching,
          };
        },
      }
    );

  const getRowData = () => {
    switch (type) {
      case "booking":
        return bookingList;
      case "task":
        return loadingList;
      case "shipment":
        return shipmentList;
      default:
        return EMPTY_ARRAY;
    }
  };

  const rowData = getRowData();
  const confirmEnabled = rowData.some(
    (item) => item.signatureStatus === "LINKED"
  );

  const isFetching =
    isBookingFetching || isLoadingFetching || isShipmentFetching;

  useAgGridHeaderRefresh({
    gridRef: gridRef.current,
    isReady,
    headerSet: [
      {
        columnKey: "status",
        langKey: "table:SignatureStatus",
      },
      {
        columnKey: "poNo",
        langKey: "table:poNo",
      },
      {
        columnKey: "buyerNameCode",
        langKey: "table:buyerCode",
      },
      {
        columnKey: "itemCode",
        langKey: "table:itemCode",
      },
      {
        columnKey: "qty",
        langKey: "table:qty",
      },
      {
        columnKey: "unitPrice",
        langKey: "table:unitPrice",
      },
      {
        columnKey: "shippingTerms",
        langKey: "table:shippingTerms",
      },
      {
        columnKey: "placeOfDelivery",
        langKey: "table:placeOfDelivery",
      },
      {
        columnKey: "origin",
        langKey: "table:originLocation",
      },
      {
        columnKey: "lastShipmentDateAt",
        langKey: "table:lastShipmentDate",
      },
    ],
  });

  return (
    <Dialog
      title={t("shareableContractDialog:title")}
      open
      onOpenChange={onClose}
      width={1000}
      footer={
        <DialogFooterContainer>
          <Button buttonColor="black" buttonGrade="tertiary" onClick={onClose}>
            {t("shareableContractDialog:button.cancel")}
          </Button>
          <Button
            onClick={onShare}
            isLoading={isConfirmLoading}
            disabled={isConfirmLoading || !confirmEnabled}
          >
            {t("shareableContractDialog:button.sharing")}
          </Button>
        </DialogFooterContainer>
      }
    >
      <Flex flexDirection="column" gap={16}>
        <CallOut
          icon={<Icon iconSrc={InfoSvg} />}
          value={
            <Typo typoType="b9r" color="gray6">
              {t("shareableContractDialog:description")}
            </Typo>
          }
        />

        <Table
          isPagination={false}
          ref={gridRef}
          rowData={isFetching ? undefined : rowData}
          columnDefs={colDefs}
          onGridReady={() => setIsReady(true)}
        />
      </Flex>
    </Dialog>
  );
};

export default ShareableContractDialog;
