import React from "react";
import Flex from "../Flex";
import Typo from "../../atom/Typo";
import styled from "styled-components";
import colorSet, { ColorType } from "@/src/styles/color";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-info-gray6.svg";

interface DatePickerNoticeTypoProps {
  text: string;
  color?: ColorType;
}

function DatePickerNoticeTypo({
  text,
  color = "gray6",
}: DatePickerNoticeTypoProps) {
  return (
    <StyledFlex alignItems="center" justifyContent="center" gap={4}>
      <InfoIcon color={color} />
      <Typo typoType="b8r" color={color}>
        {text}
      </Typo>
    </StyledFlex>
  );
}

export default DatePickerNoticeTypo;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const StyledFlex = styled(Flex)`
  padding: 8px 16px;
`;
