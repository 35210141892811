import NotShareSvg from "@/src/assets/icons/illust-not-share.svg";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import Flex from "@/src/components/molecule/Flex";
import RightArrowIcon from "@/src/components/molecule/SectionCard/RightArrowIcon";
import SectionFoldableCard from "@/src/components/molecule/SectionFoldableCard";
import CargoTracking from "@/src/components/organism/CargoTracking";
import { importerShipmentListApi } from "@/src/store/apis/shipments/importerShipment";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";

interface ShipmentTrackingCardProps {
  id: number;
}

const ShipmentTrackingCard = ({ id }: ShipmentTrackingCardProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const { hasBlNo, isFetching, blNoList } =
    importerShipmentListApi.endpoints.getBookingShipmentContainer.useQueryState(
      { id },
      {
        selectFromResult: ({ currentData, isError, isFetching }) => {
          const isUnstable = isUndefined(currentData) || isError;
          const isStable = !isUnstable;

          return {
            hasBlNo: isStable
              ? currentData.rows.some(({ blNo }) => !!blNo)
              : false,
            isFetching,
            blNoList: currentData?.rows ?? [],
          };
        },
      }
    );

  const currentBlNo = blNoList?.[0]?.blNo || "";

  const renderTrackingContent = () => {
    if (isFetching) {
      return <PulseLoader color={colorSet.blue4} />;
    }
    if (hasBlNo) {
      return <CargoTracking blNo={currentBlNo} />;
    }

    return (
      <>
        <Icon iconSrc={NotShareSvg} iconSize={160} />
        <Typo typoType="b4m">{t("common:shipmentTrackingNoUpdate")}</Typo>
      </>
    );
  };

  return (
    <SectionFoldableCard
      open={open}
      onOpenChange={setOpen}
      cardTitle={t("shipment:importer.detail.label.shipmentTracking")}
      rightAccessory={
        <RightArrowIcon
          isCardOpen={open}
          onClick={() => setOpen((open) => !open)}
        />
      }
    >
      <Flex alignItems="center" justifyContent="center" flexDirection="column">
        {renderTrackingContent()}
      </Flex>
    </SectionFoldableCard>
  );
};

export default ShipmentTrackingCard;
