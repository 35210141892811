import FrameSvg from "@/src/assets/icons/icon-frame.svg";
import { Button } from "@/src/components/atom/Button";
import BasicCard from "@/src/components/atom/Cards/BasicCard";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import VerificationInputDiv from "@/src/components/molecule/VerificationInputDiv";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import IMPORTER_PRIVATE_PATH from "@/src/routes/importer/path";
import { useAppSelector } from "@/src/store";
import colorSet from "@/src/styles/color";
import typo from "@/src/styles/typography";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LinkCompanyDetailDialog from "@/src/components/organism/LinkCompanyDetailDialog";
import { useLazyGetCompanyInfoViaLinkCodeQuery } from "@/src/store/apis/link";
import useAlert from "@/src/hooks/useAlert";
import AlertDialog from "@/src/components/atom/AlertDialog";

export interface StepProps {
  onStepChange: Dispatch<SetStateAction<1 | 2>>;
  linkCode: string;
}

enum DialogState {
  NULL,
  LINK_COMPANY_DETAIL,
}

enum AlertDialogState {
  NULL,
  DUPLICATE,
}

const EnterAccountCode = ({ onStepChange, linkCode }: StepProps) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement[]>([]);
  const navigate = useNavigate();
  const alert = useAlert();
  const companyType = useAppSelector(
    (state) => state.auth.user?.exporter.companyType
  );
  const companyLinkCode = useAppSelector(
    (state) => state.auth.user?.exporter.companyLinkCode
  );

  const basicCodeArr = ["", "", "", "", "", "", "", ""].map(
    (_, idx) => linkCode?.[idx] || ""
  );
  const [codeArr, setCodeArr] = useState<string[]>(basicCodeArr);
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [alertDialogState, setAlertDialogState] = useState(
    AlertDialogState.NULL
  );

  const [getCompanyInfoViaLinkCode, { isFetching, currentData }] =
    useLazyGetCompanyInfoViaLinkCodeQuery();

  const handleCancel = () => {
    if (companyType === "BOTH" || companyType === "SELLER") {
      return navigate(EXPORTER_PRIVATE_PATH.HOME, { replace: true });
    }
    return navigate(IMPORTER_PRIVATE_PATH.HOME, { replace: true });
  };

  const handleNext = async () => {
    try {
      const connectionLinkCode = codeArr.join("");
      if (connectionLinkCode === companyLinkCode) {
        // eslint-disable-next-line no-throw-literal
        throw {
          data: {
            message: t("alert:invalidHeadquartersCompanyCode"),
          },
        };
      }
      await getCompanyInfoViaLinkCode({
        companyLinkCode: connectionLinkCode,
      }).unwrap();

      setDialogState(DialogState.LINK_COMPANY_DETAIL);
    } catch (e: any) {
      const isAlreadyRegistered = e.status === 409;
      if (isAlreadyRegistered) {
        setAlertDialogState(AlertDialogState.DUPLICATE);
      } else {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    }
  };

  const renderDialog = () => {
    if (dialogState === DialogState.LINK_COMPANY_DETAIL) {
      return (
        <LinkCompanyDetailDialog
          onClose={() => setDialogState(DialogState.NULL)}
          linkCode={codeArr.join("")}
          defaultClientCode={currentData?.row.companyName || ""}
          onClick={() => onStepChange(2)}
        />
      );
    }
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.DUPLICATE) {
      return (
        <AlertDialog
          open
          title={t(
            "clientManagement:exporter.linkCodeDialog.alertDialog.successIntegration.title"
          )}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
          }}
          okText={t("common:ok")}
        >
          {t(
            "clientManagement:exporter.linkCodeDialog.alertDialog.successIntegration.description"
          )}
        </AlertDialog>
      );
    }
  };

  return (
    <StyledMain>
      <Typo as="h1" typoType="d3" className="enter-account-code-heading">
        {t("linkToAccount:linkToAccount")}
      </Typo>

      <StyledBasicCard
        title={
          <BasicCardTitleContainer>
            {t("linkToAccount:registration.registration")}
          </BasicCardTitleContainer>
        }
      >
        <ContentSection>
          <Icon iconSrc={FrameSvg} iconSize={80}></Icon>
          <TypoContainer>
            <Typo as="span" typoType="d6" color="gray1">
              {t("linkToAccount:registration.info")}
            </Typo>
            <Description>
              <p>{t("linkToAccount:registration.descriptionTop")}</p>
            </Description>
          </TypoContainer>

          <FlexColumn className="gap-8">
            <TextContainer>
              <Typo as="p" typoType="b7m" color="gray2">
                {t("linkToAccount:registration.corpAccountCode")}
              </Typo>
              <RequireMark>*</RequireMark>
            </TextContainer>
            <EnterCodeContainer>
              <EnterCodeBox>
                {codeArr.map((_, idx) => {
                  return (
                    <VerificationInputDiv
                      key={idx.toString()}
                      ref={(node) => node && ref.current.push(node)}
                      char={codeArr[idx]}
                      style={idx === 3 ? { marginRight: "20px" } : undefined}
                      onKeyDown={(e) => {
                        const key = e.key;
                        if (key === "ArrowLeft") {
                          if (idx - 1 !== -1) {
                            ref.current[idx - 1].focus();
                          }
                        }
                        if (key === "ArrowRight") {
                          if (idx + 1 !== codeArr.length) {
                            ref.current[idx + 1].focus();
                          }
                        }
                        if ((e.ctrlKey || e.metaKey) && e.key === "v") {
                          navigator.clipboard.readText().then((res) => {
                            const overridden = Array.from(
                              { length: 8 },
                              () => ""
                            );
                            const pasteText = res
                              .split("")
                              .filter((letter) => letter !== " ")
                              .slice(0, 8)
                              .concat(overridden)
                              .slice(0, 8);
                            setCodeArr(pasteText);
                          });
                        }
                      }}
                      onCharChange={(char) => {
                        if (!!codeArr[idx]) {
                          if (idx + 1 !== codeArr.length) {
                            ref.current[idx + 1].focus();
                          }
                          return;
                        }
                        const copied = codeArr.slice();
                        copied.splice(idx, 1, char ?? "");
                        setCodeArr(copied);
                        if (idx + 1 !== codeArr.length) {
                          ref.current[idx + 1].focus();
                        }
                      }}
                      onBackSpaceKeyDown={() => {
                        const copied = codeArr.slice();
                        copied.splice(idx, 1, "");
                        setCodeArr(copied);
                        if (idx - 1 !== -1) {
                          ref.current[idx - 1].focus();
                        }
                      }}
                    />
                  );
                })}
              </EnterCodeBox>
            </EnterCodeContainer>
          </FlexColumn>

          <StyledFooter>
            <StyledButton
              buttonColor="black"
              buttonGrade="tertiary"
              buttonSize={40}
              onClick={handleCancel}
            >
              {t("linkToAccount:registration.cancel")}
            </StyledButton>
            <StyledButton
              onClick={handleNext}
              disabled={
                codeArr.filter((code) => code !== "").length !== 8 || isFetching
              }
              isLoading={isFetching}
            >
              {t("linkToAccount:registration.next")}
            </StyledButton>
          </StyledFooter>
        </ContentSection>
      </StyledBasicCard>

      {renderDialog()}
      {renderAlertDialog()}
    </StyledMain>
  );
};

export default EnterAccountCode;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  h1.enter-account-code-heading {
    padding: 24px 0;
  }
`;

const StyledBasicCard = styled(BasicCard)`
  width: 640px;
`;

const BasicCardTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 40px 0;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  &.gap-8 {
    gap: 8px;
  }
`;

const TypoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
`;

const Description = styled.span`
  display: flex;
  flex-direction: column;
  text-align: center;

  color: ${colorSet.gray2};
  ${typo.b7m}
`;

const TextContainer = styled.span`
  display: flex;
  gap: 2px;
`;

const RequireMark = styled.p`
  color: ${colorSet.systemRed2};
`;

const EnterCodeContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const EnterCodeBox = styled.div`
  display: flex;
  gap: 4px;

  :nth-child(4) {
    margin-right: 16px;
  }
`;

const StyledFooter = styled.footer`
  display: flex;
  gap: 8px;
  width: 480px;
`;

const StyledButton = styled(Button)`
  flex: 1;
  text-align: center;
`;
