import AlertDialog from "@/src/components/atom/AlertDialog";
import { Button } from "@/src/components/atom/Button";
import Dialog from "@/src/components/atom/Dialog";
import DialogFooterContainer from "@/src/components/atom/Dialog/DialogFooterContainer";
import Input from "@/src/components/atom/Input";
import Table from "@/src/components/atom/Table";
import Typo from "@/src/components/atom/Typo";
import { InputError } from "@/src/components/molecule/FormItem";
import useAgGridHeaderRefresh from "@/src/hooks/useAgGridHeaderRefresh";
import useAlert from "@/src/hooks/useAlert";
import {
  useInvalidatesBuyerMutation,
  useLazyCheckClientNameCodeQuery,
} from "@/src/store/apis/client/buyer";
import { useGetCommonCodeViaCodeNameQuery } from "@/src/store/apis/common";
import { PartialCommonCodeItemDto } from "@/src/store/apis/common/interface";
import { useGetStaffsQuery } from "@/src/store/apis/corporate/staffManagement";
import {
  linkApi,
  useLazyGetCorporateManagerInfoViaLinkCodeQuery,
  useLinkExistedClientMutation,
} from "@/src/store/apis/link";
import colorSet from "@/src/styles/color";
import { isUndefined } from "@/src/utils/is";
import { RowSelectedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  clientCorporateManagerColumn,
  staffManagerColumn,
} from "./columns/column";

interface LinkCompanyDetailDialogProps {
  onClose: () => void;
  linkCode: string;
  defaultClientCode: string;
  clientId: number;
}

enum AlertDialogState {
  NULL,
  SUCCESS,
  EXIST_FAIL,
}

const EMPTY_ARRAY: PartialCommonCodeItemDto[] = [];
const EMPTY_STRING_ARRAY: string[] = [];

const TABLE_INNER_CONTENT_HEIGHT = 253;

const LinkCompanyDetailInDetailPageDialog = ({
  onClose,
  linkCode,
  defaultClientCode,
  clientId,
}: LinkCompanyDetailDialogProps) => {
  const { t } = useTranslation();
  const alert = useAlert();
  const corpManagerGridRef = useRef<AgGridReact>(null);
  const staffManagerGridRef = useRef<AgGridReact>(null);
  const [isCorpManagerGridReady, setIsCorpManagerGridReady] =
    useState<boolean>(false);
  const [isStaffManagerGridReady, setStaffManagerIsGridReady] =
    useState<boolean>(false);
  const {
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
  } = useForm<{ clientCode: string }>({
    mode: "onBlur",
    defaultValues: {
      clientCode: defaultClientCode,
    },
  });
  const [staffPagination, setStaffPagination] = useState(1);
  const [selectedStaffList, setSelectedStaffList] = useState<number[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertDialogState, setAlertDialogState] = useState<AlertDialogState>(
    AlertDialogState.NULL
  );
  const [errorContent, setErrorContent] = useState<string>();
  const [column] = useState(clientCorporateManagerColumn);
  const [staffColumn] = useState(staffManagerColumn);
  const {
    isManagerListError,
    isManagerListFetching,
    managerList,
    managerListCount,
  } = useGetStaffsQuery(
    {
      isActivated: true,
      page: staffPagination,
      pageSize: 5,
      exporterUserTypes: ["MANAGER", "MIDDLE_MANAGER"],
    },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;
        return {
          isManagerListError: isError,
          isManagerListFetching: isFetching,
          managerList: isStable ? currentData.rows : EMPTY_ARRAY,
          managerListCount: isStable ? currentData.count : 0,
        };
      },
    }
  );

  const [checkClientCode] = useLazyCheckClientNameCodeQuery();
  const [invalidatesBuyer] = useInvalidatesBuyerMutation();
  const [linkExistedClient] = useLinkExistedClientMutation();
  const { mainCategory } = useGetCommonCodeViaCodeNameQuery(
    {
      codeName: "MAIN_CATEGORY",
    },
    {
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          mainCategory: !isUnstable ? currentData : EMPTY_ARRAY,
        };
      },
    }
  );
  const [
    getCorporateManagerInfoViaLinkCode,
    {
      currentData: clientCorporateManager,
      isFetching: isClientCorporateManagerFetching,
    },
  ] = useLazyGetCorporateManagerInfoViaLinkCodeQuery();
  const {
    client,
    contact,
    country,
    businessNumber,
    mainCategoryCode,
    exporterId,
    address,
  } = linkApi.endpoints.getCompanyInfoViaLinkCode.useQueryState(
    {
      companyLinkCode: linkCode,
    },
    {
      selectFromResult: ({ isError, isFetching, currentData }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;
        return {
          client: isStable ? currentData.row.companyName : "-",
          clientType: isStable ? currentData.row.companyType : undefined,
          contact: isStable
            ? `${currentData.row?.telPrefix || ""} ${
                currentData.row?.tel || ""
              }`
            : "-",
          country: isStable ? currentData.row.country : "-",
          businessNumber: isStable ? currentData.row.businessNumber : "-",
          mainCategoryCode: isStable
            ? currentData.row.mainCategoryCodeItemNames
            : EMPTY_STRING_ARRAY,
          exporterId: isStable ? currentData.row.id : 0,
          address: isStable
            ? [
                currentData.row.postalCode,
                currentData.row.streetAddress,
                currentData.row.locality,
                currentData.row.region,
                currentData.row.countryName,
              ]
                .filter((addressPartial) => !!addressPartial)
                .join(", ")
            : "-",
        };
      },
    }
  );

  const mainCategoryCapitalize = mainCategoryCode
    .map(
      (itemCode) =>
        mainCategory.find(({ codeItemName }) => codeItemName === itemCode)
          ?.codeItemNameEn
    )
    .join(", ");

  // AgGrid Checkbox
  const handleSelectionChanged = (e: RowSelectedEvent) => {
    if (
      e.source === "rowClicked" ||
      e.source === "uiSelectAll" ||
      e.source === "checkboxSelected"
    ) {
      const selectedNodesData = e.api.getSelectedNodes();
      const accountKey = e.node.data.exporterUserListQueryResultDto.id;
      const keys = selectedNodesData.map(
        (item) => item.data.exporterUserListQueryResultDto.id
      ) as number[];

      if (selectedStaffList.includes(accountKey)) {
        setSelectedStaffList((prev) => prev.filter((id) => id !== accountKey));
      } else {
        const set: any = new Set([...selectedStaffList, ...keys]);
        setSelectedStaffList([...set]);
      }
    }
  };

  // AgGrid 페이지 네이션
  const handleRowDataUpdated = () => {
    const isLiveGridRef = !!staffManagerGridRef.current;

    if (isLiveGridRef) {
      staffManagerGridRef.current?.api.forEachNode((rowNode) => {
        if (
          selectedStaffList.includes(
            rowNode.data.exporterUserListQueryResultDto.id
          )
        ) {
          rowNode.setSelected(true);
        }
      });
    }
  };

  const handleConnect = async () => {
    try {
      setIsSubmitting(true);

      const { isError } = await checkClientCode({
        buyerNameCode: getValues("clientCode"),
        buyerId: clientId,
      });

      if (isError) {
        // eslint-disable-next-line no-throw-literal
        throw {
          data: {
            message: t("error:alreadyExistedClientCode"),
          },
        };
      }

      await linkExistedClient({
        exporterId,
        companyLinkCode: linkCode,
        buyerNameCode: getValues("clientCode"),
        companyType: "BOTH",
        buyerId: clientId,
        exporterUserIdList: selectedStaffList,
      }).unwrap();

      setAlertDialogState(AlertDialogState.SUCCESS);
    } catch (e: any) {
      if (e.data.statusCode === 409) {
        setErrorContent(e.data.message);
        setAlertDialogState(AlertDialogState.EXIST_FAIL);
      } else {
        const message = Array.isArray(e.data.message)
          ? e.data.message[0]
          : e.data.message;
        alert.showAlert({ type: "error", message });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  useAgGridHeaderRefresh({
    gridRef: corpManagerGridRef.current,
    isReady: isCorpManagerGridReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "clientManagement:exporter.common.name",
      },
      {
        columnKey: "aId",
        langKey: "clientManagement:exporter.common.email",
      },
      {
        columnKey: "personalContact",
        langKey: "clientManagement:exporter.common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "clientManagement:exporter.common.officeContact",
      },
    ],
  });

  useAgGridHeaderRefresh({
    gridRef: staffManagerGridRef.current,
    isReady: isStaffManagerGridReady,
    headerSet: [
      {
        columnKey: "name",
        langKey: "clientManagement:exporter.common.name",
      },
      {
        columnKey: "email",
        langKey: "clientManagement:exporter.common.email",
      },
      {
        columnKey: "personalContact",
        langKey: "clientManagement:exporter.common.personalContact",
      },
      {
        columnKey: "officeContact",
        langKey: "clientManagement:exporter.common.officeContact",
      },
    ],
  });

  const renderCorpInfo = () => {
    return (
      <InfoBox>
        <Box>
          <InfoRow>
            <InfoLabel>
              <Typo typoType="b7m">
                {t(
                  "clientManagement:exporter.linkCompanyDetailDialog.clientInfo.client"
                )}
              </Typo>
            </InfoLabel>
            <InfoValue>
              <Typo typoType="b7r" color="gray5">
                {client}
              </Typo>
            </InfoValue>
          </InfoRow>
          <InfoRow>
            <InfoLabel>
              {t(
                "clientManagement:exporter.linkCompanyDetailDialog.clientInfo.clientCode"
              )}
            </InfoLabel>
            <InfoValue className="client-code-input">
              <Controller
                control={control}
                name="clientCode"
                rules={{
                  maxLength: 80,
                  required: true,
                  validate: async (buyerNameCode) => {
                    if (buyerNameCode.length > 80) return true;
                    const { isError } = await checkClientCode({
                      buyerNameCode,
                    });
                    return !isError;
                  },
                }}
                render={({ field }) => {
                  return (
                    <Input
                      data-invalid={!!errors.clientCode}
                      onClear={() => setValue("clientCode", "")}
                      {...field}
                    />
                  );
                }}
              />
              {errors.clientCode && (
                <StyledInputError
                  message={(() => {
                    switch (errors.clientCode.type) {
                      case "maxLength":
                        return t("error:maxLength80");
                      case "validate":
                        return t("error:alreadyExistedClientCode");
                      case "required":
                        return t("error:required");
                    }
                  })()}
                />
              )}
            </InfoValue>
          </InfoRow>

          <InfoRow>
            <InfoLabel>
              <Typo typoType="b7m">
                {t(
                  "clientManagement:exporter.linkCompanyDetailDialog.clientInfo.country"
                )}
              </Typo>
            </InfoLabel>
            <InfoValue>
              <Typo typoType="b7r" color="gray5">
                {country}
              </Typo>
            </InfoValue>
          </InfoRow>
          {[
            {
              label: t(
                "clientManagement:exporter.linkCompanyDetailDialog.clientInfo.businessNumber"
              ),
              value: businessNumber,
            },
            {
              label: t(
                "clientManagement:exporter.linkCompanyDetailDialog.clientInfo.mainCategory"
              ),
              value: mainCategoryCapitalize,
            },
            {
              label: t(
                "clientManagement:exporter.linkCompanyDetailDialog.clientInfo.contact"
              ),
              value: contact,
            },
            {
              label: t(
                "clientManagement:exporter.linkCompanyDetailDialog.clientInfo.address"
              ),
              value: address,
            },
          ].map(({ label, value }) => {
            return (
              <InfoRow>
                <InfoLabel>
                  <Typo typoType="b7m">{label}</Typo>
                </InfoLabel>
                <InfoValue>
                  <Typo typoType="b7r" color="gray5">
                    {value}
                  </Typo>
                </InfoValue>
              </InfoRow>
            );
          })}
        </Box>
      </InfoBox>
    );
  };

  const renderCorpManagerInfo = () => {
    return (
      <Article>
        <Typo typoType="b7m">
          {t(
            "clientManagement:exporter.linkCompanyDetailDialog.clientCorporateManager"
          )}
        </Typo>
        <Table
          ref={corpManagerGridRef}
          columnDefs={column}
          isPagination={false}
          height={84}
          rowData={
            isClientCorporateManagerFetching || !clientCorporateManager
              ? undefined
              : [clientCorporateManager.row]
          }
          onGridReady={() => {
            setIsCorpManagerGridReady(true);
          }}
        />
      </Article>
    );
  };

  const renderCorpStaffList = () => {
    return (
      <Article>
        <Typo typoType="b7m">
          {t("clientManagement:exporter.linkCompanyDetailDialog.staffList")}{" "}
          <strong style={{ color: `${colorSet.red2}` }}>*</strong>
        </Typo>

        <TableWrapper>
          <Table
            ref={staffManagerGridRef}
            columnDefs={staffColumn}
            height={TABLE_INNER_CONTENT_HEIGHT}
            rowSelection={"multiple"}
            rowMultiSelectWithClick={true}
            totalPage={managerListCount}
            page={staffPagination}
            pageSize={5}
            handlePaginationClick={(page) => setStaffPagination(page)}
            onRowSelected={handleSelectionChanged}
            onRowDataUpdated={handleRowDataUpdated}
            rowData={
              isManagerListFetching
                ? undefined
                : isManagerListError || isUndefined(managerList)
                  ? []
                  : managerList
            }
            onGridReady={() => {
              setStaffManagerIsGridReady(true);
            }}
          />
        </TableWrapper>
      </Article>
    );
  };

  const renderAlertDialog = () => {
    if (alertDialogState === AlertDialogState.SUCCESS) {
      return (
        <AlertDialog
          open
          title={t(
            "clientManagement:exporter.linkCompanyDetailDialog.alertDialog.success.title"
          )}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
            onClose();
          }}
          onOk={() => {
            invalidatesBuyer();
            setAlertDialogState(AlertDialogState.NULL);
            onClose();
          }}
          okText={t("common:ok")}
        >
          {t(
            "clientManagement:exporter.linkCompanyDetailDialog.alertDialog.success.description"
          )}
        </AlertDialog>
      );
    }

    if (alertDialogState === AlertDialogState.EXIST_FAIL) {
      return (
        <AlertDialog
          open
          title={t("common:registeredClient")}
          onOpenChange={() => {
            setAlertDialogState(AlertDialogState.NULL);
            onClose();
          }}
          onOk={() => {
            setAlertDialogState(AlertDialogState.NULL);
            onClose();
          }}
          okText={"ok"}
        >
          {errorContent}
        </AlertDialog>
      );
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await getCorporateManagerInfoViaLinkCode({
          companyLinkCode: linkCode,
        });
      } catch (e) {}
    })();
  }, [getCorporateManagerInfoViaLinkCode, linkCode]);

  return (
    <Dialog
      title={t("clientManagement:exporter.linkCompanyDetailDialog.dialogTitle")}
      open
      onOpenChange={onClose}
      width={1000}
      footer={
        <DialogFooterContainer>
          <Button
            buttonGrade={"tertiary"}
            buttonColor="black"
            onClick={onClose}
          >
            {t("common:cancel")}
          </Button>
          <Button
            disabled={!watch("clientCode") || !!errors.clientCode}
            onClick={handleConnect}
            isLoading={isSubmitting}
          >
            {t(
              "clientManagement:exporter.linkCompanyDetailDialog.buttons.submit"
            )}
          </Button>
        </DialogFooterContainer>
      }
    >
      <TextContainer>
        <Typo typoType="h2">
          {t("clientManagement:exporter.linkCompanyDetailDialog.title")}
        </Typo>
        <PreLineTypo typoType="b7m">
          {t("clientManagement:exporter.linkCompanyDetailDialog.description")}
        </PreLineTypo>
      </TextContainer>
      <InnerContainer>
        {renderCorpInfo()}
        <RowDivider />
        {renderCorpManagerInfo()}
        <RowDivider />
        {renderCorpStaffList()}
      </InnerContainer>

      {renderAlertDialog()}
    </Dialog>
  );
};

export default LinkCompanyDetailInDetailPageDialog;

const InfoBox = styled.div`
  border: 1px solid ${colorSet.gray9};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 16px;
`;

const Box = styled.div`
  width: calc(100% - 8px);
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const RowDivider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorSet.gray9};
`;

const InfoRow = styled.div`
  display: flex;
  align-items: flex-start;
`;

const InfoLabel = styled.div`
  width: 164px;
`;
const InfoValue = styled.div`
  flex: 1;

  &.client-code-input div {
    width: 248px;
  }
`;

const TextContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-bottom: 40px;
`;

const InnerContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledInputError = styled(InputError)`
  padding-top: 8px;
`;

const Article = styled.article`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TableWrapper = styled.div`
  .ant-pagination-options {
    display: none;
  }
`;

const PreLineTypo = styled(Typo)`
  white-space: pre-line;
  text-align: center;
`;
