import { ContainerMediaData } from "../components/organism/ViewPhotoCollectionDialog";
import { toPascalCase } from "./transform";

/**
 * @description 대문자 영어문자열 첫글자 대문자 및 소문자 변환
 */
export const convertToManagerType = (type: string) => {
  const convertedType = type.replace(/_/g, " ").toLowerCase();
  return convertedType.charAt(0).toUpperCase() + convertedType.slice(1);
};

/**
 * @description 이메일 앞 @ *** 마스킹
 * @param email
 * @param removeLength : 제거될 문자열 개수 기본값 3
 */
export const changeEmailCharacters = (
  email: string,
  removeLength: number = 3
) => {
  const atIndex = email.indexOf("@");
  const emailPrefix = email.substring(0, atIndex);
  const modifiedEmail =
    emailPrefix.substring(0, emailPrefix.length - removeLength) +
    "***" +
    email.substring(atIndex);

  return modifiedEmail;
};

/**
 * @description 퍼센트 구하기
 */
export const calculatePercentage = (part: number, total: number) => {
  return Math.floor((part / total) * 100);
};

/**
 * @description 메인 키테고리 이름형식 Metal, Glass ...
 */
export const formatCategoryName = (item: string[]) => {
  const categoryString = item
    .map((item) => item[0] + item.slice(1).toLowerCase().replace(/_/g, "-"))
    .join(", ");

  return categoryString;
};

export const formatSingleContainerMedia = (
  containerData: any
): { folderName: string; images: ContainerMediaData[] } => {
  const {
    containerNo,
    sampleBaleSimpleContainerMedias,
    emptySimpleContainerMedias,
    quarterLoadedSimpleContainerMedias,
    halfLoadedSimpleContainerMedias,
    fullLoadedSimpleContainerMedias,
    oneDoorClosedSimpleContainerMedias,
    bothDoorsClosedSimpleContainerMedias,
    closeUpSealSimpleContainerMedias,
    extraSimpleContainerMedias,
  } = containerData;

  // 미디어 타입별로 데이터를 분리하여 처리
  const allMediaDataByType: Record<string, any[]> = {
    SAMPLE_BALE: sampleBaleSimpleContainerMedias || [],
    EMPTY: emptySimpleContainerMedias || [],
    QUARTER_LOADED: quarterLoadedSimpleContainerMedias || [],
    HALF_LOADED: halfLoadedSimpleContainerMedias || [],
    FULL_LOADED: fullLoadedSimpleContainerMedias || [],
    ONE_DOOR_CLOSED: oneDoorClosedSimpleContainerMedias || [],
    BOTH_DOORS_CLOSED: bothDoorsClosedSimpleContainerMedias || [],
    CLOSE_UP_SEAL: closeUpSealSimpleContainerMedias || [],
    EXTRA: extraSimpleContainerMedias || [],
  };

  const images: ContainerMediaData[] = [];

  // 타입별로 파일명과 URL을 생성하여 이미지 리스트에 추가
  Object.keys(allMediaDataByType).forEach((type) => {
    const mediaList = allMediaDataByType[type];

    if (mediaList.length > 0) {
      mediaList.forEach((media: any, index) => {
        images.push({
          createAt: media.containerMediaCreatedAt,
          mediaUrl: media.containerPhotoSimpleMedia.mediaUrl, // mediaUrl 경로
          originalFileName: `${containerNo || "-"}_${toPascalCase(
            type
          )}${String(index + 1).padStart(2, "0")}.jpg`,
          latitude: media.coordinate?.latitude
            ? media.coordinate?.latitude
            : undefined,
          longitude: media.coordinate?.longitude
            ? media.coordinate?.longitude
            : undefined,
          zoomLevel: media.coordinate?.zoomLevel,
          mediaCoordinateRange: media.coordinate?.mediaCoordinateRange,
        });
      });
    }
  });

  return {
    folderName: containerNo || "-",
    images,
  };
};
