import DeleteDisableSvg from "@/src/assets/icons/icon-cancel-pink.svg";
import DeleteSvg from "@/src/assets/icons/icon-cancle-red.svg";
import { ReactComponent as ArrowDownSvg } from "@/src/assets/icons/icon-chevron-down-gray7.svg";
import ChevronLeft from "@/src/assets/icons/icon-chevron-left-black.svg";
import { ReactComponent as ClockSvg } from "@/src/assets/icons/icon-clock-gray5.svg";
import DotGray4Svg from "@/src/assets/icons/icon-dot-gray4.svg";
import DotGray7Svg from "@/src/assets/icons/icon-dot-gray7.svg";
import { ReactComponent as MailSvg } from "@/src/assets/icons/icon-mail-blue.svg";
import { Button } from "@/src/components/atom/Button";
import DropDown from "@/src/components/atom/DropDown";
import Icon from "@/src/components/atom/Icon";
import Typo from "@/src/components/atom/Typo";
import BookmarkDialog from "@/src/components/molecule/BookmarkDialog";
import BookMarkStatus from "@/src/components/molecule/BookMarkStatus";
import BottomFixedContainer from "@/src/components/molecule/BottomFixedContainer";
import SectionCardWithoutHeader from "@/src/components/molecule/SectionCardWithoutHeader";
import TabItem from "@/src/components/molecule/TabItem";
import UnauthorizedDescription from "@/src/components/molecule/UnauthorizedDescription";
import EmailHistoryDialog from "@/src/components/organism/EmailHistoryDialog";
import ShareableContractDialog from "@/src/components/organism/ShareableContractDialog";
import ExporterMainLayout from "@/src/components/template/Layout/exporter/ExporterMainLayout";
import DATE_FORMAT_STRINGS from "@/src/constant/dateFormat";
import useAlert from "@/src/hooks/useAlert";
import useContentLoading from "@/src/hooks/useContentLoading";
import EXPORTER_PRIVATE_PATH from "@/src/routes/exporter/path";
import { useLazyGetEmailShareHistoriesQuery } from "@/src/store/apis/emailShareHistories";
import { useShareShipmentMutation } from "@/src/store/apis/sharing";
import {
  useDeleteShipmentDetailMutation,
  useGetShipmentDetailBookmarkQuery,
  useGetShipmentDetailBookmarkUserQuery,
  useGetShipmentDetailQuery,
  useUpdateShipmentDetailBookmarkMutation,
} from "@/src/store/apis/shipments/shipmentDetail";
import { ShipmentBookmarkListViewDto } from "@/src/store/apis/shipments/shipmentDetail/interface";
import colorSet, { ColorType } from "@/src/styles/color";
import { isNull, isUndefined } from "@/src/utils/is";
import {
  DropdownMenuGroup,
  DropdownMenuItem,
} from "@radix-ui/react-dropdown-menu";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled, { css } from "styled-components";
import BookingContractCard from "./components/detail/card/BookingContractCard";
import CombinedSplitedHistoryCard from "./components/detail/card/CombinedSplitedHistoryCard";
import ContainerCard from "./components/detail/card/ContainerCard";
import DocumentMemoCard from "./components/detail/card/DocumentMemoCard";
import ShipmentCard from "./components/detail/card/ShipmentCard";
import TaskCard from "./components/detail/card/TaskCard";
import DeleteShipmentAlertDialog from "./components/detail/dialog/DeleteShipmentAlertDialog";
import EmailDialog from "./components/detail/dialog/EmailDialog";
import { renderShipmentStatusBadge } from "./utils/renderShipmentStatusBadge";

export enum ShipmentSelectTabState {
  BOOKING_CONTRACT = "BOOKING_CONTRACT",
  LOADING = "LOADING",
  CONTAINER = "CONTAINER",
  DOCUMENT_MEMO = "DOCUMENT_MEMO",
}

enum DialogState {
  NULL,
  SEND_EMAIL,
  SENT_HISTORY,
  BOOK_MARK,
  SHARE,
}

const TAB_LIST: ShipmentSelectTabState[] = [
  ShipmentSelectTabState.BOOKING_CONTRACT,
  ShipmentSelectTabState.LOADING,
  ShipmentSelectTabState.CONTAINER,
  ShipmentSelectTabState.DOCUMENT_MEMO,
];

const bookmarkEmptyArray: ShipmentBookmarkListViewDto[] = [];

function ExporterShipmentDetailPage() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const alert = useAlert();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const params = useParams();
  const loadingTimeoutId = useRef<NodeJS.Timeout | null>(null);
  const { handleContentLoadingOff, handleContentLoadingOn } =
    useContentLoading();

  // API
  const {
    isShipmentError,
    shipmentStatus,
    invoiceNo,
    shipmentWriterName,
    shipmentCreatedAt,
    shipmentUpdatedAt,
    shipmentLastEditorName,
    isShipmentDetailFetching,
    isUnstable,
    refetch: getShipmentDetail,
    hasSharedAt,
  } = useGetShipmentDetailQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);
        const isStable = !isUnstable;

        return {
          isUnstable,
          isShipmentError: isError,
          isShipmentDetailFetching: isFetching,
          shipmentWriterId: isStable ? currentData.shipmentWriterId : null,
          shipmentStatus: isStable ? currentData.shipmentStatus : null,
          invoiceNo: isStable ? (currentData.invoiceNo ?? "-") : "-",
          hasSharedAt: isStable ? !isNull(currentData?.sharedAt) : true,
          shipmentWriterName: isStable
            ? (currentData.shipmentWriterName ?? "-")
            : "-",
          shipmentLastEditorName: isStable
            ? (currentData.shipmentLastEditorName ?? "-")
            : "-",
          shipmentCreatedAt: isStable
            ? `(${dayjs(currentData.createdAt).format(
                DATE_FORMAT_STRINGS.YY_MM_DD
              )},${dayjs(currentData.createdAt).format(
                DATE_FORMAT_STRINGS.HH_mm
              )})`
            : "-",
          shipmentUpdatedAt: isStable
            ? `(${dayjs(currentData.updatedAt).format(
                DATE_FORMAT_STRINGS.YY_MM_DD
              )},${dayjs(currentData.updatedAt).format(
                DATE_FORMAT_STRINGS.HH_mm
              )})`
            : "-",
        };
      },
    }
  );
  const { isBookmarked } = useGetShipmentDetailBookmarkQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData }) => {
        return {
          isBookmarked: currentData?.isBookmarked,
        };
      },
    }
  );
  const { bookmarkUserList } = useGetShipmentDetailBookmarkUserQuery(
    { id: Number(params.id) },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ currentData, isError, isFetching }) => {
        const isUnstable = isError || isFetching || isUndefined(currentData);

        return {
          bookmarkUserList: !isUnstable
            ? (currentData.rows ?? bookmarkEmptyArray)
            : bookmarkEmptyArray,
        };
      },
    }
  );
  const [editShipmentBookmark] = useUpdateShipmentDetailBookmarkMutation();
  const [deleteShipment] = useDeleteShipmentDetailMutation();
  const [
    getShipmentSharedHistories,
    { count, isFetching, emailHistoryList, isEmailShareHistoriesError },
  ] = useLazyGetEmailShareHistoriesQuery({
    selectFromResult: ({ currentData, isFetching, isError }) => {
      const isUnstable = isUndefined(currentData) || isFetching || isError;

      return {
        isFetching,
        count: !isUnstable ? currentData.count : 0,
        emailHistoryList: !isUnstable ? currentData.rows : undefined,
        isEmailShareHistoriesError: isError,
      };
    },
  });
  const [shareShipment, { isLoading: isShareShipmentLoading }] =
    useShareShipmentMutation();

  // State
  const [dialogState, setDialogState] = useState<DialogState>(DialogState.NULL);
  const [isDeleteShipmentAlertDialogOpen, setIsDeleteShipmentAlertDialogOpen] =
    useState(false);
  const [selectTab, setSelectTab] = useState<ShipmentSelectTabState>(
    (searchParams.get("selectTab") as unknown as ShipmentSelectTabState) ??
      ShipmentSelectTabState.BOOKING_CONTRACT
  );

  const isSendMailDisabled =
    shipmentStatus === "BEFORE_REGISTRATION" ||
    shipmentStatus === "CI_PL_WRITING" ||
    shipmentStatus === "TEMPORARY_REGISTER";

  const isEditAuth = !isShipmentError;

  const isShipmentCompleted = shipmentStatus === "COMPLETED";

  const renderTabItemTitle = (type: ShipmentSelectTabState) => {
    switch (type) {
      case ShipmentSelectTabState.BOOKING_CONTRACT:
        return t("shipment:exporter.detail.tabs.booking");
      case ShipmentSelectTabState.LOADING:
        return t("shipment:exporter.detail.tabs.loading");
      case ShipmentSelectTabState.CONTAINER:
        return t("shipment:exporter.detail.tabs.container");
      case ShipmentSelectTabState.DOCUMENT_MEMO:
        return t("shipment:exporter.detail.tabs.document");
    }
  };

  const handleBookmarkClick = async () => {
    const bookmark = !isBookmarked;

    try {
      const bookmarkParams: { id: number; isBookmarked: boolean } = {
        id: Number(params.id),
        isBookmarked: bookmark,
      };
      await editShipmentBookmark(bookmarkParams).unwrap();
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const handleShipmentDeleteClick = async () => {
    try {
      await deleteShipment({ id: Number(params.id) }).unwrap();

      navigate(EXPORTER_PRIVATE_PATH.SHIPMENT);

      alert.showAlert({
        type: "success",
        message: t("shipment:exporter.detail.alert.deleteShipmentAlert"),
      });
    } catch (e: any) {
      const message = Array.isArray(e.data.message)
        ? e.data.message[0]
        : e.data.message;
      alert.showAlert({ type: "error", message });
    }
  };

  const renderTabItemContent = (tabType: ShipmentSelectTabState) => {
    switch (tabType) {
      case ShipmentSelectTabState.BOOKING_CONTRACT:
        return <BookingContractCard />;
      case ShipmentSelectTabState.LOADING:
        return <TaskCard />;
      case ShipmentSelectTabState.CONTAINER:
        return (
          <>
            <ContainerCard
              isEditAuth={isEditAuth}
              isShipmentCompleted={isShipmentCompleted}
            />
            <CombinedSplitedHistoryCard />
          </>
        );
      case ShipmentSelectTabState.DOCUMENT_MEMO:
        return (
          <DocumentMemoCard
            isEditAuth={isEditAuth}
            isShipmentCompleted={isShipmentCompleted}
          />
        );
    }
  };

  const renderDialogs = () => {
    if (dialogState === DialogState.BOOK_MARK) {
      return (
        <BookmarkDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          bookmarkUserList={bookmarkUserList}
        />
      );
    }

    if (dialogState === DialogState.SEND_EMAIL) {
      return (
        <EmailDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
        />
      );
    }

    if (dialogState === DialogState.SENT_HISTORY) {
      return (
        <EmailHistoryDialog
          open
          onOpenChange={() => setDialogState(DialogState.NULL)}
          isFetching={isFetching}
          isError={isEmailShareHistoriesError}
          emailHistoryList={emailHistoryList}
          totalCount={count}
          fetch={getShipmentSharedHistories}
          emailShareDomain={"SHIPMENT"}
          emailShareDomainId={Number(params.id)}
        />
      );
    }
    if (dialogState === DialogState.SHARE) {
      return (
        <ShareableContractDialog
          id={Number(params.id)}
          onClose={() => setDialogState(DialogState.NULL)}
          isConfirmLoading={isShareShipmentLoading}
          onShare={async () => {
            try {
              await shareShipment({
                shipmentId: Number(params.id),
              }).unwrap();

              setDialogState(DialogState.NULL);
              await getShipmentDetail().unwrap();
              alert.showAlert({
                type: "success",
                message: t("shipment:exporter.detail.alert.sharingAlert"),
              });
            } catch (e: any) {
              const message = Array.isArray(e.data?.message)
                ? e.data?.message[0]
                : e.data?.message;
              alert.showAlert({
                type: "error",
                message: message ?? t("alert:unHandled"),
              });
            }
          }}
          type="shipment"
        />
      );
    }
  };

  const renderUnauthorizedDescription = () => {
    // 권한이 없을 경우에 보여준다, 서버 http Status 404를 받음
    if (!isEditAuth) {
      return (
        <NoDataContainer>
          <UnauthorizedDescription />
        </NoDataContainer>
      );
    }
  };

  const renderShipmentDetailContent = () => {
    if (isUnstable) {
      return;
    }

    return (
      <>
        <FlexColumn>
          {/* Header */}
          <HeaderContainer>
            <TaskStatusContainer>
              {renderShipmentStatusBadge({
                type: shipmentStatus,
                t,
                size: "L",
              })}
            </TaskStatusContainer>

            <HeaderButtonContainer>
              {/* BookMark */}
              <BookMarkStatus
                bookMarkStatus={isBookmarked}
                bookMarkCount={bookmarkUserList.length}
                onClickBookMark={handleBookmarkClick}
                onClickCount={() => setDialogState(DialogState.BOOK_MARK)}
              />

              <Button
                buttonColor="black"
                buttonGrade="tertiary"
                buttonSize={32}
                disabled={hasSharedAt}
                onClick={() => setDialogState(DialogState.SHARE)}
              >
                {hasSharedAt
                  ? t("common:shareComplete")
                  : t("shipment:exporter.detail.button.shareButton")}
              </Button>

              {/* Email */}
              <DropDown
                dropdownContentProps={{ align: "end" }}
                dropdownTriggerProps={{
                  disabled: isSendMailDisabled || !isEditAuth,
                }}
                dropdownTrigger={
                  <StyledButton
                    buttonSize={32}
                    buttonColor="blue"
                    buttonGrade="secondary"
                    disabled={isSendMailDisabled || !isEditAuth}
                  >
                    <MailIcon
                      size={16}
                      color="gray2"
                      disabled={isSendMailDisabled || !isEditAuth}
                    />
                    {t("shipment:exporter.detail.button.emailButton")}
                    <ArrowDownIcon
                      color="indigo"
                      disabled={isSendMailDisabled || !isEditAuth}
                    />
                  </StyledButton>
                }
              >
                <StyledSectionCardWithoutHeader>
                  <EmailDropDownList>
                    <EmailDropDownItem
                      onSelect={() => {
                        setDialogState(DialogState.SEND_EMAIL);
                      }}
                    >
                      <MailIcon size={18} color="gray2" />
                      <Typo>
                        {t("shipment:exporter.detail.button.sendEmailButton")}
                      </Typo>
                    </EmailDropDownItem>

                    <EmailDropDownItem
                      onSelect={() => setDialogState(DialogState.SENT_HISTORY)}
                    >
                      <ClockIcon />
                      <Typo>
                        {t("shipment:exporter.detail.button.sentHistoryButton")}
                      </Typo>
                    </EmailDropDownItem>
                  </EmailDropDownList>
                </StyledSectionCardWithoutHeader>
              </DropDown>
            </HeaderButtonContainer>
          </HeaderContainer>

          {/* Tabs, Card */}
          <ShipmentCard />
          <Tabs role="tablist">
            {TAB_LIST.map((item, idx) => {
              return (
                <StyledTabItem
                  key={idx.toString()}
                  ref={item === selectTab ? buttonRef : null}
                  tabIndex={item === selectTab ? 0 : -1}
                  isSelect={item === selectTab}
                  tabValue={item}
                  onClick={() => {
                    setSelectTab(item);
                    setSearchParams({ selectTab: item }, { replace: true });
                  }}
                  onFocusItem={(value) => {
                    setSelectTab(value);
                    setSearchParams({ selectTab: value }, { replace: true });
                  }}
                >
                  <Typo
                    typoType="b5m"
                    color={item === selectTab ? "white" : "gray6"}
                  >
                    {renderTabItemTitle(item)}
                  </Typo>
                </StyledTabItem>
              );
            })}
          </Tabs>
          {selectTab && renderTabItemContent(selectTab)}

          {/* Last Editor */}
          {isEditAuth && (
            <EditorContainer>
              <figure>
                <Icon iconSrc={DotGray7Svg} iconSize={4} />
                <Typo color="gray6" typoType="b9r">
                  {`${t("shipment:exporter.detail.editor.registratant")} (${t(
                    "shipment:exporter.detail.editor.registratantAt"
                  )}) ${shipmentWriterName}`}
                </Typo>
                <Typo color="gray7" typoType="b9r">
                  {shipmentCreatedAt}
                </Typo>
              </figure>
              <figure>
                <Icon iconSrc={DotGray4Svg} iconSize={4} />
                <Typo color="gray4" typoType="b9r">
                  {`${t("shipment:exporter.detail.editor.lastEditor")} (${t(
                    "shipment:exporter.detail.editor.lastEditorAt"
                  )}) ${shipmentLastEditorName}`}
                </Typo>
                <Typo color="gray7" typoType="b9r">
                  {shipmentUpdatedAt}
                </Typo>
              </figure>
            </EditorContainer>
          )}
        </FlexColumn>

        {/* Bottom Fixed */}
        <BottomFixedContainer>
          <BottomButtonContainer>
            <StyledButton
              buttonColor="black"
              buttonGrade="tertiary"
              onClick={() => navigate(-1)}
            >
              <Icon iconSrc={ChevronLeft} iconSize={16} />
              {t("shipment:exporter.detail.button.backToPreviousButton")}
            </StyledButton>

            <StyledButton
              buttonColor="red"
              buttonGrade="secondary"
              onClick={() => setIsDeleteShipmentAlertDialogOpen(true)}
              disabled={!isEditAuth || isShipmentCompleted}
            >
              <Icon
                iconSrc={
                  !isEditAuth || isShipmentCompleted
                    ? DeleteDisableSvg
                    : DeleteSvg
                }
                iconSize={16}
              />
              {t("shipment:exporter.detail.button.deleteShipmentButton")}
            </StyledButton>
          </BottomButtonContainer>

          {isDeleteShipmentAlertDialogOpen && (
            <DeleteShipmentAlertDialog
              open={isDeleteShipmentAlertDialogOpen}
              onOpenChange={() => setIsDeleteShipmentAlertDialogOpen(false)}
              onOk={() => {
                setIsDeleteShipmentAlertDialogOpen(false);
                handleShipmentDeleteClick();
              }}
            />
          )}
        </BottomFixedContainer>
      </>
    );
  };

  useEffect(() => {
    buttonRef.current?.focus();
  }, []);

  useEffect(() => {
    if (isShipmentDetailFetching) {
      const setTimeoutId = setTimeout(() => {
        handleContentLoadingOn();
      }, 300);

      loadingTimeoutId.current = setTimeoutId;
    } else {
      if (loadingTimeoutId.current) {
        clearTimeout(loadingTimeoutId.current);
      }
      handleContentLoadingOff();
    }
  }, [
    isShipmentDetailFetching,
    handleContentLoadingOff,
    handleContentLoadingOn,
  ]);

  return (
    <ExporterMainLayout
      breadcrumb={
        !isUnstable ? [t("sideNav:shipment"), t("sideNav:shipmentDetail")] : []
      }
      pageTitle={
        !isUnstable &&
        `${t("shipment:exporter.detail.header.title")} ${invoiceNo}`
      }
    >
      {/* Dialogs */}
      {renderDialogs()}
      {/* Contents */}
      {renderShipmentDetailContent()}
      {renderUnauthorizedDescription()}
    </ExporterMainLayout>
  );
}

export default ExporterShipmentDetailPage;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 72px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
const TaskStatusContainer = styled(HeaderButtonContainer)``;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  figure {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const BottomButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Tabs = styled.div`
  display: flex;
  margin-top: 24px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
`;

const StyledTabItem = styled(TabItem)<{
  isSelect?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 8px 16px;
  cursor: pointer;
  background: ${({ isSelect }) =>
    isSelect ? colorSet.indigo : colorSet.gray11};

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  ${({ isSelect }) => {
    return isSelect
      ? css`
          border: none;
        `
      : css`
          border: 1px solid ${colorSet.gray9};
        `;
  }}
`;

const MailIcon = styled(MailSvg)<{
  color?: ColorType;
  disabled?: boolean;
  size: number;
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;

const EmailDropDownList = styled(DropdownMenuGroup)`
  display: flex;
  flex-direction: column;
`;
const EmailDropDownItem = styled(DropdownMenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  outline: none;

  &:focus,
  &:hover {
    background: ${colorSet.gray10};
  }

  &[data-disabled="true"] {
    span {
      color: ${colorSet.gray8};
    }

    cursor: not-allowed;
  }
`;

const ArrowDownIcon = styled(ArrowDownSvg)<{
  color: ColorType;
  disabled: boolean;
}>`
  width: 16px;
  height: 16px;

  path {
    ${({ color }) =>
      color &&
      css`
        fill: ${colorSet[color]};
      `}

    ${({ disabled }) =>
      disabled &&
      css`
        fill: ${colorSet.gray8};
      `}
  }
`;
const ClockIcon = styled(ClockSvg)`
  width: 18px;
  height: 18px;

  path {
    fill: ${colorSet.gray2};
  }
`;

const StyledSectionCardWithoutHeader = styled(SectionCardWithoutHeader)`
  background: ${colorSet.white};
`;

const NoDataContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
