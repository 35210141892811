import Dialog from "@/src/components/atom/Dialog";
import GoogleMap from "@/src/components/atom/GoogleMap";
import SectionCardRow from "@/src/components/molecule/SectionCardRow";
import colorSet, { ColorType } from "@/src/styles/color";
import zIndex from "@/src/styles/zIndex";
import { ConfigProvider, Image, ImageProps } from "antd";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import Flex from "../../molecule/Flex";
import { ContainerMediaData } from "../ViewPhotoCollectionDialog";
import { MEDIA_COORDINATE_RANGE_OPTION_LIST } from "@/src/pages/exporter/Task/constant/add";
import { ReactComponent as InfoSvg } from "@/src/assets/icons/icon-info-gray6.svg";
import Typo from "../../atom/Typo";

interface LoadingPhotoPreviewDialogProps {
  open: boolean;
  onOpenChange: () => void;
  selectImage: ContainerMediaData | null;
  antdImageProps?: ImageProps;
  photoDetailRightAccessory?: ReactNode;
  zoomLevel?: number;
  isLocationInfo?: boolean;
}

export const mapContainerStyle = {
  width: "100%",
  height: "200px",
};

function LoadingPhotoPreviewDialog({
  open,
  onOpenChange,
  selectImage,
  antdImageProps,
  photoDetailRightAccessory,
  zoomLevel,
  isLocationInfo = true,
}: LoadingPhotoPreviewDialogProps) {
  const { t } = useTranslation();

  const findMediaCoordinateRangeText =
    MEDIA_COORDINATE_RANGE_OPTION_LIST.find(
      (item) => item.value === selectImage?.mediaCoordinateRange
    )?.label || "-";

  return (
    <Dialog
      title={t("task:detail.dialog.photoPreview")}
      open={open}
      width={640}
      onOpenChange={() => {
        onOpenChange();
      }}
    >
      <ConfigProvider
        theme={{
          components: {
            Image: {
              zIndexPopup: zIndex.antdImage,
              zIndexPopupBase: zIndex.antdImageBase,
            },
          },
        }}
      >
        <MainImageContainer>
          <Image
            style={{ objectFit: "contain" }}
            src={selectImage?.mediaUrl}
            width={592}
            height={395}
            {...antdImageProps}
          />
          {photoDetailRightAccessory && (
            <MainImageDownloadButton>
              {photoDetailRightAccessory}
            </MainImageDownloadButton>
          )}
        </MainImageContainer>
      </ConfigProvider>

      {isLocationInfo && (
        <StyledSectionCardRow
          label={t("task:add.label.mediaCoordinateRange")}
          value={
            <Flex justifyContent="space-between" alignItems="center">
              {t(`task:${findMediaCoordinateRangeText}`)}
              <Flex gap={6} alignItems="center">
                <InfoIcon color="gray6" />
                <Typo typoType="b9r" color="gray6">
                  {t("common:locationLimitedAccess")}
                </Typo>
              </Flex>
            </Flex>
          }
        />
      )}

      {selectImage?.latitude && selectImage?.longitude && (
        <>
          <Divider gap={24} />
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={{
              lat: Number(selectImage?.latitude),
              lng: Number(selectImage?.longitude),
            }}
            zoomLevel={zoomLevel}
            mediaCoordinateRange={selectImage?.mediaCoordinateRange}
            marker={[
              {
                position: {
                  lat: Number(selectImage.latitude),
                  lng: Number(selectImage.longitude),
                },
              },
            ]}
          />
        </>
      )}
    </Dialog>
  );
}

export default LoadingPhotoPreviewDialog;

const Divider = styled.div<{ gap?: number }>`
  width: 100%;
  border-bottom: 1px solid ${colorSet.gray9};
  ${({ gap }) =>
    gap &&
    css`
      margin: ${gap}px 0;
    `};
`;

const StyledSectionCardRow = styled(SectionCardRow)`
  padding-top: 24px;
  p {
    width: 100%;
  }
`;

const InfoIcon = styled(InfoSvg)<{ color: ColorType }>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;

  path {
    fill: ${({ color }) => colorSet[color]};
  }
`;

const MainImageContainer = styled.div`
  position: relative;
`;

const MainImageDownloadButton = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;
