import ProtectedImportPrivateRoute from "@/src/components/template/ProtectedImportPrivateRoute";
import ImporterClientManagementPage from "@/src/pages/importer/ClientManagement";
import ImporterClientManagementAddPage from "@/src/pages/importer/ClientManagement/add";
import ImporterClientManagementDetailPage from "@/src/pages/importer/ClientManagement/detail";
import ImporterClientManagementEditPage from "@/src/pages/importer/ClientManagement/edit";
import ImporterCompanyInformationManagement from "@/src/pages/importer/CompanyInformationManagement";
import ImporterContractPage from "@/src/pages/importer/Contract";
import ImporterContractAddPage from "@/src/pages/importer/Contract/add";
import ImporterContractDetailPage from "@/src/pages/importer/Contract/detail";
import ImporterDashboardPage from "@/src/pages/importer/Dashboard";
import ImporterFaqPage from "@/src/pages/importer/Faq";
import ImporterFileBoxPage from "@/src/pages/importer/FileBox";
import ImporterInquiryPage from "@/src/pages/importer/Inquiry";
import ImporterInquiryAddPage from "@/src/pages/importer/Inquiry/add";
import ImporterInquiryDetailPage from "@/src/pages/importer/Inquiry/detail";
import ImporterItemManagementPage from "@/src/pages/importer/ItemManagement";
import ImporterMypage from "@/src/pages/importer/Mypage";
import ImporterMypageEditPage from "@/src/pages/importer/Mypage/edit";
import ImporterNotFoundPage from "@/src/pages/importer/NotFound";
import ImporterNoticePage from "@/src/pages/importer/Notice";
import ImporterNoticeDetailPage from "@/src/pages/importer/Notice/detail";
import ImporterSalesManagementPage from "@/src/pages/importer/Sales";
import ImporterSalesCreatePage from "@/src/pages/importer/Sales/add";
import ImporterSalesDetailPage from "@/src/pages/importer/Sales/detail";
import ImporterSalesEditPage from "@/src/pages/importer/Sales/edit";
import ImporterShipmentPage from "@/src/pages/importer/Shipment";
import ImporterShipmentDetailPage from "@/src/pages/importer/Shipment/detail";
import ImporterStaffManagementPage from "@/src/pages/importer/StaffManagement";
import ImporterWarehouseManagementPage from "@/src/pages/importer/WarehouseManagement";
import ImporterWarehouseManagementAddPage from "@/src/pages/importer/WarehouseManagement/add";
import ImporterWarehouseManagementDetailPage from "@/src/pages/importer/WarehouseManagement/detail";
import ImporterWarehouseManagementEditPage from "@/src/pages/importer/WarehouseManagement/edit";
import { RouteObject } from "react-router-dom";
import IMPORTER_PRIVATE_PATH from "./path";
import ImporterContractEditV2Page from "@/src/pages/importer/Contract/editV2";

const importerPrivateRoutes: RouteObject[] = [
  {
    path: IMPORTER_PRIVATE_PATH.ROOT,
    element: <ProtectedImportPrivateRoute />,
    errorElement: <ImporterNotFoundPage />,
    children: [
      {
        path: IMPORTER_PRIVATE_PATH.HOME,
        element: <ImporterDashboardPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.CONTRACT,
        element: <ImporterContractPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.CONTRACT_ADD,
        element: <ImporterContractAddPage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.CONTRACT_EDIT_V2}/:id`,
        element: <ImporterContractEditV2Page />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.CONTRACT_DETAIL}/:id`,
        element: <ImporterContractDetailPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.SHIPMENT,
        element: <ImporterShipmentPage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.SHIPMENT_DETAIL}/:id`,
        element: <ImporterShipmentDetailPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.COMPANY_INFORMATION_MANAGEMENT,
        element: <ImporterCompanyInformationManagement />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.STAFF_MANAGEMENT,
        element: <ImporterStaffManagementPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT,
        element: <ImporterClientManagementPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_ADD,
        element: <ImporterClientManagementAddPage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_DETAIL}/:id`,
        element: <ImporterClientManagementDetailPage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.CLIENT_MANAGEMENT_EDIT}/:id`,
        element: <ImporterClientManagementEditPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.ITEM_MANAGEMENT,
        element: <ImporterItemManagementPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT,
        element: <ImporterWarehouseManagementPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT_ADD,
        element: <ImporterWarehouseManagementAddPage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT_DETAIL}/:id`,
        element: <ImporterWarehouseManagementDetailPage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.WAREHOUSE_MANAGEMENT_EDIT}/:id`,
        element: <ImporterWarehouseManagementEditPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.SALES_MANAGEMENT,
        element: <ImporterSalesManagementPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.SALES_ADD,
        element: <ImporterSalesCreatePage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.SALES_DETAIL}/:id`,
        element: <ImporterSalesDetailPage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.SALES_EDIT}/:id`,
        element: <ImporterSalesEditPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.NOTICE,
        element: <ImporterNoticePage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.NOTICE}/:id`,
        element: <ImporterNoticeDetailPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.INQUIRY,
        element: <ImporterInquiryPage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.INQUIRY}/:id`,
        element: <ImporterInquiryDetailPage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.INQUIRY_ADD}`,
        element: <ImporterInquiryAddPage />,
      },
      {
        path: `${IMPORTER_PRIVATE_PATH.INQUIRY_EDIT}/:id`,
        element: <ImporterInquiryDetailPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.FAQ,
        element: <ImporterFaqPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.FILE_BOX,
        element: <ImporterFileBoxPage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.MY_PAGE,
        element: <ImporterMypage />,
      },
      {
        path: IMPORTER_PRIVATE_PATH.MY_PAGE_EDIT,
        element: <ImporterMypageEditPage />,
      },
    ],
  },
];

export default importerPrivateRoutes;
