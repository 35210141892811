import CloseIconSvg from "@/src/assets/icons/icon-cancle-black.svg";
import colorSet from "@/src/styles/color";
import { StyledScroll } from "@/src/styles/scroll";
import typo from "@/src/styles/typography";
import zIndex from "@/src/styles/zIndex";
import { PropsWithChildren, ReactNode, useLayoutEffect, useRef } from "react";
import styled, { keyframes } from "styled-components";
import {
  QDialogCloseButton,
  QDialogContent,
  QDialogDescription,
  QDialogOverlay,
  QDialogPortal,
  QDialogRoot,
  QDialogTitle,
} from "../../quantum/DialogQuantum";
import { Button } from "../Button";
import Icon from "../Icon";

interface DialogProps {
  title: ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  okText?: ReactNode;
  onOk?: () => void;
  onEscapeKeyDown?: () => void;
  /**
   * @description 496이 기본 사이즈;
   */
  width?: number;
  height?: number;
  maxHeight?: number;
  isCloseButton?: boolean;
  /**@description Title 우측 커스텀 */
  titleRightAccessory?: ReactNode;
  footer?: ReactNode;
  minHeight?: number;
  destroyDialogWhenEscapePress?: boolean;
  isHideDialog?: boolean;
}

function Dialog({
  title,
  open,
  onOpenChange,
  okText,
  onOk,
  onEscapeKeyDown,
  width = 496,
  height,
  maxHeight,
  isCloseButton = true,
  titleRightAccessory,
  footer,
  minHeight,
  children,
  destroyDialogWhenEscapePress = true,
  isHideDialog = false,
}: PropsWithChildren<DialogProps>) {
  const overflowCssValue = useRef<string>();

  useLayoutEffect(() => {
    const originalOverflow = window.getComputedStyle(document.body).overflow;
    overflowCssValue.current = originalOverflow;
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = overflowCssValue.current ?? "visible";
    };
  }, []);

  const renderTitleRightContent = () => {
    if (titleRightAccessory) {
      return titleRightAccessory;
    }

    if (isCloseButton) {
      return (
        <DialogCloseButton>
          <Icon iconSrc={CloseIconSvg} iconSize={20} />
        </DialogCloseButton>
      );
    }
  };

  const renderFooter = () => {
    if (footer) {
      return footer;
    }

    if (onOk) {
      return <Button onClick={onOk}>{okText}</Button>;
    }
  };

  return (
    <QDialogRoot
      open={open}
      onOpenChange={onOpenChange}
      destroyDialogWhenEscapePress={destroyDialogWhenEscapePress}
      onEscapeKeyDown={onEscapeKeyDown}
    >
      <QDialogPortal>
        <StyledOverlay isHideDialog={isHideDialog} />
        <StyledDialogContent width={width} isHideDialog={isHideDialog}>
          {/* Title */}
          <TitleContainer>
            <StyledTitle>{title}</StyledTitle>
            {renderTitleRightContent()}
          </TitleContainer>

          {/* Description */}
          <StyledDescription
            minHeight={minHeight}
            maxHeight={maxHeight}
            height={height}
            isOkText={!!okText}
          >
            {children}
          </StyledDescription>

          {/* Footer */}
          {(footer || onOk) && <Footer>{renderFooter()}</Footer>}
        </StyledDialogContent>
      </QDialogPortal>
    </QDialogRoot>
  );
}

export default Dialog;

const overlayKeyframe = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const contentShow = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;

const StyledOverlay = styled(QDialogOverlay)<{ isHideDialog: boolean }>`
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  inset: 0;
  animation: ${overlayKeyframe} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: ${zIndex.dialog};
  display: ${({ isHideDialog }) => (isHideDialog ? "none" : "block")};
`;

const StyledDialogContent = styled(QDialogContent)<{
  width: number;
  isHideDialog: boolean;
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${({ width }) => width}px;
  display: flex;
  flex-direction: column;
  background: ${colorSet.white};
  border-radius: 16px;
  animation: ${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: ${zIndex.dialog};
  display: ${({ isHideDialog }) => (isHideDialog ? "none" : "block")};
`;

const StyledTitle = styled(QDialogTitle)`
  margin: 0;
  color: ${colorSet.gray2};
  ${typo.h4}
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid ${colorSet.gray10};
`;

const StyledDescription = styled(QDialogDescription)<{
  isOkText: boolean;
  height?: number;
  maxHeight?: number;
  minHeight?: number;
}>`
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "auto")};
  max-height: ${({ maxHeight }) =>
    maxHeight ? `${maxHeight}px` : "calc(100vh - 200px)"};
  padding: ${({ isOkText }) => (isOkText ? "24px 24px 16px 24px" : "24px")};
  overflow: auto;

  ${StyledScroll}
`;

const Footer = styled.div`
  background: ${colorSet.white};
  border-radius: 16px;
  padding: 20px 24px;
  button {
    text-align: center;
    width: -webkit-fill-available;
  }
`;

export const DialogCloseButton = styled(QDialogCloseButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  border-radius: 8px;
  box-shadow:
    0px 0px 0px 0.5px rgba(160, 164, 171, 0.2),
    0px 1px 2px rgba(5, 29, 57, 0.1);
  cursor: pointer;
`;
